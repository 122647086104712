export enum MultiSelectHeaderEmitSelectTag {
  SELECT_ALL,
  DESELECT_ALL,
}

export interface MultiSelectOption {
  label: string;
  code: string;
}

export interface JFMultiSelectProps {
  multiSelectProps: MultiSelectProps;
  selectLabel: string;
  headerProps?: MultiSelectHeaderProps;
}

export interface MultiSelectProps {
  selectName: string;
  maxOptionsDisplayed: number;
  placeholder?: string;
  allSelectedLabel?: string;
  selectOptions: MultiSelectOption[];
  initialSelectedOptions?: MultiSelectOption[];
  disabled?: boolean;
  extensionCallback?: (label: string) => string;
  selectable?: (option: MultiSelectOption) => boolean;
}

export interface MultiSelectHeaderProps {
  selectAllActive: boolean;
  deSelectAllActive: boolean;
  selectAllLabel?: string;
  clearAllLabel?: string;
  disabled?: boolean;
}
