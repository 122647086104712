
import { Component, Vue } from "vue-property-decorator";

declare const AWS_PROX_URL: string;
@Component({
  name: "CTAUpgradeToProXTrigger",
  components: {},
})
export default class CTAUpgradeToProXTrigger extends Vue {
  triggerText = "View upgrade options on Amazon Marketplace";
  dataGTMEvent = "subscription-title|aws-upgrade-to-prox-link";

  goToAWSProXProductPage() {
    window.open(AWS_PROX_URL, "_blank");
  }
}
