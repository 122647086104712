
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ModalMonthlyEOYDiscount",
  components: {},
})
export default class ModalMonthlyEOYDiscount extends Vue {
  get dataGTMPage() {
    return "eoy-monthly-discount";
  }
  get dataGTMEventContactUs() {
    return this.dataGTMPage + "|contact-us-link";
  }
  get dataGTMEventConditions() {
    return this.dataGTMPage + "|terms-and-conditions-link";
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }
}
