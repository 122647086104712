import { MultipleStatisticsResponse } from "@jfrog-ba/myjfrog-common";

export type statisticsNames =
  | "storage"
  | "dataTransfer"
  | "dataTransferTrafficLogArt"
  | "dataTransferArtReplicator"
  | "dataTransferArtP2p"
  | "dataTransferXray"
  | "dataTransferTrafficLogXray"
  | "dataTransferRedirectXray"
  | "dataTransferRedirectArtifactory"
  | "dataTransferCloudFrontArtifactory"
  | "dataTransferCloudFrontXray"
  | "pipelines";

export default {
  // create a new object with { key as date and value }.
  parser(data: any, key: number, container: any) {
    const value = data;
    if (!container[key]) {
      container[key] = 0;
    }
    container[key] += value;
  },

  // function that takes statistics names and sums for all servers the data for that statistic name.
  sumCallback(statisticsValues: statisticsNames[]) {
    return (acc: number, serverStatistics: MultipleStatisticsResponse["serverStatistics"][0]) =>
      acc +
      serverStatistics.statistics.reduce((accumulator, currentValue) => {
        let value = accumulator;
        if (statisticsValues.length === 0) {
          return value;
        }
        statisticsValues.forEach(val => {
          const currentValueElement = currentValue[val];
          if (!currentValueElement) {
            return;
          }
          value += currentValueElement;
        });
        return value;
      }, 0);
  },
};
