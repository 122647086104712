
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { Region, FlatSubscription } from "@/types/localtypes";
import { JFRadioButtonGroup } from "jfrog-ui-vue-essentials";
import { JpuDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ModalChangeProvider",
  components: { LoadingMask, JFRadioButtonGroup },
})
export default class ModalChangeProvider extends Vue {
  @Prop() private onApprove!: (selectedProvider: JpuDTO["cloudProvider"], selectedRegion: JpuDTO["region"]) => any;
  @Prop() private subscription!: FlatSubscription;
  @Prop() private regions!: Region[];

  selectedProvider: JpuDTO["cloudProvider"] | "" = "";
  selectedRegion: JpuDTO["region"] = "";

  get disclaimer() {
    return this.subscription.meta.isTrial
      ? "Change Cloud Provider is only available for paid subscriptions. Complete your purchase to activate this option."
      : "Select the new provider you would like to switch to and click confirm. Your request will be sent to a JFrog technical representative for review, and we will contact you with an update on your request as soon as possible.<br/><br/>Note: The provider update process will only start once it is confirmed by a JFrog representative.";
  }

  get modalBodyStyle() {
    let minHeight = "480px";
    const viewHeight = document.body.clientHeight;
    if (this.isMobile && viewHeight < 600) {
      minHeight = "auto";
    }
    return {
      minHeight: minHeight,
    };
  }

  get providersInputs(): { label: string; value: JpuDTO["cloudProvider"] }[] {
    return [
      {
        label: this.generateInputHtml("amazon"),
        value: "amazon",
      },
      {
        label: this.generateInputHtml("azure"),
        value: "azure",
      },
      {
        label: this.generateInputHtml("google"),
        value: "google",
      },
    ];
  }

  get radioProvidersProps() {
    return {
      name: "provider",
      validation: "required",
      inputs: this.contextualProvidersInputs,
      vertical: !this.isMobile,
      change: this.clearRegion,
      size: "sm",
    };
  }
  get contextualRegionsInputs() {
    if (this.selectedProvider) {
      return this.regions
        .filter(region => region.jpuProviderCode === this.selectedProvider && region.name.toLowerCase() !== "default")
        .map(region => ({ label: region.name, code: region.code }));
    }
    return [];
  }

  get contextualProvidersInputs() {
    if (this.subscription) {
      return this.providersInputs.filter(providerInput => {
        return providerInput.value !== this.currentProvider;
      });
    }
    return [];
  }
  get providerIsSelected() {
    return this.selectedProvider !== "";
  }
  get regionIsSelected() {
    return this.selectedRegion !== "";
  }

  get currentProvider() {
    return this.subscription.cloudProviderCode;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading(): boolean {
    return !this.subscription;
  }

  get shouldLockConfirmButton() {
    return this.subscription.meta.isTrial || this.isLoading || !this.providerIsSelected || !this.regionIsSelected;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }
  generateInputHtml(providerCode: JpuDTO["cloudProvider"]) {
    const isAzure = providerCode == "azure";
    let styles: string[] | string = ["margin-bottom:-5px"];
    if (isAzure && this.isMobile) {
      styles.push("margin-top:7px");
    }

    styles = styles.join(";");
    const providerName = this.getProviderDisplayName(providerCode);
    return `<img width="30" style="${styles}" src="${this.getProviderImage(
      providerCode,
    )}" /> <span ml-1>${providerName}</span>`;
  }

  getProviderDisplayName(providerCode: JpuDTO["cloudProvider"]) {
    return this.$jfSubscriptions.extractProviderName(providerCode);
  }

  getProviderImage(providerCode: FlatSubscription["cloudProviderCode"]) {
    return this.$jfImages.get(this.$jfImages.getProviderLogoFileName(providerCode));
  }

  dismiss() {
    this.$jfModal.dismiss();
  }
  confirm() {
    if (!this.shouldLockConfirmButton && this.providerIsSelected && this.regionIsSelected) {
      this.onApprove(this.selectedProvider as JpuDTO["cloudProvider"], this.selectedRegion);
      this.$jfModal.close();
    }
  }

  clearRegion() {
    this.selectedRegion = "";
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }
}
