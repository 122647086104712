
import { CountryItem } from "@/assets/countries";
import LoadingMask from "@/components/common/LoadingMask.vue";
import JFMultiSelect from "@/components/common/multiSelect/JFMultiSelect.vue";
import DraggableSelect from "@/components/content/DraggableSelect.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { DraggableItem } from "@/types/localtypes";
import { JFMultiSelectProps, MultiSelectOption } from "@/types/localtypes/multiSelect";
import { JFRadioButtonGroup } from "jfrog-ui-vue-essentials";
import _difference from "lodash/difference";
import { GeoIpCountries, UpdateGeoIPCountriesRequest } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

enum ServerMetadataStatusPrefix {
  "Loading" = "Loading",
  "InProgress" = "In Progress",
  "Active" = "Active",
  "NotActive" = "Not Active",
  "Error" = "Error",
}

@Component({
  name: "ModalGeoipRestriction",
  components: {
    JFRadioButtonGroup,
    DraggableSelect,
    LoadingMask,
    JFMultiSelect,
  },
})
export default class ModalGeoRestriction extends Vue {
  @Prop() private fetchedGeoRestrictionCountries!: GeoIpCountries["geoIpRestrictionCountriesList"];
  @Prop() private restrictionType!: GeoIpCountries["geoIpRestrictionType"];
  @Prop() private geoIpInProgress!: GeoIpCountries["inProgress"];
  @Prop() private serverInProgress!: GeoIpCountries["inProgress"];
  @Prop() private statusLabel!: ServerMetadataStatusPrefix;
  @Prop() private statusClasses!: string[];
  @Prop() private onApprove!: (request: UpdateGeoIPCountriesRequest) => Promise<boolean>;
  @Prop() private allCountries!: CountryItem[];

  selectedRestrictionType: GeoIpCountries["geoIpRestrictionType"] = "deny";
  countries: DraggableItem[] = [];
  dataIsloaded: boolean = false;
  countriesOptionSelected: MultiSelectOption[] = [];

  created() {
    this.setAndFormatCountries();
    this.setCountriesToSelectedBasedOnFetchedData();
    this.selectedRestrictionType = this.restrictionType;
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }

  setAndFormatCountries() {
    this.countries = this.allCountries.map(c => ({ tag: c.tag, selected: false, checked: false, label: c.label }));
  }

  setCountriesToSelectedBasedOnFetchedData() {
    this.countries.forEach(c => {
      if (this.fetchedGeoRestrictionCountries.find(f => f === c.tag)) {
        c.selected = true;
      }
      return c;
    });
    this.dataIsloaded = true;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get mobileMultiSelectItemsMultiSelectProps(): JFMultiSelectProps {
    return {
      selectLabel: "Choose Countries:",
      headerProps: {
        disabled: this.featureIsDisabled,
        selectAllActive: this.selectAllActiveOptionItems,
        deSelectAllActive: this.deSelectAllActiveOptionItems,
      },
      multiSelectProps: {
        disabled: this.featureIsDisabled,
        placeholder: "Choose...",
        maxOptionsDisplayed: 1,
        selectName: "countries",
        selectOptions: this.mobileSelectOptions,
        initialSelectedOptions: this.mobileInitialSelectedOption,
        extensionCallback: () => "",
      },
    };
  }

  get mobileSelectOptions() {
    return this.allCountries.map(c => ({ code: c.tag, label: c.label }));
  }

  get selectAllActiveOptionItems() {
    return this.countriesOptionSelected.length !== this.allCountries.length;
  }

  get deSelectAllActiveOptionItems() {
    return this.countriesOptionSelected.length > 0;
  }

  get mobileInitialSelectedOption() {
    return this.allCountries
      .filter(c => {
        if (this.fetchedGeoRestrictionCountries.find(f => f === c.tag)) {
          return c;
        }
      })
      .map(c => ({ code: c.tag, label: c.label }));
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading(): boolean {
    return !this.dataIsloaded;
  }

  get featureIsDisabled() {
    return this.geoIpInProgress || this.serverInProgress;
  }

  get radioGeoIPProps() {
    return {
      name: "restrictionType",
      validation: "required",
      inputs: this.restrictionRadioProvidersInputs,
      vertical: true,
      size: "md",
      disabled: this.featureIsDisabled,
    };
  }

  get restrictionRadioProvidersInputs(): { label: string; value: GeoIpCountries["geoIpRestrictionType"] }[] {
    return [
      {
        label: "<span><strong>Block</strong>" + " access to JFrog Platform from:</span>",
        value: "deny",
      },
      {
        label: "<span><strong>Enable</strong>" + " access to JFrog Platform from:</span>",
        value: "allow",
      },
    ];
  }

  updateCountries(updatedItems: DraggableItem[]): void {
    this.countries = updatedItems;
  }

  onCountriesSelected(countriesOptionSelected: MultiSelectOption[]) {
    this.countriesOptionSelected = countriesOptionSelected;
  }

  get countriesSelectedTag() {
    return this.countries.filter(c => c.selected).map(c => c.tag);
  }

  get mobileCountriesSelected() {
    return this.countriesOptionSelected.map(c => c.code);
  }

  get canSubmit() {
    return (
      !this.featureIsDisabled &&
      !this.isSameData({
        geoIpRestrictionType: this.selectedRestrictionType,
        geoIpRestrictionCountriesList: this.geoIpRestrictionCountriesListToUpdate,
      })
    );
  }

  get geoIpRestrictionCountriesListToUpdate() {
    return this.isMobile ? this.mobileCountriesSelected : this.countriesSelectedTag;
  }

  isSameData(data: UpdateGeoIPCountriesRequest) {
    const isSameRestrictionType = data.geoIpRestrictionType === this.restrictionType;
    const differences = [
      ..._difference(this.fetchedGeoRestrictionCountries, data.geoIpRestrictionCountriesList),
      ..._difference(data.geoIpRestrictionCountriesList, this.fetchedGeoRestrictionCountries),
    ];
    const isSameCountryList = differences.length === 0;
    return isSameRestrictionType && isSameCountryList;
  }

  async onSubmit() {
    const data: UpdateGeoIPCountriesRequest = {
      geoIpRestrictionType: this.selectedRestrictionType,
      geoIpRestrictionCountriesList: this.geoIpRestrictionCountriesListToUpdate,
    };
    if (this.isSameData(data)) {
      return;
    }
    const canClose = await this.onApprove(data);
    if (canClose) {
      this.$jfModal.closeAll();
    }
  }
}
