
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { FlatSubscription } from "@/types/localtypes";
import { JFSwitchControl } from "jfrog-ui-vue-essentials";
import { JFEssentialsPopupMenuPlacement } from "@/types/3rdPartyLibs";

@Component({
  name: "ModalDetailedUsageLogs",
  components: { JFSwitchControl },
})
export default class ModalDetailedUsageLogs extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Inject() readonly globalBus!: Vue;
  isEnabled: boolean = false;
  tooltipMessage: string =
    "Once logs are enabled, it may take several<br/> hours before the repository is created<br/> and the logs can be viewed.";
  viewLoaded = false;

  getCssClasses() {
    const arr = ["custom-switch"];
    arr.push(this.isEnabled ? "active" : "");
    return arr;
  }
  mounted() {
    this.isEnabled = this.subscription.isEnrolledForLogShipping;

    setTimeout(() => {
      this.viewLoaded = true;
    }, 500);
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }

  @Watch("isEnabled")
  isEnabledChange() {
    this.globalBus.$emit("enrollForLogShipping", this.isEnabled);
  }

  get tooltipProps() {
    const placement: JFEssentialsPopupMenuPlacement = "right";
    return {
      html: true,
      content: this.tooltipMessage,
      trigger: "manual",
      show: this.isEnabled,
      placement: placement,
      classes: ["tooltip_light", "over-popup"],
      autoHide: false,
      hideOnTargetClick: false,
    };
  }

  close() {
    this.$jfModal.dismiss();
  }
}
