
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "GraphTitle",
})
export default class GraphTitle extends Vue {
  @Prop({ default: "Title" })
  private title!: string;
  @Prop({ default: undefined })
  private titlePrecision!: string;
  @Prop({ default: "Sub-title" })
  private subTitle!: string;
  @Prop({ default: "Sum" })
  private displayedSum!: string;
  @Prop({ default: "U" })
  private displayedSumUnit!: string;

  get hasTitlePrecision() {
    return !!this.titlePrecision;
  }
}
