
import { JFFormControl, JFTextAreaField } from "jfrog-ui-vue-essentials";
import { Component, Prop, Vue } from "vue-property-decorator";
import { FlatSubscription } from "@/types/localtypes";

@Component({
  name: "CancelSubscriptionInput",
  components: { JFFormControl, JFTextAreaField },
})
export default class CancelSubscriptionInput extends Vue {
  @Prop() private reason!: { value: String };
  @Prop() private noticeText!: string;
  @Prop() private cancellationText!: string;
  @Prop() private isShowNotice!: boolean;
  @Prop() private isDisabled!: boolean;
  @Prop() private subscription!: FlatSubscription;

  maxCharsAllowedForExplanation: number = 255;

  get explanationValidationRules() {
    return `required|max:${this.maxCharsAllowedForExplanation}`;
  }
}
