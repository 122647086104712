
import { JFMultiSelectProps, MultiSelectHeaderEmitSelectTag, MultiSelectOption } from "@/types/localtypes/multiSelect";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { JFFormControl } from "jfrog-ui-vue-essentials";
import MultiSelectHeader from "@/components/common/multiSelect/parts/MultiSelectHeader.vue";
import VMultiSelect from "@/components/common/multiSelect/parts/VMultiSelect.vue";

@Component({
  name: "JFMultiSelect",
  components: { VMultiSelect, MultiSelectHeader, JFFormControl },
})
export default class JFMultiSelect extends Vue {
  @Prop() private selectLabel!: JFMultiSelectProps["selectLabel"];
  @Prop() private multiSelectProps!: JFMultiSelectProps["multiSelectProps"];
  @Prop() private headerProps!: JFMultiSelectProps["headerProps"];

  eventBus = new Vue();

  get hasHeader() {
    return !!this.headerProps;
  }

  onChange(selected: MultiSelectOption[]) {
    this.$emit("change", selected);
  }

  onMultiHeaderClick(action: MultiSelectHeaderEmitSelectTag) {
    this.eventBus.$emit(action.toString());
  }
}
