
import { JFEssentialsGridPopupMenuOptionsGRID } from "@/types/3rdPartyLibs";
import { SubscriptionJPDCardProps } from "@/types/localtypes";
import { JFPopupMenu } from "jfrog-ui-vue-essentials";
import { JpuDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SubscriptionJPDCard",
  components: { JFPopupMenu },
})
export default class SubscriptionJPDCard extends Vue {
  @Prop() private jpu!: SubscriptionJPDCardProps["jpu"];
  @Prop() private whitelistIpLabel!: SubscriptionJPDCardProps["whitelistIpLabel"];
  @Prop() private whitelistIpClasses!: SubscriptionJPDCardProps["whitelistIpClasses"];
  @Prop() private onWhitelistIpTriggerClick!: SubscriptionJPDCardProps["onWhitelistIpTriggerClick"];
  @Prop() private popupMenuConfig!: JFEssentialsGridPopupMenuOptionsGRID;

  get cardWrapperClasses() {
    let classes = [this.jpu.status.toLowerCase()];
    return classes;
  }
  get logoPath() {
    if (!this.jpu.cloudProvider) {
      return "";
    }
    return this.$jfImages.get(
      this.$jfImages.getProviderLogoFileName(this.jpu.cloudProvider.toLowerCase() as JpuDTO["cloudProvider"]),
    );
  }

  handleWLTriggerClick() {
    this.onWhitelistIpTriggerClick(this.jpu);
  }
}
