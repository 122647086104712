
import { Component, Prop, Vue } from "vue-property-decorator";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import * as fileSaver from "file-saver";

@Component({
  name: "ModalGetRootCertificate",
  components: {
    LoadingMask,
  },
})
export default class ModalGetRootCertificate extends Vue {
  @Prop() private accountNumber!: SubscriptionDTO["accountNumber"];
  @Prop() private title!: string;

  subscription!: SubscriptionDTO;
  isLoading: boolean = false;

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }

  get loadingMaskProps(): LoadingMaskProps {
    return { loading: this.isLoading };
  }

  async downloadCertificate() {
    try {
      this.isLoading = true;
      const certificates = await this.$jfSubscriptions.getCertificates(this.accountNumber);
      this.subscription = await this.$jfSubscriptions.getSubscription(this.accountNumber);
      this.isLoading = false;
      fileSaver.saveAs(new Blob([certificates]), "JFrog_public_root_certificate_" + this.accountNumber + ".zip");
      this.$jfWebeyez.send({ goal_key: "download_public_root_certificate", isSuccess: true });
      this.$jfModal.closeAll();
    } catch (e) {
      this.isLoading = false;
      this.$jfNotification.error({
        title: "Error",
        text: this.$jfMessages.root_certificate_Fetch_error,
        data: { textWrapperClasses: ["text-left"] },
      });
      this.$jfWebeyez.send({
        goal_key: "download_public_root_certificate",
        isSuccess: false,
        errorMessage: this.$jfMessages.root_certificate_Fetch_error,
      });
    }
  }

  dismiss() {
    this.$jfModal.dismiss();
  }
}
