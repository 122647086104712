
import { MultiSelectHeaderEmitSelectTag, MultiSelectHeaderProps } from "@/types/localtypes/multiSelect";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "MultiSelectHeader",
})
export default class MultiSelectHeader extends Vue {
  @Prop({ default: "Select All" })
  private selectAllLabel!: MultiSelectHeaderProps["selectAllActive"];
  @Prop({ default: "Clear All" })
  private clearAllLabel!: MultiSelectHeaderProps["clearAllLabel"];
  @Prop({ default: true })
  private selectAllActive!: MultiSelectHeaderProps["selectAllActive"];
  @Prop({ default: false })
  private deSelectAllActive!: MultiSelectHeaderProps["deSelectAllActive"];
  @Prop({ default: false })
  private disabled!: MultiSelectHeaderProps["disabled"];

  get wrapperClasses() {
    const classes = [];
    if (this.disabled) {
      classes.push("disabled");
    }
    return classes;
  }

  get selectAllClass() {
    const classes = ["multi-select-action"];
    if (!this.disabled && this.selectAllActive) {
      classes.push("active");
    }
    return classes;
  }

  get deSelectAllClass() {
    const classes = ["multi-select-action"];
    if (!this.disabled && this.deSelectAllActive) {
      classes.push("active");
    }
    return classes;
  }

  selectAll() {
    if (!this.disabled && this.selectAllActive) {
      this.$emit("select", MultiSelectHeaderEmitSelectTag.SELECT_ALL);
    }
  }

  deselectAll() {
    if (!this.disabled && this.deSelectAllActive) {
      this.$emit("select", MultiSelectHeaderEmitSelectTag.DESELECT_ALL);
    }
  }
}
