
declare var ENABLE_GCP_PRIVATE_LINK: any;
import { allCountries } from "@/assets/countries";
import ModalChangeProvider from "@/components/views/subscriptions/modals/ModalChangeProvider.vue";
import ModalChangeRegion from "@/components/views/subscriptions/modals/ModalChangeRegion.vue";
import ModalEnableCdn from "@/components/views/subscriptions/modals/ModalEnableCdn.vue";
import ModalGeoRestriction from "@/components/views/subscriptions/modals/ModalGeoRestriction.vue";
import ModalGetRootCertificate from "@/components/views/subscriptions/modals/ModalGetRootCertificate.vue";
import ModalIPListing from "@/components/views/subscriptions/modals/ModalIPListing.vue";
import ModalGenerateTokenAdmin from "@/components/views/subscriptions/modals/ModalGenerateTokenAdmin.vue";
import ModalRequestForCancelOrDownGradeSubscription from "@/components/views/subscriptions/modals/ModalRequestForCancelOrDownGradeSubscription.vue";
import ModalSetNotification from "@/components/views/subscriptions/modals/ModalSetNotification.vue";
import { JFEssentialsPopupMenuOptions } from "@/types/3rdPartyLibs";
import { ChangeSubscriptionOptions, FlatSubscription, Region, WebeyezGoalKeyNames } from "@/types/localtypes";
import marketoCookie from "@/util/marketo-cookie";
import { JFPopupMenu } from "jfrog-ui-vue-essentials";
import _difference from "lodash/difference";
import _pull from "lodash/pull";
import {
  GeoIpCountries,
  GetMigrationCandidatesResponse,
  isRestClientError,
  JpuDTO,
  storeErrorsMapping,
  SubscriptionDTO,
  UpdateGeoIPCountriesRequest,
  WhiteListIPSResponse,
} from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  CANCEL,
  DOWNGRADE,
  REMOVE_SECURITY_PACK,
  SELF_DOWNGRADE_TO_FREE,
} from "@/components/views/subscriptions/consts/DowngradeHelper";
import ModalPrivateLink from "@/components/views/subscriptions/modals/privateLink/ModalPrivateLink.vue";
import ModalMigrationPlugin from "@/components/views/subscriptions/modals/ModalMigrationPlugin.vue";
import ModalDetailedUsageLogs from "@/components/views/subscriptions/modals/ModalDetailedUsageLogs.vue";

interface RegionSelectResult {
  label: string;
  code: string;
}

enum ServerMetadataStatusPrefix {
  "InProgress" = "In Progress",
  "Active" = "Active",
  "NotActive" = "Not Active",
}

@Component({
  name: "SubscriptionTitleComponent",
  components: {
    JFPopupMenu,
  },
})
export default class SubscriptionTitleComponent extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop({ default: true })
  private isLoading!: boolean;
  @Prop({ default: null })
  private popOverProps!: JFEssentialsPopupMenuOptions;
  @Prop({ default: () => [] })
  private regionsList!: Region[];
  @Prop({ default: { meta: {} } })
  private subscription!: FlatSubscription;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  isEnrolledForLogShippingOldValue: boolean = false;
  enableUsageLogsFeatureFlag: boolean = false; // feature flag that will be removed once enable usage logs will be available for all instances
  migrationCandidates!: string[];
  selectedMigrationCandidate!: string;

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  async getMigrationCandidates() {
    await this.$jfJmisService
      .getMigrationCandidates({
        technical_server_names: [...new Set(this.subscription.jpus.map(jpu => jpu.technicalServerName))],
      })
      .then((data: GetMigrationCandidatesResponse) => {
        this.migrationCandidates = data.technical_server_names;
        this.selectedInstanceForGeneratedToken(this.migrationCandidates);
      });
  }

  selectedInstanceForGeneratedToken(migrationCandidates: string[]) {
    let selectedInstance = "";
    if (migrationCandidates && migrationCandidates.length === 1) {
      selectedInstance = migrationCandidates[0];
    }
    this.selectedMigrationCandidate = selectedInstance;
  }

  get serverName(): string {
    return this.subscription ? this.subscription.serverName : "";
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get shouldDisplayExtraActionsInDropdown() {
    return this.isMobile;
  }

  get isEnterprisePlus() {
    return !!this.subscription.meta.isEnterprisePlus || !!this.subscription.meta.isEnterprisePlusTrial;
  }

  get isEnterprisePlusTrial() {
    return this.subscription.meta.isEnterprisePlusTrial;
  }

  get isEnterpriseTrial() {
    return this.subscription.meta.isEnterpriseX && this.subscription.meta.isTrial;
  }

  get isEnterpriseX() {
    return !!this.subscription.meta.isEnterpriseTeam && !!this.subscription.meta.hasXrayPremium;
  }

  get hasPrivateLink() {
    return !!this.subscription.meta.hasPrivateLink;
  }

  handleSetNotification() {
    if (this.shouldDisableSubscriptionActions) {
      return;
    }
    this.$jfModal.showCustom(
      ModalSetNotification,
      "lg",
      { subscription: this.subscription },
      {
        title: this.setNotificationLabelText,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  get setNotificationLabelText() {
    return "Usage Notification";
  }

  get shouldDisableSubscriptionActions() {
    return this.isLoading || (!!this.subscription && this.subscription.status !== "Active");
  }

  get subscriptionPopoverProps(): JFEssentialsPopupMenuOptions {
    const triggerClasses = ["large-title"];
    if (this.shouldDisableSubscriptionActions) {
      triggerClasses.push("disabled");
    }
    return {
      theme: "flat-green",
      triggerClasses: triggerClasses,
      placement: "bottom-end",
      trigger: "click",
      disabled: this.shouldDisableSubscriptionActions,
      displayTriggerText: !this.isMobile,
      title: "Actions",
      titlePlacement: "left",
      iconName: "icon-arrow",
      iconStyles: ["transform:rotate(90deg)"],
      items: [
        {
          label: "Geolocation Restrictions",
          click: this.handleGeoRestriction,
          showCondition: () => this.isGeoRestrictionFeatureAvailable && !this.isEnterprisePlus,
          dataGtmEventTitle: "title-content-dropdown|geo-restriction-item",
        },
        {
          label: "IP/CIDR Whitelisting",
          click: this.handleChangeIPListing,
          showCondition: () =>
            this.subscription && !!this.subscription.meta.isK8s && !this.isEnterprisePlus && !this.isEnterpriseTrial,
          dataGtmEventTitle: "title-content-dropdown|ip-whitelist-item",
        },
        {
          label: "Request Cloud Provider Change",
          click: this.handleChangeProvider,
          showCondition: () =>
            this.subscription &&
            !this.subscription.meta.isMP &&
            !this.subscription.meta.isInternal &&
            !this.subscription.meta.isOSS &&
            !this.isEnterprisePlus &&
            !this.subscription.meta.isFree,
          dataGtmEventTitle: "title-content-dropdown|change-provider-item",
        },
        {
          label: "Request Cloud Region Change",
          showCondition: () => !this.subscription.meta.isFree && !this.isEnterprisePlus,
          click: this.handleChangeRegion,
          dataGtmEventTitle: "title-content-dropdown|change-region-item",
        },
        {
          label: "Enable CDN Distribution",
          click: this.handleEnableCdn,
          showCondition: () => this.showEnableCdnDropdownOption && !this.isEnterprisePlus,
          dataGtmEventTitle: "title-content-dropdown|enable-cdn-item",
        },
        {
          label: this.setNotificationLabelText,
          click: this.handleSetNotification,
          showCondition: () => this.subscription && (this.isEnterprisePlus || this.shouldDisplayExtraActionsInDropdown),
          dataGtmEventTitle: "title-content-dropdown|set-notifications-item",
        },
        {
          label: "Download Public Root Certificate",
          showCondition: () => this.isEnterprisePlus,
          dataGtmEventTitle: "title-content-options|get-root-certificates",
          click: this.handleDownloadCertificate,
        },
        {
          label: "PrivateLinks",
          showCondition: () =>
            this.hasPrivateLink &&
            (this.hasAtLeastOneAWSAccount || this.hasAtLeastOneAzureAccount || this.hasAtLeastOneGCPAccount) &&
            !this.isMobile &&
            !this.isEnterpriseTrial,
          dataGtmEventTitle: "title-content-options|private-link",
          click: () => this.handlePrivateLink(),
        },
        {
          label: "Downgrade Subscription",
          click: () =>
            this.handleRequestCancelSubscription(this.canRemoveXrayPremium ? REMOVE_SECURITY_PACK : DOWNGRADE),
          showCondition: () => this.isDownGradeAvailable,
          dataGtmEventTitle: "title-content-dropdown|downgrade-subscription-item",
        },
        {
          label: "Downgrade Subscription",
          click: () => this.handleRequestCancelSubscription(SELF_DOWNGRADE_TO_FREE),
          showCondition: () => this.isSelfDowngradeAvailable,
          dataGtmEventTitle: "title-content-dropdown|downgrade-subscription-item",
        },
        {
          label: "Cancel Subscription",
          click: () => this.handleRequestCancelSubscription(CANCEL),
          showCondition: () => this.isCancelAvailable,
          dataGtmEventTitle: this.subscription.meta.isJCR
            ? "title-content-dropdown|additional-actions-item"
            : "title-content-dropdown|cancel-subscription-item",
        },
        {
          label: "Enable Usage Logs",
          dataGtmEventTitle: "title-content-dropdown|enable-usage-logs",
          click: () => this.handleLogShippingEnrollment(),
          // showCondition: () => this.subscription.isPaying, // once enable usage logs will be available for all customers this will be the condition
          showCondition: () => this.enableUsageLogsFeatureFlag,
        },
        {
          label: "Generate Admin Token",
          showCondition: () => false,
          click: () => this.handleGenerateAdminToken(),
          dataGtmEventTitle: "title-content-dropdown|generate-admin-token-item",
        },
        {
          label: "Enable Artifactory Transfer",
          showCondition: () => this.canMigrateToCloud(),
          click: () => this.handleMigrationPlugin(),
          dataGtmEventTitle: "title-content-dropdown|generate-admin-token-item",
        },
      ],
    };
  }

  canMigrateToCloud() {
    return true;
  }

  async handleLogShippingEnrollment() {
    this.isEnrolledForLogShippingOldValue = this.subscription.isEnrolledForLogShipping;
    this.globalBus.$on("enrollForLogShipping", this.enrollForLogShippingChanged);
    this.$jfModal.showCustom(
      ModalDetailedUsageLogs,
      "md",
      {
        subscription: this.subscription,
      },
      {
        title: "Enable Detailed Usage Logs",
        forceDisplayTitle: true,
        forceDisplayCancelIcon: false,
        headerBorder: false,
        footerBorder: true,
        clickShouldClose: false,
        shakeIfCantClose: false,
        onDismiss: () => {
          if (this.subscription.isEnrolledForLogShipping !== this.isEnrolledForLogShippingOldValue) {
            this.$jfSubscriptions.logShippingEnrollment({
              accountNumber: this.subscription.accountNumber,
              enrollForLogShipping: this.subscription.isEnrolledForLogShipping,
            });
          }
        },
      },
    );
  }

  enrollForLogShippingChanged(value: boolean) {
    this.subscription.isEnrolledForLogShipping = value;
  }

  async handlePrivateLink() {
    this.$jfModal.showCustom(
      ModalPrivateLink,
      "xl",
      {
        subscription: this.subscription,
        accountNumber: this.subscription.accountNumber,
      },
      {
        title: "",
        onDismiss: () => this.globalBus.$emit("onRefreshTopologyData"),
        forceDisplayTitle: true,
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  async handleGenerateAdminToken() {
    await this.getMigrationCandidates();
    this.$jfModal.showCustom(
      ModalGenerateTokenAdmin,
      "lg",
      {
        migrationCandidates: this.migrationCandidates,
        selectedInstance: this.selectedMigrationCandidate,
      },
      {
        title: "Generate Admin Token",
        onDismiss: () => this.globalBus.$emit("onRefreshTopologyData"),
        forceDisplayTitle: true,
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  get hasAtLeastOneAWSAccount() {
    return this.subscription && this.subscription.jpus.some(jpu => jpu.cloudProvider === "amazon");
  }

  get hasAtLeastOneAzureAccount() {
    return this.subscription && this.subscription.jpus.some(jpu => jpu.cloudProvider === "azure");
  }

  get hasAtLeastOneGCPAccount() {
    return (
      ENABLE_GCP_PRIVATE_LINK === "true" &&
      this.subscription &&
      this.subscription.jpus.some(jpu => jpu.cloudProvider === "google")
    );
  }

  get isGeoRestrictionFeatureAvailable() {
    return false;
    // been ask to hide it for now , suppose to be added also for enterprise in the future
    let meta = this.subscription.meta;
    return this.subscription && !!meta.isK8s && (!!meta.isEnterprise || !!meta.isEnterpriseX);
  }

  get showEnableCdnDropdownOption() {
    const featureDisabled = true;
    return !featureDisabled && !!this.subscription.meta.isEligibleToEnableCdn && !this.subscription.meta.hasCdnEnabled;
  }

  get canCancelSubscription() {
    return (
      this.subscription &&
      !this.subscription.meta.isMP &&
      !this.subscription.meta.isInternal &&
      !this.subscription.meta.isOSS &&
      !this.subscription.meta.isTrial &&
      !this.isEnterprisePlus
    );
  }

  get isCancelAvailable() {
    return this.canCancelSubscription && (!!this.subscription.meta.isMonthlyPayment || !!this.subscription.meta.isFree);
  }

  get canRemoveXrayPremium() {
    const meta = this.subscription.meta;
    return meta.hasXrayPremium && meta.isMonthlyPayment;
  }

  get isDownGradeAvailable() {
    return (
      this.canCancelSubscription &&
      !this.proWithOutPipelines &&
      !this.subscription.meta.isFree &&
      !this.proTeamPrePaid &&
      !this.isSelfDowngradeAvailable
    );
  }

  get isSelfDowngradeAvailable() {
    return (
      !this.subscription.meta.isMP && !!this.subscription.meta.isProTeam && !!this.subscription.meta.isMonthlyPayment
    );
  }

  get proTeamPrePaid() {
    return this.subscription.meta.isProTeam && this.subscription.meta.isPrepaidPayment;
  }

  get subscriptionDtoFromFlatSubscription() {
    return { ...this.subscriptions.find(s => s.accountNumber === this.subscription.accountNumber) } as SubscriptionDTO;
  }

  handleEnableCdn() {
    this.$jfModal.showCustom(
      ModalEnableCdn,
      "md",
      {
        onApprove: async () => {
          try {
            const serverNamesToEnable = this.subscription.jpus.map(jpu => jpu.serverName);
            await this.$jfSubscriptions.enableCdn({ serverNames: this.subscription.jpus.map(jpu => jpu.serverName) });
            this.$jfNotification.success({
              duration: 2000,
              text: this.$jfMessages.enable_cdn_success,
            });
            const updatedSubscription: SubscriptionDTO = this.subscriptionDtoFromFlatSubscription;
            updatedSubscription.jpus = updatedSubscription.jpus.map(jpu =>
              serverNamesToEnable.includes(jpu.serverName) ? { ...jpu, cdnEnabled: true } : jpu,
            );

            setTimeout(() => {
              // should be trigger on the next tick to ensure the good display of the graphs
              this.globalBus.$emit("shouldRerenderGraphs");
            }, 0);

            this.globalBus.$emit("subscriptionUpdated", updatedSubscription);
            return true;
          } catch (error) {
            this.$log.error(error);
            this.notifyError(this.$jfMessages.enable_cdn_error);
          }
          return false;
        },
      },
      {
        title: "Request Cloud Provider Change",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  handleChangeProvider() {
    this.$jfModal.showCustom(
      ModalChangeProvider,
      "md",
      {
        subscription: this.subscription,
        regions: this.regionsList,
        onApprove: async (selectedProvider: JpuDTO["cloudProvider"], selectedRegion: RegionSelectResult) => {
          this.$jfLoaders.showGlobal();
          try {
            if (!this.subscription) {
              throw new Error("Subscription undefined");
            }

            await this.$jfSubscriptions.changeProvider({
              marketoCookie: marketoCookie(),
              serverName: this.serverName,
              requestedProvider: selectedProvider,
              requestedRegion: selectedRegion.label,
              currentProvider: this.subscription.cloudProviderCode,
            });

            await this.$jfLoaders.hideGlobal();

            this.$jfNotification.success({
              duration: 2000,
              text: this.$jfMessages.subscriptions_change_provider_success,
            });
            this.$jfWebeyez.send({ goal_key: "change_provider", isSuccess: true });
          } catch (error) {
            this.$log.error(error);
            await this.$jfLoaders.hideGlobal();

            this.notifyError(this.$jfMessages.subscriptions_change_provider_error);
            this.$jfWebeyez.send({
              goal_key: "change_provider",
              isSuccess: false,
              errorMessage: this.$jfMessages.subscriptions_change_provider_error,
            });
          }
        },
      },
      {
        title: "Request Cloud Provider Change",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  handleChangeRegion() {
    this.$jfModal.showCustom(
      ModalChangeRegion,
      "md",
      {
        subscription: this.subscription,
        regions: this.regionsList,
        onApprove: async (selectedRegion: RegionSelectResult) => {
          await this.$jfLoaders.showGlobal();

          try {
            if (!this.subscription) {
              throw new Error("Subscription is Undefined");
            }

            await this.$jfSubscriptions.changeRegion({
              marketoCookie: marketoCookie(),
              serverName: this.serverName,
              currentProvider: this.subscription.cloudProviderCode,
              currentRegion: this.subscription.region,
              requestedRegion: selectedRegion.label,
            });

            await this.$jfLoaders.hideGlobal();

            this.$jfNotification.success({
              duration: 2000,
              text: this.$jfMessages.subscriptions_change_region_success,
            });
            this.$jfWebeyez.send({ goal_key: "change_region", isSuccess: true });
          } catch (error) {
            this.$log.error(error);
            await this.$jfLoaders.hideGlobal();

            this.notifyError(this.$jfMessages.subscriptions_change_region_error);
            this.$jfWebeyez.send({
              goal_key: "change_region",
              isSuccess: false,
              errorMessage: this.$jfMessages.subscriptions_change_region_error,
            });
          }
        },
      },
      {
        title: "Request Cloud Region Change",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  ipsToSaveAreNew(originalIps: string[], newIps: string[]): boolean {
    // removing the original whitemark IP
    // (not returned by the modal but can be present in the original Ips returned by the server. So we should remove it to be accurate in the difference calculation)
    _pull(originalIps, "0.0.0.0/0");

    // building the array of diffrences between the original ips and the new ones
    const differences = [..._difference(originalIps, newIps), ..._difference(newIps, originalIps)];

    // if there are differences that means that the provided IP's are new and should be saved.
    return !!differences.length;
  }

  async handleChangeIPListing() {
    if (!this.subscription) {
      return;
    }

    const meta = this.subscription.meta;
    const isUnderLevelEnterprise = !(meta.isEnterprise || meta.isEnterpriseX || meta.isEnterpriseTeam); // note that E+ has a specific handling for this feature so it's not required here in SubscriptionTopology.vue

    let whiteListIPSResponse: WhiteListIPSResponse = {
      ips: [],
      inProgress: true,
    };
    this.$jfLoaders.showGlobal();
    // white listing only works in all cases except trial account.

    if (!isUnderLevelEnterprise) {
      try {
        whiteListIPSResponse = await this.$jfSubscriptions.getWhiteListIPs(
          this.subscription.accountNumber,
          this.subscription.serverName,
        );
      } catch (error) {
        this.$log.error(error);
        this.$jfLoaders.hideGlobal();
        this.notifyError(this.$jfMessages.subscriptions_whitelist_ips_fetch_error);
        return;
      }
    }
    this.$jfLoaders.hideGlobal();
    this.$jfModal.showCustom(
      ModalIPListing,
      "md",
      {
        subscription: this.subscription,
        ips: whiteListIPSResponse.ips,
        ipInProgress: whiteListIPSResponse.inProgress,
        isUnderLevelEnterprise: isUnderLevelEnterprise,
        onApprove: async (ips: string[]) => {
          try {
            if (this.ipsToSaveAreNew(whiteListIPSResponse.ips, ips)) {
              await this.$jfSubscriptions.updateWhiteListIPs(
                this.subscription.accountNumber,
                (this.subscription as FlatSubscription).serverName,
                {
                  ips,
                },
              );
              this.$jfNotification.success({
                duration: 1000,
                text: this.$jfMessages.subscriptions_whitelist_ips_set_success,
              });
              this.$jfWebeyez.send({ goal_key: "whitelist_ips", isSuccess: true });
            }
            return true;
          } catch (error) {
            this.$log.error(error);
            this.notifyError(this.$jfMessages.subscriptions_whitelist_ips_set_error);
            this.$jfWebeyez.send({
              goal_key: "whitelist_ips",
              isSuccess: false,
              errorMessage: this.$jfMessages.subscriptions_whitelist_ips_set_error,
            });
            return false;
          }
        },
      },
      {
        title: "IP/CIDR Whitelisting",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  get proWithOutPipelines() {
    const meta = this.subscription.meta;
    return meta.isCloudPro && (!meta.hasPipelines || meta.isPipelinesBlocked);
  }

  get isSimpleSubscription() {
    const meta = this.subscription.meta;
    return meta.isJCR || meta.isCloudPro;
  }

  handleRequestCancelSubscription(requestType: ChangeSubscriptionOptions) {
    this.$jfModal.showCustom(
      ModalRequestForCancelOrDownGradeSubscription,
      requestType == REMOVE_SECURITY_PACK ? "lg" : "md",
      {
        subscription: this.subscription,
        requestType,
        onApprove: async (reason: string, selectedRequestType: ChangeSubscriptionOptions): Promise<boolean> => {
          const currentRequestType = selectedRequestType || requestType;
          await this.$jfLoaders.showGlobal();
          let webeyezGoalKey: WebeyezGoalKeyNames = "downgrade";
          try {
            let updatedSubscription;
            switch (currentRequestType) {
              case CANCEL:
                webeyezGoalKey = "cancel";
                updatedSubscription = await this.$jfSubscriptions.cancelSubscription(
                  this.subscription.accountNumber,
                  this.serverName,
                  !!this.subscription.meta.isJCR,
                  reason,
                );
                break;
              case SELF_DOWNGRADE_TO_FREE:
                webeyezGoalKey = "downgrade";
                updatedSubscription = await this.$jfSubscriptions.downgradeSubscription(
                  this.subscription.accountNumber,
                  reason,
                  "FREE",
                );
                break;
              case DOWNGRADE:
                webeyezGoalKey = "downgrade";
                await this.$jfSubscriptions.sendDowngradeRequest(this.serverName);
                break;
              case REMOVE_SECURITY_PACK:
                updatedSubscription = await this.$jfSubscriptions.downgradeSubscription(
                  this.subscription.accountNumber,
                  reason,
                  "ENTERPRISE_MONTHLY",
                );
                break;
              default:
                throw new Error(`request type is unknown: ${currentRequestType}`);
            }

            await this.$jfLoaders.hideGlobal();

            this.$jfNotification.success({
              duration: 10000,
              text: this.getSuccessCancelRequestMessage(currentRequestType),
            });
            this.$jfWebeyez.send({ goal_key: webeyezGoalKey, isSuccess: true });
            if (updatedSubscription) {
              this.globalBus.$emit("subscriptionUpdated", updatedSubscription);
            }
            return true;
          } catch (error) {
            this.$log.error(error);
            await this.$jfLoaders.hideGlobal();
            this.notifyError(this.getErrorForCancelRequest(currentRequestType, error));
            this.$jfWebeyez.send({
              goal_key: webeyezGoalKey,
              isSuccess: false,
              errorMessage: this.getErrorForCancelRequest(currentRequestType, error),
            });

            return false;
          }
        },
      },
      {
        title: SubscriptionTitleComponent.getCancellationTitle(requestType),
        titleIconClasses:
          requestType === CANCEL || requestType === REMOVE_SECURITY_PACK
            ? ["icon-art-artifactory-edit", "mjf-green", "pr-1"]
            : [],
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
        submitOnEnterPress: false,
      },
    );
  }

  private static getCancellationTitle(requestType: ChangeSubscriptionOptions) {
    switch (requestType) {
      case CANCEL:
        return "Cancel Subscription";
      case DOWNGRADE:
        return "Downgrade Subscription Request";
      case REMOVE_SECURITY_PACK:
        return "Downgrade Subscription";
      case SELF_DOWNGRADE_TO_FREE:
        return "Downgrade to Free Subscription";
      default:
        throw new Error(`request type is unknown: ${requestType}`);
    }
  }

  private getErrorForCancelRequest(requestType: ChangeSubscriptionOptions, error: any): string {
    switch (requestType) {
      case CANCEL:
        return this.isSimpleSubscription
          ? this.$jfMessages.subscriptions_request_self_cancellation_error
          : this.$jfMessages.subscriptions_request_cancel_error;
      case DOWNGRADE:
      case SELF_DOWNGRADE_TO_FREE:
      case REMOVE_SECURITY_PACK:
        if (
          isRestClientError(error) &&
          error.httpBody &&
          error.httpBody.result === storeErrorsMapping.downgradeSubscription.narcissusTaskInProgress
        ) {
          return this.$jfMessages.subscriptions_narcissus_task_in_progress;
        }
        return this.$jfMessages.subscriptions_request_downgrade_error;
      default:
        throw new Error(`request type is unknown: ${requestType}`);
    }
  }

  private getSuccessCancelRequestMessage(requestType: ChangeSubscriptionOptions) {
    switch (requestType) {
      case CANCEL:
        return this.$jfMessages.subscriptions_request_self_cancellation_success;
      case DOWNGRADE:
        return this.$jfMessages.subscriptions_request_downgrade_success;
      case REMOVE_SECURITY_PACK:
        return this.$jfMessages.subscriptions_request_downgrade_xray_premium_success;
      case SELF_DOWNGRADE_TO_FREE:
        return this.$jfMessages.subscriptions_request_self_downgrade_success;
      default:
        throw new Error(`request type is unknown: ${requestType}`);
    }
  }

  async handleGeoRestriction() {
    if (!this.subscription) {
      return;
    }
    let geopIPCountries: GeoIpCountries;
    try {
      this.$jfLoaders.showGlobal();
      geopIPCountries = await this.$jfSubscriptions.getGeoRestrictionCountries(
        this.subscription.accountNumber,
        this.subscription.serverName,
      );
      this.$jfLoaders.hideGlobal();
    } catch (error) {
      this.$log.error(error);
      this.$jfLoaders.hideGlobal();
      this.notifyError(this.$jfMessages.subscriptions_Geo_Restriction_fetch_error);
      return;
    }
    this.openGeoRestrictionModal(geopIPCountries);
  }

  getGeoIPRestrictionsStatusLabel(geopIPCountries: GeoIpCountries): string {
    if (geopIPCountries.inProgress) {
      return ServerMetadataStatusPrefix.InProgress;
    }
    const nbCountries = geopIPCountries.geoIpRestrictionCountriesList.length;
    return nbCountries ? ServerMetadataStatusPrefix.Active : ServerMetadataStatusPrefix.NotActive;
  }

  getServerMetadataSubLabelClasses(metaDataValue: string) {
    if (metaDataValue.startsWith(ServerMetadataStatusPrefix.NotActive)) {
      return ["jf-almost-black"];
    }
    if (metaDataValue.startsWith(ServerMetadataStatusPrefix.InProgress)) {
      return ["jf-yellow"];
    }
    if (metaDataValue.startsWith(ServerMetadataStatusPrefix.Active)) {
      return ["mjf-green"];
    }
    return [];
  }

  openGeoRestrictionModal(geopIPCountries: GeoIpCountries): void {
    const serverName = this.subscription.serverName;
    const statusLabel = this.getGeoIPRestrictionsStatusLabel(geopIPCountries);
    const statusClasses = this.getServerMetadataSubLabelClasses(statusLabel);
    this.$jfModal.showCustom(
      ModalGeoRestriction,
      "lg",
      {
        fetchedGeoRestrictionCountries: geopIPCountries.geoIpRestrictionCountriesList,
        restrictionType: geopIPCountries.geoIpRestrictionType,
        isInProgress: geopIPCountries.inProgress,
        statusLabel,
        statusClasses,
        allCountries: allCountries,
        onApprove: async (request: UpdateGeoIPCountriesRequest) => {
          try {
            await this.$jfLoaders.showGlobal();
            await this.$jfSubscriptions.updateGeoIPCountries(this.subscription.accountNumber, serverName, request);
            await this.$jfLoaders.hideGlobal();
            this.$jfNotification.success({
              duration: 1000,
              text: this.$jfMessages.subscriptions_Geo_Restriction_set_success,
            });
            this.$jfWebeyez.send({ goal_key: "geo_restrictions", isSuccess: true });
            return true;
          } catch (error) {
            await this.$jfLoaders.hideGlobal();
            this.$log.error(error);
            this.notifyError(this.$jfMessages.subscriptions_Geo_Restriction_set_error);
            this.$jfWebeyez.send({
              goal_key: "geo_restrictions",
              isSuccess: false,
              errorMessage: this.$jfMessages.subscriptions_Geo_Restriction_set_error,
            });

            return false;
          }
        },
      },
      {
        title: "Geolocation Restrictions",
        // forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
        submitOnEnterPress: false,
      },
    );
  }

  async handleDownloadCertificate() {
    if (!this.isEnterprisePlus) {
      return;
    }
    this.$jfModal.showCustom(
      ModalGetRootCertificate,
      "md",
      {
        accountNumber: this.subscription.accountNumber,
      },
      {
        title: "Download Public Root Certificate",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  async handleMigrationPlugin() {
    this.$jfModal.showCustom(
      ModalMigrationPlugin,
      "lg",
      {
        subscription: this.subscription,
      },
      {
        title: "Artifactory Configuration Transfer",
        forceDisplayTitle: true,
        forceDisplayCancelIcon: false,
        headerBorder: false,
        footerBorder: true,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }
}
