
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import ManageEndpoint from "@/components/views/subscriptions/modals/privateLink/ManageEndpoint.vue";
import FirstSetup from "@/components/views/subscriptions/modals/privateLink/FirstSetup.vue";
import LinkMenu from "@/components/views/subscriptions/modals/privateLink/LinkMenu.vue";
import InProgress from "@/components/views/subscriptions/modals/privateLink/InProgress.vue";
import { FlatSubscription, JpusFormatedForTopology } from "@/types/localtypes";
import AddAnotherPrivateLinkStep from "@/components/views/subscriptions/modals/privateLink/AddAnotherPrivateLinkStep.vue";
import { JpuDTO, ManageEndpointRequest } from "@jfrog-ba/myjfrog-common";

const FIRST_SETUP = "first_setup";
const LINK_MENU = "link_menu";
const CREATE_ENDPOINT = "create_endpoint";
const EDIT_ENDPOINT = "edit_endpoint";
const IN_PROGRESS = "in_progress";
const ADD_ANOTHER_PRIVATE_LINK_STEP = "add_another_private_link_step";

@Component({
  name: "ModalPrivateLink",
  components: {
    AddAnotherPrivateLinkStep,
    InProgress,
    LinkMenu,
    FirstSetup,
    ManageEndpoint,
    LoadingMask,
  },
})
export default class ModalPrivateLink extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Prop() private jpu!: JpusFormatedForTopology;
  @Inject() readonly globalBus!: Vue;

  isLoading: boolean = false;
  currentStep = "";
  eventBus: Vue = new Vue();
  isDisabled = false;

  currentCloudProvider: string = "";

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();

    // init step, need to where i come from as initial step
    this.currentStep = LINK_MENU;
    this.setModalName();
  }

  get isShowCancel() {
    if (this.isShowInProgress) {
      return false;
    }
    return true;
  }

  get buttonCancelName() {
    if (this.isShowFirstSetup || this.isShowLinkMenu) {
      return "CLOSE";
    }
    return "CANCEL";
  }

  get buttonSuccessName() {
    if (this.isShowInProgress) {
      return "FINISH";
    }
    if (this.isShowEditEndpoint) {
      return "SAVE";
    }
    if (this.isShowCreateEndpoint) {
      return "CREATE";
    }

    return "FINISH";
  }

  get LoadingMaskClasses() {
    if (this.isShowFirstSetup) {
      return ["no-padding"];
    }
    return [];
  }

  get isShowSuccessButton() {
    if (this.isShowFirstSetup || this.isShowLinkMenu) {
      return false;
    }
    return true;
  }

  get isShowLinkMenu() {
    return this.currentStep === LINK_MENU;
  }
  get isShowFirstSetup() {
    return this.currentStep === FIRST_SETUP;
  }
  get isShowCreateEndpoint() {
    return this.currentStep === CREATE_ENDPOINT;
  }
  get isShowEditEndpoint() {
    return this.currentStep === EDIT_ENDPOINT;
  }
  get isShowInProgress() {
    return this.currentStep === IN_PROGRESS;
  }

  get isShowAddAnotherPrivateLink() {
    return this.currentStep === ADD_ANOTHER_PRIVATE_LINK_STEP;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return { loading: this.isLoading };
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setCurrentPage(stepName: string) {
    this.currentStep = stepName;
    this.setModalName();
  }

  redirectToCreatePrivateLink(cloudProvider: ManageEndpointRequest["cloudProviderCode"]) {
    this.currentCloudProvider = this.cloudProviderMapper(cloudProvider);
    this.setCurrentPage(CREATE_ENDPOINT);
  }

  redirectToEditPrivateLink(cloudProvider: ManageEndpointRequest["cloudProviderCode"]) {
    this.currentCloudProvider = this.cloudProviderMapper(cloudProvider);
    this.setCurrentPage(EDIT_ENDPOINT);
  }

  redirectToFirstStep() {
    this.setCurrentPage(FIRST_SETUP);
  }

  redirectToAddAnotherPrivateLink() {
    this.setCurrentPage(ADD_ANOTHER_PRIVATE_LINK_STEP);
  }

  gotoViewAllEndpoints() {
    this.setCurrentPage(LINK_MENU);
  }

  gotoInProgress() {
    this.setCurrentPage(IN_PROGRESS);
  }

  updateModalSize(size: string) {
    this.$emit("onResize", size);
  }

  setDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setModalName() {
    this.$emit("onTitleChanged", this.modalName);
  }

  get modalName() {
    if (this.isShowCreateEndpoint) {
      return "CREATE PRIVATE CONNECTION";
    }
    if (this.isShowInProgress) {
      return "";
    }
    if (this.isShowEditEndpoint) {
      return "EDIT PRIVATE CONNECTION";
    }
    if (this.isShowLinkMenu || this.isShowFirstSetup || this.isShowAddAnotherPrivateLink) {
      return "MANAGE PRIVATE CONNECTIONS";
    }

    return "MANAGE PRIVATE CONNECTIONS";
  }

  onSuccess() {
    if (this.isShowCreateEndpoint || this.isShowEditEndpoint) {
      this.eventBus.$emit("onManageEndpoint");
      return;
    }
    if (this.isShowInProgress) {
      this.setCurrentPage(LINK_MENU);
      return;
    }
    this.closeModal();
  }

  closeModal() {
    this.globalBus.$emit("onRefreshTopologyData");
    this.$jfModal.dismiss();
  }

  dismiss() {
    if (this.isShowCreateEndpoint || this.isShowEditEndpoint) {
      this.setCurrentPage(LINK_MENU);
      return;
    }
    this.closeModal();
  }
  cloudProviderMapper(cloudProviderName: ManageEndpointRequest["cloudProviderCode"]): any {
    switch (cloudProviderName) {
      case "AWS":
        return "amazon";
      case "AZURE":
        return "azure";
      case "GCP":
        return "google";
      default:
        return cloudProviderName;
    }
  }
}
