
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { usageExposerService } from "@/services/usageExposer";
import { ChartData, FlatSubscription, SizeUnit } from "@/types/localtypes";
import {
  CUAggregatedUsageBreakdownResponse,
  CUAggregatedUsageBreakdownResponseRow,
  CUUsageBreakdownCSVRequest,
} from "@jfrog-ba/myjfrog-common";
import { JFDateField } from "jfrog-ui-vue-essentials";
import BarChart from "@/components/charts/BarChart.vue";
import { ChartsProps, TooltipItem } from "@/types/charts";
import { bytesTo } from "@/filters/units";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import { Alert } from "@/types/layout/contentNode";
import SvgInline from "@/components/common/SvgInline.vue";

@Component({
  name: "UsageBreakDown",
  components: {
    SimpleAlert,
    JFDateField,
    BarChart,
    SvgInline,
  },
})
export default class UsageBreakDown extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Inject() readonly globalBus!: Vue;

  startDate = new Date();
  endDate = new Date();
  today = new Date();
  minDate = new Date();
  chartData: ChartsProps = {};
  selectedGranularity: string = "daily";
  aggregatedUsage: CUAggregatedUsageBreakdownResponse | null = null;
  sizeUnit: SizeUnit = "GB";
  isLoading = false;
  showGraph = false;
  totalAll = 0;
  tabId: string = "";
  dateChanged = true;
  usageLoadTimeout: number = -1;
  usageLoadDelay = 1200;
  alertData: Alert = {
    message: `Data displayed is for the last 62 days.</br>
      <a style="color: #40BE46;" href="https://www.jfrog.com/confluence/display/JFROG/MyJFrog+Cloud+Portal#MyJFrogCloudPortal-ManagingUsage" target="_blank">Read More</a>`,
    type: "inlineSvg",
  };

  created() {
    this.getMinUsageDate();
    this.globalBus.$on("relevantViewChangedForFeatureNotification", this.onTabChange);
  }

  iconSrc(icon: string): string | null {
    if (icon) {
      try {
        return this.$jfImages.get(icon);
      } catch (e) {
        this.$log.error("Failed to retrieve icon : " + icon);
      }
    }
    return null;
  }

  get calcEndOfStartTime() {
    return this.endDate < this.today ? this.endDate : this.today;
  }

  onDateChange() {
    this.dateChanged = true;
    this.getAggrigatedUsage();
  }

  onTabChange(tabId: string) {
    this.tabId = tabId;
    this.getAggrigatedUsage();
  }

  private getAggrigatedUsage() {
    clearTimeout(this.usageLoadTimeout);
    if (this.tabId === "_monthlyBreakdown" && (!this.showGraph || this.dateChanged)) {
      this.usageLoadTimeout = setTimeout(() => {
        this.loadAggregatedUsage();
      }, this.usageLoadDelay);
    }
    this.usageLoadDelay = 0;
  }

  async getMinUsageDate() {
    this.isLoading = true;
    this.globalBus.$emit("loading", true);
    try {
      const minDateStr = await usageExposerService.getMinimumUsageDate(this.subscription.accountNumber);
      this.isLoading = false;
      this.globalBus.$emit("loading", false);

      if (minDateStr) {
        this.minDate = new Date(minDateStr);
        const limitDate = this.$dateUtils.addDays(new Date(), -62);
        if (this.minDate < limitDate) {
          this.minDate = limitDate;
        }
        this.startDate = this.minDate;
        this.globalBus.$emit("showUsageBreakdown");
      }
    } catch (e) {
      if (e.httpStatus !== 404) {
        this.$jfNotification.error({ text: "Error while getting usage breakdown min date" });
      }
    }
  }

  async loadAggregatedUsage() {
    this.aggregatedUsage = null;
    let startDate = this.$dateUtils.toSqlFormat(this.startDate);
    const endDate = this.$dateUtils.toSqlFormat(this.endDate);
    if (startDate > endDate) {
      this.$jfNotification.warn({ text: "start date should be equals or before end date." });
      this.endDate = this.startDate;
      startDate = endDate;
    }
    this.isLoading = true;
    this.globalBus.$emit("loading", true);
    try {
      const data = await usageExposerService.getAggregatedUsage({
        subscription: this.subscription.accountNumber,
        startDate: startDate,
        endDate: endDate,
        breakdownBy: "repository",
      });
      if (!data) {
        this.showGraph = false;
      }
      this.isLoading = false;
      this.globalBus.$emit("loading", false);
      if (data?.remaining) {
        let remaining = data.remaining;
        remaining.repositoryName = `Other (${remaining.numOfRepositories})`;
        data.top.push(remaining);
      }
      this.aggregatedUsage = data as CUAggregatedUsageBreakdownResponse;
      this.prepareChartData();
    } catch (e) {
      this.showGraph = false;
      this.globalBus.$emit("loading", false);
      this.isLoading = false;
      if (e.httpStatus !== 404) {
        this.$jfNotification.error({ text: e.message });
      }
    }
    this.dateChanged = false;
  }

  prepareChartData() {
    let datasets = [
      {
        label: "Download",
        backgroundColor: "#8744E1",
        data: this.aggregatedUsage?.top.map(d => bytesTo(d.totalDownloadBytes, this.sizeUnit)),
      },
      {
        label: "Upload",
        backgroundColor: "#5CCFFE",
        data: this.aggregatedUsage?.top.map(d => bytesTo(d.totalUploadBytes, this.sizeUnit)),
      },
    ];

    this.chartData = {
      data: {
        labels: this.aggregatedUsage?.top.map(d => d.repositoryName),
        datasets: datasets,
      },
      customOptions: this.chartOptions,
    };
    this.totalAll = 0;
    this.aggregatedUsage?.top.forEach(a => (this.totalAll += a.totalDownloadBytes + a.totalUploadBytes));
    this.showGraph = true;
  }

  get chartTitle() {
    return `Total Data Transfer by Repository (${this.sizeUnit})`;
  }

  get chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
      },
      legend: { display: false },
      tooltips: {
        backgroundColor: "#ffffff",
        titleFontColor: "#414857",
        bodyFontColor: "#556274",
        multiKeyBackground: "#ffffff",
        borderColor: "#dddddd",
        mode: "index",
        position: "nearest",
        callbacks: {
          title: (tooltipItems: TooltipItem[]) => {
            const d: CUAggregatedUsageBreakdownResponseRow | undefined = this.aggregatedUsage?.top[
              tooltipItems[0].index
            ];
            if (!d) {
              return "";
            }
            const total = bytesTo(d.totalDownloadBytes + d.totalUploadBytes, this.sizeUnit);
            const percent = (((d.totalDownloadBytes + d.totalUploadBytes) / this.totalAll) * 100).toFixed(0);
            return `Total Data Transfer  ${total} ${this.sizeUnit} (${percent}%)`;
          },
          label: (tooltipItem: TooltipItem, data: ChartData) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const label = dataset.label || "";
            const value = dataset.data[tooltipItem.index];
            return this.chartTooltipLabel(
              "Total Data Transfer  99.99 GB (99%)".length,
              label,
              value + " " + this.sizeUnit,
            );
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            gridlines: {
              display: true,
              borderDash: [8, 4],
              color: "#dddddd",
            },
          },
        ],
      },
    };
  }

  chartTooltipLabel(titleLength: number, label: string, value: string) {
    let result = label;
    for (let i = 0; i < titleLength - (label.length + value.length); i++) {
      result += " ";
    }
    result += "     ";
    result += value;
    return result;
  }

  exportCsv() {
    const request: CUUsageBreakdownCSVRequest = {
      subscription: this.subscription.accountNumber,
      startDate: this.$dateUtils.toSqlFormat(this.startDate),
      endDate: this.$dateUtils.toSqlFormat(this.endDate),
      breakdown: "daily",
      breakdownBy: "repository",
    };
    usageExposerService
      .getUsageBreakdownCSV(request)
      .then(data => {
        this.exportCsvContent(data);
      })
      .catch(e => {
        this.$jfNotification.error({ text: "Error while trying to download the csv" });
      });
  }

  private exportCsvContent(data: string) {
    let csvContent = "data:text/csv;charset=utf-8," + data;
    const startDateStr = this.$dateUtils.format(this.startDate, "YYYY-MM-DD");
    const endDateStr = this.$dateUtils.format(this.endDate, "YYYY-MM-DD");
    const filename = `Jfrog_${startDateStr}_${endDateStr}_DT_By_Repository.csv`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
}
