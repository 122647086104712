
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription } from "@/types/localtypes";
import { JFTextField } from "jfrog-ui-vue-essentials";
import { Component, Prop, Vue } from "vue-property-decorator";

interface IPItem {
  id: number;
  ip: string;
}
@Component({
  name: "ModalIPListing",
  $_veeValidate: { validator: "new" },
  components: { JFTextField, LoadingMask },
})
export default class ModalIPListing extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Prop() private ips!: string[];
  @Prop({ default: false })
  private ipInProgress!: boolean;
  @Prop({ default: false })
  private serverInProgress!: boolean;
  @Prop({ default: false })
  private isUnderLevelEnterprise!: boolean;
  @Prop() private onApprove!: (ips: string[]) => Promise<boolean>;
  updatedIps: IPItem[] = [];
  nextId = 0;
  initialyEmpty = false;
  isLoading: boolean = false;
  whitemarkIP = "0.0.0.0/0";
  initialIpsWrapperHeight: number = 300;
  componentMounted: boolean = false;
  viewLoaded: boolean = false;

  get statusHTML() {
    const status = this.ipInProgress ? "In Progress" : this.initialyEmpty ? "Not Active" : "Active";
    const colorClass = this.ipInProgress ? "warning" : this.initialyEmpty ? "jf-almost-black" : "mjf-green";
    return `<strong><span class="${colorClass}">${status}</span></strong>`;
  }

  get proXTrialDisclaimer() {
    return this.$jfMessages.subscriptions_whitelist_disclaimer_proX_trial;
  }
  get displayProXTrialDisclaimer() {
    const meta = this.subscription.meta;
    return meta.isCloudProX && meta.isTrialXray && !meta.isTrial;
  }
  get hasUpdatedIps() {
    return this.updatedIps.length > 0;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get modalWrapperClasses() {
    let classes: string[] = [this.$mq];
    if (this.shouldDisableConfirmBtn) {
      classes.push("disabled");
    }
    return classes;
  }

  get headerText() {
    return this.isUnderLevelEnterprise
      ? "*This feature is available only for Enterprise and Enterprise+ subscriptions"
      : "Enable IP or CIDR whitelisting by adding each address below.";
  }

  get ipsWrapperStyles() {
    let height = this.initialIpsWrapperHeight;
    if (this.componentMounted) {
      /* We need to fix the ipsWrapper height to his maximum to make the scrollbar always work as expected.
       * the perfect height of the ipsWrapper is : modalBody maxHeight - offset between wrapper and modalBody
       */

      const modalBody = document.getElementsByClassName("modal-body")[0] as HTMLDivElement;
      const modalBodyMaxHeightStr = modalBody.style.maxHeight as string; // --> "XXXpx" (string)
      const modalBodyMaxHeightNumber = parseInt(modalBodyMaxHeightStr.substring(0, modalBodyMaxHeightStr.length - 2)); // -> XXX (number)
      const ipsWrapper = document.getElementsByClassName("wl-ips-wrapper")[0] as HTMLDivElement;
      const ipsWrapperOffsetTop = ipsWrapper.offsetTop; // height between the start of the modalBody and the ipsWrapper
      height = modalBodyMaxHeightNumber - ipsWrapperOffsetTop;
    }
    return {
      height: height + "px",
    };
  }

  mounted() {
    setTimeout(() => {
      this.viewLoaded = true;
    }, 150);
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();

    this.ips.forEach(ip => {
      if (ip !== this.whitemarkIP) {
        this.updatedIps.push({
          id: this.nextId++,
          ip: ip,
        });
      }
    });

    if (!this.hasUpdatedIps) {
      this.initialyEmpty = true;
      this.addIp();
    }
    this.componentMounted = true;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  async confirm() {
    if (!this.$validator.validateAll()) {
      return;
    }

    if (this.filledIPsAreEmpty) {
      this.$jfModal.confirm({
        danger: true,
        size: "sm",
        body: this.$jfMessages.subscriptions_whitelist_ips_deactivate_confirmation,
        buttonsText: { confirm: "Ok", cancel: "Cancel" },
        onConfirm: () => {
          this.approve();
          return true;
        },
      });
    } else {
      this.approve();
    }
  }

  async approve() {
    // - removing all whitemark IP's (0.0.0.0/0)
    // - adding CIDR of 32 if not provided by customer

    this.isLoading = true;
    const ipsToSave: string[] = this.filledIps
      .filter((ipItem: IPItem) => ipItem.ip !== this.whitemarkIP)
      .map((ipItem: IPItem) => {
        const ip = ipItem.ip;
        return ip.indexOf("/") === -1 ? ip + "/32" : ip;
      });
    const canClose = await this.onApprove(ipsToSave);
    this.isLoading = false;
    if (canClose) {
      this.$jfModal.closeAll();
    }
  }
  onAddIp() {
    if (!this.featureEnabled) {
      return;
    }
    this.addIp();
  }

  onRemoveAll() {
    if (!this.featureEnabled) {
      return;
    }
    if (this.updatedIps.length === 1) {
      //only 1 element > the onRemoveIp() method is invoked with it proper logic.
      this.onRemoveIp(this.updatedIps[0]);
      return;
    }
    this.updatedIps = [];
    this.addIp();
  }

  onRemoveIp(ipItem: IPItem) {
    if (!this.featureEnabled) {
      return;
    }
    if (this.updatedIps.length > 1) {
      this.removeIp(ipItem);
      return;
    }
    if (this.updatedIps.length === 1 && !this.filledIPsAreEmpty) {
      this.errors.clear();
      //clearing the first ip if removing it when there is only 1 ip not empty
      this.updatedIps[0].ip = "";
    }
  }

  addIp() {
    const id = this.nextId++;
    this.updatedIps.push({ id: id, ip: "" });
  }

  removeIp(ipItem: IPItem) {
    const indexToRemove = this.updatedIps.indexOf(ipItem);
    this.updatedIps.splice(indexToRemove, 1);
  }

  get filledIps() {
    return this.updatedIps.filter((ip: IPItem) => {
      return ip.ip !== "";
    });
  }
  get updatedIPsAreEmpty() {
    return this.updatedIps.length === 0;
  }
  get filledIPsAreEmpty() {
    return this.filledIps.length === 0;
  }

  get hasError() {
    // @ts-ignore
    return this.errors.items.length > 0;
  }

  get inProgress() {
    return this.serverInProgress || this.ipInProgress;
  }

  get featureEnabled() {
    return !this.inProgress && !this.isUnderLevelEnterprise;
  }

  get shouldDisableConfirmBtn() {
    return this.isLoading || !this.featureEnabled || this.hasError;
  }

  onKeyDown(e: any) {
    if (["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }
    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }
  onBlur(e: any) {
    if (this.viewLoaded) {
      this.$validator.validate(e.target.name);
    }
  }
}
