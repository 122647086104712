
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CreateNewButton",
})
export default class CreateNewButton extends Vue {
  @Prop() private onClick!: () => any;
  @Prop() private disabled!: boolean;
}
