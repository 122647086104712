
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ModalEnableCdn",
  $_veeValidate: { validator: "new" },
  components: {
    LoadingMask,
  },
})
export default class ModalEnableCdn extends Vue {
  @Prop() private onApprove!: () => Promise<boolean>;
  isLoading: boolean = false;

  get loadingMaskProps(): LoadingMaskProps {
    return { loading: this.isLoading };
  }
  eulaUrl = "https://www.jfrog.com";

  get explanationsText() {
    return `Click Confirm to enable CDN Distribution and agree to the <a blue-link href="${this.eulaUrl}" target="_blank">Terms and Conditions</a>.<br/>You will receive an email once your CDN setup is up and running, allowing you to complete the final configuration process.`;
  }

  get canSendForm() {
    return !this.isLoading && !this.errors.any();
  }

  async sendForm() {
    if (!(await this.$validator.validateAll()) || !this.canSendForm) {
      return;
    }
    this.isLoading = true;
    const canClose = await this.onApprove();
    this.isLoading = false;
    if (canClose) {
      this.dismiss();
    }
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }
}
