
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Route } from "vue-router";
import { SubscriptionDTO, UserDTO } from "@jfrog-ba/myjfrog-common";
import AsyncComponent from "@/components/common/AsyncComponent.vue";
import LoadingMask from "@/components/common/LoadingMask.vue";
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import CTAUpgradeToProXTrigger from "@/components/content/marketplace/aws/CTAUpgradeToProXTrigger.vue";
import MonthlyEOYDiscountTrigger from "@/components/content/promotions/monthlyEOYDiscount/MonthlyEOYDiscountTrigger.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageView from "@/components/layout/PageView.vue";
import SubscriptionTopology from "@/components/views/subscriptions/ePlus/SubscriptionTopology.vue";
import SubscriptionGraphs from "@/components/views/subscriptions/subscriptionGraphs/SubscriptionGraphs.vue";
import SubscriptionResume from "@/components/views/subscriptions/SubscriptionResume.vue";
import SubscriptionTitleComponent from "@/components/views/subscriptions/SubscriptionTitleComponent.vue";
import { AsyncComponentProperties } from "@/types/asyncComponent";
import { PageTitleProps } from "@/types/layout/pageTitle";
import { FlatSubscription, Region } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration } from "@/types/services/menus";
import { TabProps } from "@/types/tabs";
import UsageTabs from "@/components/views/subscriptions/subscriptionGraphs/UsageTabs.vue";
import UsageBreakDown from "@/components/views/subscriptions/subscriptionGraphs/UsageBreakDown.vue";

type TabId = "_tabUsage" | "_tabTopology";

@Component({
  name: "SubscriptionsDetail",
  components: {
    UsageTabs,
    Tab,
    Tabs,
    LoadingMask,
    PageView,
    PageTitle,
    PageSection,
    SubscriptionResume,
    SubscriptionGraphs,
    AsyncComponent,
    SubscriptionTopology,
    UsageBreakDown,
  },

  beforeRouteUpdate(to, from, next) {
    let thus = this as SubscriptionsDetail;
    thus.setPrevRoute(from);
    next();
    thus.loadComponent();
  },
  beforeRouteEnter(to: Route, from: Route, next: any): any {
    next((vm: SubscriptionsDetail) => {
      vm.setPrevRoute(from);
    });
  },
})
export default class SubscriptionsDetail extends Vue {
  @Prop() private activeTabRouteParam!: TabId;
  @Inject() readonly globalBus!: Vue;
  @Getter("user", { namespace: "user" })
  user!: UserDTO;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;
  @Action("updateSubscription", { namespace: "subscriptions" })
  updateSubscription!: (subscriptionToUpdate: SubscriptionDTO) => void;

  @Watch("subscription")
  async onSubscriptionChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
    this.setViewContext(this.defineViewContext());
  }

  defineViewContext(): ViewContext {
    return this.subscriptions && this.subscription
      ? { subscriptionMetas: this.subscriptions.map(s => s.meta), currentSubscriptionMeta: this.subscription.meta }
      : {};
  }

  accountNumber!: SubscriptionDTO["accountNumber"];
  subscription: FlatSubscription | null = null;
  subscriptions: SubscriptionDTO[] | null = null;
  regionsList: Region[] | null = null;
  eventBus = new Vue();

  prevRouteObj: {
    _prevRoute: Route | undefined;
  } = { _prevRoute: undefined };

  initialActiveTabId: TabId = "_tabUsage";
  subscriptionGraphRef = 0;
  subscriptionGraphEplusRef = 0;
  forceLoading = false;
  detailsTabsHeight = 0;
  isUpgradableToProX = false;
  currentSubscriptionResumeComponentKey = 0;
  hasUsageBreakdown = false;

  setPrevRoute(prevRoute: Route) {
    this.$set(this.prevRouteObj, "_prevRoute", prevRoute);
  }

  onTabChange(tab: TabProps) {
    if ((tab.id as TabId) === "_tabUsage") {
      this.forceLoading = true;
      setTimeout(() => (this.forceLoading = false), 100);
    }
    this.globalBus.$emit("relevantViewChangedForFeatureNotification", tab.id, this.subscription);
    setTimeout(() => {
      this.calcTabHeight();
    }, 0);
  }

  get tabStyles() {
    return {
      // background: "red",
    };
  }

  get tabsTheme() {
    if (this.hasUsageBreakdown) {
      return "basic";
    }
    return "light";
  }
  get calcStyleForDetails() {
    if (this.subscription && (this.subscription.meta.isEnterprisePlus || this.subscription.meta.isEnterprisePlus)) {
      return { padding: 0, margin: 0, minHeight: "400px" };
    }
    return { marginTop: "8px", minHeight: "400px" };
  }

  get tabHeaderStyles() {
    return {
      // background: "red",
    };
  }

  get tabContentWrapperStyles() {
    return {
      height: this.detailsTabsHeight + "px",
      // overflowY: "visible",
      background: "white",
    };
  }

  get showEnterprisePlus() {
    return (
      this.subscription && (this.subscription.meta.isEnterprisePlus || this.subscription.meta.isEnterprisePlusTrial)
    );
  }

  get subscriptionResumeAsyncConfig(): AsyncComponentProperties {
    return {
      load: this.fetchSubscription,
      component: SubscriptionResume,
      spinnerConfig: { size: "m" },
      autoHeight: true,
      eventBus: this.eventBus,
      onReload: () => true,
    };
  }

  get bgColor(): string {
    return this.subscription ? "" : "#ffffff";
  }

  get userHasMultipleSubscriptions() {
    return this.user && this.user.subscriptionsAccountNumbers.length > 1;
  }

  get pageTitleProps(): PageTitleProps {
    return {
      title: this.subscription ? this.subscription.meta.displayName : "Loading Details...",
      backButtonConfig: { show: this.shouldDisplayTitleBackButton, text: this.titleBackButtonText },
      leftOptionComponentConfig: this.pageTitleLeftComponentConfig,
      rightOptionComponentConfig: {
        component: SubscriptionTitleComponent,
        props: {
          subscription: this.subscription,
          isLoading: this.isLoading,
          regionsList: this.regionsList,
        },
      },
    };
  }

  get pageTitleLeftComponentConfig(): PageTitleProps["leftOptionComponentConfig"] {
    if (this.displayEOYPromotion) {
      return {
        component: MonthlyEOYDiscountTrigger,
        props: {},
      };
    }
    if (this.displayAWSUpgradeToProXCTA) {
      return {
        component: CTAUpgradeToProXTrigger,
        props: {},
      };
    }
    return undefined;
  }

  get displayAWSUpgradeToProXCTA() {
    if (!this.subscription || this.subscription.status !== "Active") return false;
    // display if current subscription is AWS Pro and if the user doesn't have another AWS ProX subscription
    const currentIsAwsPro = this.subscription.meta.isAWSMP && this.subscription.meta.isCloudPro;
    return currentIsAwsPro && this.isUpgradableToProX;
  }

  get displayEOYPromotion() {
    // currently deactivated
    return false;
    // display if ALL subscriptions are monthly PRO or monthly PRO_X
    // return (
    //   this.subscriptions &&
    //   !!this.subscriptions.length &&
    //   this.subscriptions.length ===
    //     this.subscriptions.filter(subscription => {
    //       const meta = subscription.meta;
    //       return (
    //         !meta.isInternal &&
    //         !meta.isOSS &&
    //         !meta.isMP &&
    //         meta.isMonthlyPayment &&
    //         (meta.isCloudPro || meta.isCloudProX)
    //       );
    //     }).length
    // );
  }

  get serverName(): string {
    return this.subscription ? this.subscription.serverName : "";
  }

  get previousRoute() {
    return this.prevRouteObj._prevRoute;
  }

  get isLoading() {
    return !this.subscription || !this.previousRoute || !this.regionsList || this.forceLoading;
  }

  get shouldDisplayTitleBackButton() {
    return !this.isLoading && (this.userHasMultipleSubscriptions || this.comeFromBillingAccountsView);
  }

  get titleBackButtonText() {
    if (this.comeFromBillingAccountsView) {
      return "Billing Account";
    }
    return this.comeFromSubscriptionList ? "Subscriptions List" : "Back";
  }

  get comeFromBillingAccountsView() {
    return !this.isLoading && !!this.previousRoute && this.previousRoute.name === "billing-accounts";
  }

  get comeFromSubscriptionList() {
    return !this.isLoading && !!this.previousRoute && this.previousRoute.name === "subscriptions.list";
  }

  fetchSubscription(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.subscriptions = await this.$jfSubscriptions.getSubscriptions();
        const subscription = this.subscriptions.find(subscription => subscription.accountNumber === this.accountNumber);
        this.subscription = subscription ? this.$jfSubscriptions.transformSubscription(subscription) : null;
        this.fetchSubscriptionIsUpgradableToProX();
        resolve({ subscription: this.subscription });
      } catch (error) {
        this.$log.error("Error while fetching subscription", error);
        if (error.httpStatusText === 500) {
          this.$jfNotification.error({ text: this.$jfMessages.app_something_went_wrong });
        } else {
          this.notFound();
        }
      }
    });
  }

  upgradeToProXCanBeFetched() {
    // only AWS MP Pro subscription can fetch the upgradability to ProX for now
    return this.subscription && this.subscription.meta.isAWSMP && this.subscription.meta.isCloudPro;
  }

  async fetchSubscriptionIsUpgradableToProX() {
    if (!this.subscription) return;
    if (!this.upgradeToProXCanBeFetched()) return;
    this.isUpgradableToProX = await this.$jfSubscriptions.isSubscriptionUpgradable(
      this.subscription.accountNumber,
      "AWS_PROX",
    );
  }

  notFound() {
    this.$router.replace({ path: "/404" });
  }

  async fetchRegionsList() {
    try {
      this.regionsList = await this.$jfSubscriptions.getRegionsList();
    } catch (error) {
      this.$log.error(error);
      this.$jfNotification.error({ text: this.$jfMessages.subscription_regions_list_fetch_failed });
    }
  }

  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscription) {
      const meta = this.subscription.meta;
      return await this.$jfMenus.defineContextualMenuConfiguration(
        this.serverName,
        meta,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  calcTabHeight() {
    const ePlusTabs = (this.$refs.ePlusTabs as Vue).$el;
    const pageTitle = (this.$refs.pageTitle as Vue).$el;
    const ePlusTabsHeight = ePlusTabs.clientHeight;
    this.detailsTabsHeight = ePlusTabsHeight - 50 - pageTitle.clientHeight;
  }

  async onSubscriptionUpdated(updatedSubscription: SubscriptionDTO) {
    // update the subscription in VueX
    this.updateSubscription(updatedSubscription);
    // refresh the view
    await this.fetchSubscription();
    this.eventBus.$emit("shouldReload");
  }

  onShouldRerenderGraphs() {
    // when updating the key of a component it force Vue to re-render it
    this.subscriptionGraphRef++;
    this.subscriptionGraphEplusRef++;
  }

  globalBusEvents: { [key: string]: (...params: any[]) => any } = {
    subscriptionUpdated: this.onSubscriptionUpdated,
    shouldRerenderGraphs: this.onShouldRerenderGraphs,
  };

  created() {
    for (let busEventsKey in this.globalBusEvents) {
      this.globalBus.$on(busEventsKey, this.globalBusEvents[busEventsKey]);
    }
  }

  loadComponent() {
    setTimeout(() => {
      this.subscription = null;
      this.$jfModal.closeAll();
      const queryParam = parseInt(this.$route.params.accountNumber) as SubscriptionDTO["accountNumber"];
      if (Number.isNaN(queryParam)) {
        this.notFound();
      } else {
        this.accountNumber = queryParam;
        this.fetchRegionsList();
      }
      this.fetchSubscription();
      this.currentSubscriptionResumeComponentKey++;
    }, 0);
  }

  mounted() {
    this.loadComponent();
    this.globalBus.$on("showUsageBreakdown", this.showUsageBreakdown);
  }

  showUsageBreakdown() {
    this.hasUsageBreakdown = true;
  }
  beforeDestroy() {
    for (let busEventsKey in this.globalBusEvents) {
      this.globalBus.$off(busEventsKey);
    }
    this.globalBus.$emit("relevantViewChangedForFeatureNotification", undefined);
  }
}
