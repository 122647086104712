
import { XrayTeaserMessageProps } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "XrayTeaserMessage",
  components: {},
})
export default class XrayTeaserMessage extends Vue {
  @Prop({ default: true })
  private inline!: XrayTeaserMessageProps["inline"];
  @Prop({ default: () => [] })
  private customClasses!: string[];
  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.inline) {
      classes.push("inline");
    }
    return classes.concat(this.customClasses);
  }
}
