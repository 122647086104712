
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription, UsageNotificationsOptions } from "@/types/localtypes";
import { JFCheckBox, JFFormControl, JFSwitchControl, JFTextField, JFRadioButtonGroup } from "jfrog-ui-vue-essentials";
import { isRestClientError, JpuNotification, UserDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "ModalSetNotification",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
    JFCheckBox,
    JFSwitchControl,
    LoadingMask,
    JFRadioButtonGroup,
  },
})
export default class ModalSetNotification extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop({ default: "" })
  private subscription!: FlatSubscription;
  @Getter("user", { namespace: "user" })
  user!: UserDTO | undefined;
  isLoading = true;
  isEnabled = false;
  isDailyEnabled = false;
  isNew = true;
  jpuNotification: JpuNotification = {
    dataTransferLimit: undefined,
    dataTransferLimitType: "GB",
    storageLimit: undefined,
    storageLimitType: "GB",
    pipelinesLimit: undefined,
    pipelinesLimitType: "MIN",
    sendUsageNotification: false,
    sendToService: false,
    sendToClient: false,
    reportPeriod: "",
    email: "",
    dailyEmail: "",
    sendDailyUsageNotification: false,
    dailyStorageLimit: undefined,
    dailyDataTransferLimit: undefined,
    dailyPipelinesLimit: undefined,
    peerToPeerLimit: undefined,
    dailyPeerToPeerLimit: undefined,
    peerToPeerLimitType: "GB",
    projectsLimit: undefined,
  };

  tooltip = "For multiple email addresses, use a comma separator";
  emailLabelIcon = {
    iconClass: "icon-info",
    tooltipText: this.tooltip,
  };
  dataTransferLabelIcon = this.isEnterprisePlus
    ? {
        iconClass: "icon-info",
        tooltipText: "Data transfer not including Peer-to-Peer data",
      }
    : null;

  usageOptions: Array<UsageNotificationsOptions> = [
    { label: "Daily", value: "1 DAYS" },
    { label: "Weekly", value: "7 DAYS" },
    { label: "Monthly", value: "1 MONTHS" },
  ];

  selected = "";

  @Watch("isEnabled")
  onChangeEnabled() {
    if (this.isEnabled) {
      this.jpuNotification.email = this.jpuNotification.email || (this.user && this.user.email) || "";
    }
  }

  @Watch("isDailyEnabled")
  onDailyEnabledChange() {
    if (this.isDailyEnabled) {
      this.jpuNotification.dailyEmail = this.jpuNotification.dailyEmail || (this.user && this.user.email) || "";
    }
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({ goal_key: "usage_notification", isSuccess: false, errorMessage: errorMessage });
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get hasPipelines() {
    return this.subscription && this.subscription.meta.hasPipelines && !this.subscription.meta.isPipelinesBlocked;
  }

  get isEnterprisePlus() {
    return this.subscription && this.subscription.meta.isEnterprisePlus;
  }
  get showPeer2Peer() {
    return false; //this feature was disabled according to product requirement, to enable return this.isEnterprisePlus;
  }
  get emailValidation() {
    return this.isDisabled ? "" : "required|store_email_list";
  }

  get dailyEmailValidation() {
    return this.isDailyDisabled ? "" : "required|store_email_list";
  }

  get labelClass() {
    return this.isDisabled ? "label-disabled" : "";
  }

  get isDisabled() {
    return !this.isEnabled || this.isTrialMode;
  }

  get isDailyDisabled() {
    return !this.isDailyEnabled || this.isTrialMode;
  }

  get isTrialMode() {
    return this.subscription.isTrial;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
    if (this.subscription.isTrial) {
      this.isLoading = false;
      return;
    }
    this.getNotifications();
  }

  async getNotifications() {
    try {
      const notifications = await this.$jfNotificationsService.getNotifications(
        this.subscription.accountNumber.toString(),
      );
      if (!notifications) {
        this.$log.error("Notifications are empty");
        this.notifyError(this.$jfMessages.subscriptions_set_notifications_error_unknown);
        this.$jfModal.close();
        return;
      }
      this.setJpuNotifications(notifications);
      this.selected = notifications.reportPeriod;
      this.isNew = false;
      this.isLoading = false;
    } catch (e) {
      this.$log.error(e);
      this.isLoading = false;
      if (isRestClientError(e) && e.httpStatus === 404) {
        this.jpuNotification.email = this.user ? this.user.email : "";
        this.jpuNotification.reportPeriod = "Weekly";
        return;
      }
      this.notifyError(this.$jfMessages.subscriptions_set_notifications_error_unknown);
    }
  }

  setJpuNotifications(notification: JpuNotification) {
    this.isEnabled = notification.sendUsageNotification;
    this.isDailyEnabled = notification.sendDailyUsageNotification;
    this.jpuNotification = {
      dataTransferLimit: notification.dataTransferLimit && this.$utils.byteToGigaByte(notification.dataTransferLimit),
      dataTransferLimitType: "GB",
      storageLimit: notification.storageLimit && this.$utils.byteToGigaByte(notification.storageLimit),
      storageLimitType: "GB",
      pipelinesLimit: notification.pipelinesLimit,
      pipelinesLimitType: "MIN",
      sendUsageNotification: notification.sendUsageNotification,
      sendToService: notification.sendToService,
      sendToClient: notification.sendToClient,
      reportPeriod: notification.reportPeriod || "7 DAYS",
      email: notification.email || "",
      dailyEmail: notification.dailyEmail || "",
      sendDailyUsageNotification: notification.sendDailyUsageNotification,
      dailyStorageLimit: notification.dailyStorageLimit && this.$utils.byteToGigaByte(notification.dailyStorageLimit),
      dailyDataTransferLimit:
        notification.dailyDataTransferLimit && this.$utils.byteToGigaByte(notification.dailyDataTransferLimit),
      dailyPipelinesLimit: notification.dailyPipelinesLimit,
      peerToPeerLimit: notification.peerToPeerLimit && this.$utils.byteToGigaByte(notification.peerToPeerLimit),
      dailyPeerToPeerLimit:
        notification.dailyPeerToPeerLimit && this.$utils.byteToGigaByte(notification.dailyPeerToPeerLimit),
      peerToPeerLimitType: "GB",
      projectsLimit: notification.projectsLimit,
    };
  }

  async confirm() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    try {
      this.isLoading = true;
      const jpuNotification = {
        dataTransferLimit: this.jpuNotification.dataTransferLimit || -1,
        dataTransferLimitType: "GB",
        storageLimit: this.jpuNotification.storageLimit || -1,
        storageLimitType: "GB",
        pipelinesLimit: this.jpuNotification.pipelinesLimit || -1,
        pipelinesLimitType: "MIN",
        sendUsageNotification: this.isEnabled,
        sendToService: this.jpuNotification.sendToService,
        sendToClient: this.isEnabled,
        reportPeriod: this.selected || "7 DAYS",
        email: this.jpuNotification.email,
        dailyEmail: this.jpuNotification.dailyEmail,
        dailyStorageLimit: this.jpuNotification.dailyStorageLimit,
        dailyDataTransferLimit: this.jpuNotification.dailyDataTransferLimit,
        dailyPipelinesLimit: this.jpuNotification.dailyPipelinesLimit,
        sendDailyUsageNotification: this.isDailyEnabled,
        peerToPeerLimit: this.jpuNotification.peerToPeerLimit,
        dailyPeerToPeerLimit: this.jpuNotification.dailyPeerToPeerLimit,
        peerToPeerLimitType: "GB",
        projectsLimit: this.jpuNotification.projectsLimit,
      };

      const accountNumber = this.subscription.accountNumber.toString();
      this.jpuNotification.sendUsageNotification = this.isNew
        ? await this.$jfNotificationsService.setNotifications(accountNumber, jpuNotification)
        : await this.$jfNotificationsService.updateNotifications(accountNumber, jpuNotification);

      this.$jfNotification.success({
        text: this.$jfMessages.subscriptions_set_notifications_success,
      });
      this.$jfWebeyez.send({ goal_key: "usage_notification", isSuccess: true });
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_set_notifications_error_confirm);
    } finally {
      this.isLoading = false;
      this.$jfModal.close();
    }
  }
}
