
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "EndpointBanner",
})
export default class EndpointBanner extends Vue {
  @Prop() private isEdit!: boolean;
  @Prop() private title!: string;
  @Prop() private link!: string;
  @Prop() private cloudProvider!: string;

  get innerText() {
    switch (this.cloudProvider) {
      case "azure":
      case "AZURE":
        return "<span >Creating an Azure PrivateLink involves several preliminary steps.</span>";
      case "amazon":
      case "AWS":
        return "<span> Creating an AWS private endpoints involves several preliminary steps.</span>";
      case "google":
      case "GCP":
        return "<span> Creating an GCP private endpoints involves several preliminary steps.</span>";
    }
  }
}
