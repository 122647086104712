
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { JFGrid } from "jfrog-ui-vue-essentials";
import {
  EndpointGridProps,
  EndpointLabel,
  FlatSubscription,
  GridEndpoint,
  PrivateEndpointModalMenuStatus,
} from "@/types/localtypes";
import CreateNewButton from "@/components/views/subscriptions/modals/privateLink/CreateNewButton.vue";
import {
  Endpoint,
  JpuDTO,
  ManageEndpointRequest,
  PrivateLinkResponse,
  ReadServerIPMetaResponse,
} from "@jfrog-ba/myjfrog-common";
import { JFEssentialsGridPopupMenuOptionsGRID } from "@/types/3rdPartyLibs";
import DeleteModal from "@/components/views/subscriptions/modals/privateLink/components/DeleteModal.vue";
import ReplaceEndpoint from "@/components/views/subscriptions/modals/privateLink/components/ReplaceEndpoint.vue";

const privateEndpointStatus: PrivateEndpointModalMenuStatus = {
  InProgress: { label: "In Progress", statuses: ["add", "remove"] },
  Removing: { label: "Removing Endpoint", statuses: ["reject"] },
  Failed: { label: "Failed", statuses: ["add_error", "remove_error", "reject_error"] },
  Connected: { label: "Connected", statuses: ["connected"] },
  Blocked: { label: "Blocked", statuses: ["blocked"] },
  Removed: { label: "Removed", statuses: ["removed"] },
};

interface ServerIpMetaDataGrid {
  [key: string]: ReadServerIPMetaResponse | null;
}

@Component({
  name: "LinkMenu",
  components: {
    CreateNewButton,
    JFGrid,
  },
})
export default class LinkMenu extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private subscription!: FlatSubscription;
  @Prop() private redirectToAddAnotherPrivateLink!: () => undefined;
  @Prop() private redirectToEditPrivateLink!: (cloudProvider: string) => any;
  @Prop() private redirectToFirstStep!: (endpoints: GridEndpoint[]) => any;
  @Prop() private gotoInProgress!: () => undefined;
  @Prop() private updateModalSize!: (size: string) => undefined;
  @Prop() private eventBus!: Vue;
  @Prop() private setLoading!: (isLoading: boolean) => undefined;
  serverIpMetaData: ServerIpMetaDataGrid = {};
  gridEventBus = new Vue();
  intervalId = 0;

  get filteredJPUStatus(): JpuDTO["status"][] {
    return ["DELETED", "HISTORY", "MOVED"];
  }

  get isEnterprisePlus() {
    return this.subscription.meta.isEnterprisePlus;
  }

  @Watch("endpoints")
  onServerIpMetaDataChange() {
    this.reRenderGrid();
  }

  reRenderGrid() {
    if (this.endpoints && this.endpoints.length === 0) {
      this.redirectToFirstStep(this.endpoints);
      return;
    }
    setTimeout(() => this.gridEventBus.$emit("reloadData"), 0);
  }

  calculateStatus(endpointStatus: Endpoint["status"][], endpointId: string) {
    if (privateEndpointStatus.Failed.statuses.some(status => endpointStatus.includes(status))) {
      this.$jfWebeyez.send({
        goal_key: "aws_private_link",
        isSuccess: false,
        errorMessage: "Failed to create private link for EndPoint ID: " + endpointId,
      });
      return privateEndpointStatus.Failed.label;
    }

    if (privateEndpointStatus.Removing.statuses.some(status => endpointStatus.includes(status))) {
      return privateEndpointStatus.Removing.label;
    }

    if (privateEndpointStatus.InProgress.statuses.some(status => endpointStatus.includes(status))) {
      return privateEndpointStatus.InProgress.label;
    }

    if (privateEndpointStatus.Connected.statuses.some(status => endpointStatus.includes(status))) {
      this.$jfWebeyez.send({ goal_key: "aws_private_link", isSuccess: true });
      return privateEndpointStatus.Connected.label;
    }

    if (privateEndpointStatus.Blocked.statuses.every(status => endpointStatus.includes(status))) {
      return privateEndpointStatus.Blocked.label;
    }

    if (privateEndpointStatus.Removed.statuses.some(status => endpointStatus.includes(status))) {
      return privateEndpointStatus.Removed.label;
    }

    // default status if there is a problem
    return privateEndpointStatus.Failed.label;
  }

  get isMenuEnabled() {
    return this.endpoints.every(
      endpoint =>
        endpoint.status === "Connected" ||
        (endpoint.status === "Failed" && !endpoint.configStatus.includes("add_error")),
    );
  }

  isReplaceMenuEnabled(endpoint: GridEndpoint) {
    return endpoint.status === "Failed" && endpoint.configStatus.includes("add_error");
  }

  get endpoints(): GridEndpoint[] {
    const payload = this.serverIpMetaData.payload;

    if (!this.subscription || !payload) {
      return [];
    }

    const gridEndpoints: GridEndpoint[] = [];
    const endpointStatusesPerId: { [key: string]: Endpoint["status"][] } = {};

    Object.keys(payload).forEach(serverName => {
      const serverPrivateLinkMeta = payload[serverName].privateLinkMeta as PrivateLinkResponse;
      if (!serverPrivateLinkMeta) {
        return;
      }

      serverPrivateLinkMeta.endpoints.forEach(endpoint => {
        const currentEndpoint = gridEndpoints.find(gridEndpoint => gridEndpoint.id === endpoint.id);

        if (privateEndpointStatus.Removed.statuses.some(status => endpoint.status.includes(status))) {
          // We don't need to include endpoint with status Removed
          return;
        }

        if (currentEndpoint) {
          currentEndpoint.serverNames.push(serverName);
          // add to existing status.
          endpointStatusesPerId[endpoint.id].push(endpoint.status);
          return;
        }

        const jpu = this.subscription.jpus.find(jpu => jpu.serverName === serverName);

        // set array of endpoint statuses per server.
        endpointStatusesPerId[endpoint.id] = [];
        endpointStatusesPerId[endpoint.id].push(endpoint.status);

        gridEndpoints.push({
          id: endpoint.id,
          cloudProvider: jpu ? jpu.cloudProvider : "N/A",
          serverNames: [serverName],
          status: "Create",
          region: jpu ? jpu.region : "N/A",
          configStatus: endpointStatusesPerId[endpoint.id],
        });
      });
    });

    return gridEndpoints.map(currentEndpoint => ({
      ...currentEndpoint,
      status: this.calculateStatus(endpointStatusesPerId[currentEndpoint.id], currentEndpoint.id),
    }));
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  async fetchServersIpMetaData() {
    if (!this.subscription.jpus) {
      return;
    }
    try {
      this.setLoading(true);
      const data = await this.$jfSubscriptions.getServersIpMetaData(this.subscription.accountNumber, {
        technicalServerNames: this.subscription.jpus
          .filter(jpu => !this.filteredJPUStatus.includes(jpu.status))
          .map(jpu => jpu.serverName),
      });
      this.$set(this.serverIpMetaData, "payload", data);
      setTimeout(() => this.setLoading(false), 500);
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_private_link_meta_data_fetch_error);
      this.$set(this.serverIpMetaData, "payload", {});
      this.setLoading(false);
    }
  }

  serverNames(serverNames: string[]) {
    const serverNamesStr = serverNames.join(" , ");
    if (serverNamesStr.length < 43 || serverNames.length === 1) {
      return serverNamesStr;
    }

    let firstServerName = serverNames[0];

    if (firstServerName.length > 43) {
      firstServerName = firstServerName.substring(0, 40) + "...";
    }
    return `${firstServerName} <span bold>+${serverNames.length - 1}</span>`;
  }

  gridProps: EndpointGridProps = {
    eventBus: this.gridEventBus,
    theme: "light",
    displayToolbar: false,
    sortConfig: "endpointId",
    columns: [
      {
        label: "Connection ID",
        field: "id",
        tooltip: false,
        sortable: true,
      },
      {
        label: "Provider",
        field: "cloudProvider",
        tooltip: false,
        sortable: true,
        getHtml: this.renderCloudProvider,
        flexGrow: 0.5,
      },
      {
        label: "Region",
        field: "region",
        tooltip: false,
        sortable: true,
        flexGrow: 0.5,
      },
      {
        label: "Status",
        field: "status",
        tooltip: true,
        sortable: true,
        flexGrow: 0.8,
        getHtml: this.renderStatus,
        cellCssCalculatedData: [row => (row ? this.getServerStatusSubLabelClasses(row.status) : "ERROR")],
        tooltipConverter: (status: EndpointLabel) => {
          let currentEndpoint = this.endpoints.filter(
            endpoint => endpoint.status.startsWith("Failed") && endpoint.configStatus.includes("add_error"),
          );
          if (currentEndpoint.length) {
            return `The Endpoint ID you entered is incorrect.</br> Re-enter the ID using the Fix Endpoint option on the right`;
          }
          if (status.startsWith("Failed")) {
            return `An error has occurred while configuring your AWS PrivateLink.</br> Please contact support@jfrog.com`;
          }
          return "";
        },
      },
      {
        label: "Instances",
        field: "serverNames",
        tooltip: true,
        sortable: true,
        flexGrow: 1.2,
        converter: (serverNames: string[]) => this.serverNames(serverNames),
        tooltipConverter: (serverNames: string[]) => serverNames.join(", "),
      },
      {
        label: "",
        field: "actions",
        tooltip: false,
        sortable: false,
        selectFilter: "off",
        flexGrow: 0.1,
        popupMenuConfig: row => this.definePopupMenuConfigs(row),
      },
    ],
    state: {
      page: 1,
      orderBy: "id",
      order: "asc",
    },
    config: {
      fetchDataPromise: () => {
        return Promise.resolve({
          data: this.endpoints,
          total: this.endpoints ? this.endpoints.length : 0,
        });
      },
      displayColumnSelectFilters: false,
      noPagination: true,
      mode: "client",
    },
  };

  getServerStatusSubLabelClasses(status: EndpointLabel) {
    if (privateEndpointStatus.Failed.label === status) {
      return ["danger"];
    }
    if (privateEndpointStatus.InProgress.label === status || privateEndpointStatus.Removing.label === status) {
      return ["jf-yellow"];
    }
    if (privateEndpointStatus.Connected.label === status) {
      return ["mjf-green"];
    }
    if (privateEndpointStatus.Blocked.label === status) {
      return ["mjf-green"];
    }
    return ["danger"];
  }

  definePopupMenuConfigs(row: GridEndpoint) {
    const menuConfig: JFEssentialsGridPopupMenuOptionsGRID = {
      disabled: false,
      placement: "auto",
      theme: "web",
      title: "",
      displayTriggerText: true,
      trigger: "click",
      iconClasses: [],
      titlePlacement: "left",
      iconName: "icon-mjf-pipelines-more",
      items: [
        {
          label: "Delete",
          showCondition: () => this.isMenuEnabled,
          click: () => this.deleteEndpoint(row),
          icon: {
            iconName: "icon-trashcan",
            iconClasses: ["mjf-green"],
            iconStyles: ["font-size:14px"],
          },
        },
        {
          label: "Fix Endpoint",
          showCondition: () => this.isReplaceMenuEnabled(row),
          click: () => this.onReplaceEndpoint(row),
          icon: {
            iconName: "icon-set-me-up",
            iconClasses: ["mjf-green"],
            iconStyles: ["font-size:14px"],
          },
        },
      ],
    };
    if (this.isEnterprisePlus) {
      menuConfig.items.unshift({
        label: "Edit",
        showCondition: () => this.isMenuEnabled,
        click: () => this.onEditEndpoint(row),
        icon: {
          iconName: "icon-artifactory-edit",
          iconClasses: ["mjf-green"],
          iconStyles: ["font-size:14px"],
        },
      });
    }
    return menuConfig;
  }

  renderStatus(status: string): string {
    if (!status) {
      return "";
    }
    if (status === "Failed") {
      return status + ` <i class="icon-info" />`;
    }
    return status;
  }

  renderCloudProvider(cloudProvider: string) {
    switch (cloudProvider) {
      case "amazon":
        return `<span fx fxACenter fxCenter><img width="20" src="${this.getProviderImage(
          cloudProvider,
        )}" /> <span ml-2>AWS</span></span>`;
      case "azure":
        return `<span fx fxACenter fxCenter><img width="20" src="${this.getProviderImage(
          cloudProvider,
        )}" /> <span ml-2>Azure</span></span>`;
      case "google":
        return `<span fx fxACenter fxCenter><img width="20" src="${this.getProviderImage(
          cloudProvider,
        )}" /> <span ml-2>GCP</span></span>`;
      default:
        return "";
    }
  }

  getProviderImage(providerCode: FlatSubscription["cloudProviderCode"]) {
    return this.$jfImages.get(this.$jfImages.getProviderLogoFileName(providerCode));
  }

  onEditEndpoint(row: GridEndpoint) {
    this.redirectToEditPrivateLink(row.cloudProvider);
    // update manage endpoint fields.
    setTimeout(() => this.eventBus.$emit("onEditEndpoint", row), 0);
  }

  mounted() {
    this.fetchServersIpMetaData();
    this.retryFetch();
  }

  retryFetch() {
    const fetchInterval = 1000 * 60;
    this.intervalId = setInterval(this.fetchServersIpMetaData, fetchInterval);
  }

  destroyed() {
    clearInterval(this.intervalId);
  }

  deleteEndpoint(row: GridEndpoint) {
    this.$jfModal.showCustom(
      DeleteModal,
      "md+",
      {
        refreshMenu: () => {
          this.fetchServersIpMetaData();
        },
        region: row.region,
        manageEndpointRequest: this.buildManageEndpointRequest(row),
        subscription: this.subscription,
        setLoading: (isLoading: boolean) => this.setLoading(isLoading),
      },
      {
        title: "DELETE PRIVATE CONNECTION",
        forceDisplayCancelIcon: false,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  onReplaceEndpoint(row: GridEndpoint) {
    this.$jfModal.showCustom(
      ReplaceEndpoint,
      "md+",
      {
        subscription: this.subscription,
        eventBus: this.eventBus,
        setLoading: (isLoading: boolean) => this.setLoading(isLoading),
        gotoInProgress: () => {
          this.gotoInProgress();
        },
        currentCloudProvider: row.cloudProvider,
      },
      {
        title: "REPLACE PRIVATE CONNECTION",
        forceDisplayCancelIcon: false,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
    setTimeout(() => this.eventBus.$emit("onReplaceEndpoint", row), 0);
  }
  buildManageEndpointRequest(row: GridEndpoint): ManageEndpointRequest {
    switch (row.cloudProvider) {
      case "azure":
      case "AZURE":
        return this.azureManageEndpointRequest(row) as ManageEndpointRequest;
      case "amazon":
      case "AWS":
        return this.amazonManageEndpointRequest(row) as ManageEndpointRequest;
      default:
        return this.amazonManageEndpointRequest(row) as ManageEndpointRequest;
    }
  }
  amazonManageEndpointRequest(row: GridEndpoint) {
    return {
      id: row.id,
      serversToRemove: row.serverNames,
      action: "DELETE",
      serversToAdd: [],
      cloudProviderCode: row.cloudProvider,
    };
  }

  azureManageEndpointRequest(row: GridEndpoint) {
    const splitEndpoint = row.id.split(".");
    const endpointName = splitEndpoint[0];
    const endpointId = splitEndpoint[1];
    return {
      id: endpointId,
      serversToRemove: row.serverNames,
      action: "DELETE",
      serversToAdd: [],
      cloudProviderCode: row.cloudProvider,
      endpointName: endpointName,
    };
  }
}
