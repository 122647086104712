
declare var ENABLE_GCP_PRIVATE_LINK: any;
import { Component, Prop, Vue } from "vue-property-decorator";
import CreateNewButton from "@/components/views/subscriptions/modals/privateLink/CreateNewButton.vue";
import { FlatSubscription, GridEndpoint } from "@/types/localtypes";
import { JpuDTO } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "FirstSetup",
  components: { CreateNewButton },
})
export default class FirstSetup extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Prop() private redirectToCreatePrivateLink!: (cloudProvider: JpuDTO["cloudProvider"]) => any;
  @Prop() private endpoints!: GridEndpoint[];

  get cloudProviders() {
    const cloudProvidersList = this.subscription.jpus.map(jpu => jpu.cloudProvider);
    if (ENABLE_GCP_PRIVATE_LINK === "true") {
      return [...new Set(cloudProvidersList)];
    } else {
      return [...new Set(cloudProvidersList)].filter(cloudProvider => cloudProvider != "google");
    }
  }

  getProviderImage(providerCode: FlatSubscription["cloudProviderCode"]) {
    return this.$jfImages.get(this.$jfImages.getProviderLogoFileName(providerCode));
  }

  getCloudProviderShortHand(providerCode: FlatSubscription["cloudProviderCode"]) {
    switch (providerCode) {
      case "amazon":
        return "AWS";
      case "azure":
        return "Azure";
      case "google":
        return "GCP";
    }
  }

  getPrivateLinkText(providerCode: FlatSubscription["cloudProviderCode"]) {
    switch (providerCode) {
      case "amazon":
      case "azure":
        return "Private Link";
      case "google":
        return "Private Service Connect";
    }
  }
}
