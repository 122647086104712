
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Tab from "@/components/common/Tab.vue";
import Tabs from "@/components/common/Tabs.vue";
import LoadingMask from "@/components/common/LoadingMask.vue";
import SubscriptionGraphs from "@/components/views/subscriptions/subscriptionGraphs/SubscriptionGraphs.vue";
import { TabProps } from "@/types/tabs";
import { FlatSubscription } from "@/types/localtypes";
import UsageBreakDown from "@/components/views/subscriptions/subscriptionGraphs/UsageBreakDown.vue";
import { initial } from "lodash";

type TabId = "_overview" | "_monthlyBreakdown";

@Component({
  name: "UsageTabs",
  components: {
    Tab,
    Tabs,
    LoadingMask,
    SubscriptionGraphs,
    UsageBreakDown,
  },
})
export default class UsageTabs extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Prop() private serverName!: string;
  @Prop() private tabsTheme!: string;
  @Prop() private isEnterprisePlus!: boolean;
  @Prop() private isParentTab!: boolean;
  @Inject() readonly globalBus!: Vue;

  initialActiveTabId: TabId = "_overview";
  currentTabId: TabId = "_overview";
  subscriptionGraphEplusRef = 0;
  detailsTabsHeight = 0;
  forceLoading = false;
  loading = false;
  hasUsageBreakdown = false;

  globalBusEvents: { [key: string]: (...params: any[]) => any } = {
    shouldRerenderGraphs: this.onShouldRerenderGraphs,
  };

  mounted() {
    for (let busEventsKey in this.globalBusEvents) {
      this.globalBus.$on(busEventsKey, this.globalBusEvents[busEventsKey]);
    }
    this.globalBus.$on("showUsageBreakdown", this.showUsageBreakdown);
    this.globalBus.$on("loading", this.isLoading);

    const viewContentWrapper = document.getElementsByClassName("view-content-wrapper")[0] as HTMLDivElement;
    viewContentWrapper.style.overflowY = "hidden";
  }

  isLoading(loading: boolean) {
    this.loading = loading;
  }

  get OverviewTitle() {
    return this.hasUsageBreakdown || (this.isEnterprisePlus && !this.hasUsageBreakdown) ? "Overview" : "Usage";
  }
  get showUsageBreakdownDisclaimer() {
    return this.currentTabId === "_monthlyBreakdown" && this.hasUsageBreakdown;
  }
  showUsageBreakdown() {
    this.hasUsageBreakdown = true;
    this.calcTabHeight();
  }
  onShouldRerenderGraphs() {
    // when updating the key of a component it force Vue to re-render it
    this.subscriptionGraphEplusRef++;
  }
  calcTabHeight() {
    const tabElAll = document.querySelectorAll(".tabs-header-wrapper");
    if (!tabElAll) return;
    const tabEl = tabElAll[tabElAll.length - 1];
    const bottom = tabEl.getBoundingClientRect().bottom;
    this.detailsTabsHeight = window.innerHeight - bottom - 80;
  }
  onTabChange(tab: TabProps) {
    this.currentTabId = tab.id as TabId;
    if ((tab.id as TabId) === "_overview") {
      this.forceLoading = true;
      setTimeout(() => (this.forceLoading = false), 100);
    }
    this.globalBus.$emit("relevantViewChangedForFeatureNotification", tab.id, this.subscription);
    setTimeout(() => {
      this.calcTabHeight();
    }, 0);
  }

  get wrapperClass() {
    if (this.hasUsageBreakdown) {
      return "usage-breakdown-wrapper";
    }
    return "";
  }

  get tabsClass() {
    if (this.isParentTab) {
      return "usage-tabs parent";
    }
    return "usage-tabs nested";
  }

  get tabStyles() {
    return {
      borderBottom: "0",
    };
  }

  get tabHeaderStyles() {
    let style = {
      width: "100%",
      background: "initial",
      borderBottom: "0",
    };
    if (this.isParentTab && !this.hasUsageBreakdown) {
      return style;
    }
    if (this.tabsTheme !== "light") {
      style.background = "none";
      style.borderBottom = "1px solid #ddd";
    }
    return style;
  }
  get tabContentWrapperStyles() {
    return {
      height: this.detailsTabsHeight + "px",
      background: "white",
    };
  }

  beforeDestroy() {
    for (let busEventsKey in this.globalBusEvents) {
      this.globalBus.$off(busEventsKey);
    }
    this.globalBus.$emit("relevantViewChangedForFeatureNotification", undefined);
  }
}
