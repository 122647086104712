
import BarChart from "@/components/charts/BarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import LoadingMask from "@/components/common/LoadingMask.vue";
import JFMultiSelect from "@/components/common/multiSelect/JFMultiSelect.vue";
import MultiSelectHeader from "@/components/common/multiSelect/parts/MultiSelectHeader.vue";
import VMultiSelect from "@/components/common/multiSelect/parts/VMultiSelect.vue";
import GraphHelpers from "@/components/views/subscriptions/subscriptionGraphs/GraphHelpers";
import DTGraphFilter from "@/components/views/subscriptions/subscriptionGraphs/graphItem/legendFilters/DTGraphFilter.vue";
import XrayTeaserMessage from "@/components/views/subscriptions/subscriptionGraphs/graphItem/legendMessages/XrayTeaserMessage.vue";
import SubscriptionGraphItem from "@/components/views/subscriptions/subscriptionGraphs/graphItem/SubscriptionGraphItem.vue";
import {
  ChartData,
  DTGraphFilterProps,
  FlatSubscription,
  SubscriptionGraphItemProps,
  SubscriptionGraphItemPropsBase,
  XrayTeaserMessageProps,
} from "@/types/localtypes";
import { JFMultiSelectProps, MultiSelectOption } from "@/types/localtypes/multiSelect";
import { SpinnerProperties } from "@/types/spinners";
import { JFCheckBox, JFDateField, JFFormControl } from "jfrog-ui-vue-essentials";
import moment from "moment";
import { MultipleStatisticsResponse, ServerStatisticsDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";

interface GraphItemsDisplayConfig {
  storage: boolean;
  dt: boolean;
  pl: boolean;
  cdn: boolean;
}

interface GraphItemsConfig {
  storage: GraphItemConfig;
  dt: GraphItemConfig;
  pl: GraphItemConfig;
  cdn: GraphItemConfig;
}

interface GraphItemConfig {
  displayedSum: string;
  displayedSumUnit: string;
  chartData: ChartData;
}

@Component({
  name: "SubscriptionGraphs",
  components: {
    MultiSelectHeader,
    LineChart,
    BarChart,
    JFDateField,
    JFFormControl,
    LoadingMask,
    JFCheckBox,
    VMultiSelect,
    SubscriptionGraphItem,
    JFMultiSelect,
  },
})
export default class SubscriptionGraphs extends Vue {
  @Prop({ default: "" })
  private serverName!: string;
  @Prop() private subscription!: FlatSubscription;
  @Provide() graphEventBus = new Vue();

  @Watch("loading")
  onSubscriptionsChanged() {
    if (this.isEnterprisePlus && !this.loading) {
      this.$emit("subscriptionLoaded");
    }
  }

  loading = true;
  isError = false;
  spinnerConfig: SpinnerProperties = {
    size: "l",
    color: "#373737",
  };
  startTime = 0;
  endTime = new Date().valueOf();
  period = "daily";

  rawStatisticsData: MultipleStatisticsResponse | null = null;
  displayArtifactoryDT = true;
  displayXrayDT = true;
  artifactoryDataSet: { [key: string]: any } = {};
  xrayDataSet: { [key: string]: any } = {};
  dataTransferInBytesXray = 0;
  dataTransferInBytesArtifactory = 0;
  sumPipelinesInMinutes = 0;
  isXrayNewForCustomer = false;

  serverOptions: MultiSelectOption[] = this.serverOptionsFiltered;
  selectedServers: string[] = [];
  selectedRegions: string[] = [];

  dtGraphItemCustomProps = {
    chartGradients: [
      {
        datasetId: "Artifactory",
        gradient: {
          x0: 0,
          y0: 0,
          x1: 0,
          y1: 200,
          colorStops: [
            { offset: 0, color: "rgba(120,50,199,0.4)" },
            { offset: 0.8, color: "rgba(120,50,199,0.1)" },
            { offset: 1, color: "rgba(120,50,199,0)" },
          ],
        },
      },
      {
        datasetId: "Xray",
        gradient: {
          x0: 0,
          y0: 0,
          x1: 0,
          y1: 200,
          colorStops: [
            { offset: 0, color: "rgba(156,136,179,0.9)" },
            { offset: 0.4, color: "rgba(156,136,179,0.8)" },
            { offset: 0.6, color: "rgba(156,136,179,0.6)" },
            { offset: 1, color: "rgba(156,136,179,0.2)" },
          ],
        },
      },
    ],
  };
  graphComponentHeight = 240;
  isCdnDataEmpty = true;
  // config for initial graph state.
  get graphItemsDisplayConfig(): GraphItemsDisplayConfig {
    return {
      storage: true,
      dt: true,
      pl: this.hasPipelines,
      cdn: this.hasCdnEnabled,
    };
  }
  graphItemsConfig: GraphItemsConfig = {
    storage: {
      displayedSum: "0",
      displayedSumUnit: "Bytes",
      chartData: {
        labels: [],
        datasets: [],
      },
    },
    dt: {
      displayedSum: "0",
      displayedSumUnit: "Bytes",
      chartData: {
        labels: [],
        datasets: [],
      },
    },
    pl: {
      displayedSum: "0",
      displayedSumUnit: "Min",
      chartData: {
        labels: [],
        datasets: [],
      },
    },
    cdn: {
      displayedSum: "0",
      displayedSumUnit: "Bytes",
      chartData: {
        labels: [],
        datasets: [],
      },
    },
  };

  get regionsMultiSelectProps(): JFMultiSelectProps {
    return {
      selectLabel: "Show By Region:",
      headerProps: {
        selectAllActive: this.regionSelectAllActive,
        deSelectAllActive: this.regionDeSelectAllActive,
      },
      multiSelectProps: {
        maxOptionsDisplayed: 1,
        selectName: "regions",
        selectOptions: this.regionsOptions,
        extensionCallback: () => "",
      },
    };
  }

  get serversMultiSelectProps(): JFMultiSelectProps {
    return {
      selectLabel: "Show By Instance:",
      headerProps: {
        selectAllActive: this.serverSelectAllActive,
        deSelectAllActive: this.serverDeSelectAllActive,
      },
      multiSelectProps: {
        maxOptionsDisplayed: 1,
        selectName: "servers",
        selectOptions: this.serverOptions,
        extensionCallback: this.calcMultiSelectServerItemExtension,
      },
    };
  }

  get nbGraphsDisplayed() {
    return Object.values(this.graphItemsDisplayConfig).filter(displayed => displayed).length;
  }

  get graphLegendDisplayMode(): SubscriptionGraphItemProps["legendDisplayMode"] {
    return this.nbGraphsDisplayed < 3 || this.isMobile || this.isTablet || this.isLaptop ? "inline" : "column";
  }

  // add margin for 4+ graphs.
  get graphMargin() {
    return this.nbGraphsDisplayed > 3 && !this.isMobile && !this.isTablet && !this.isLaptop ? "0 0 40px 0" : "";
  }

  get baseGraphItem(): SubscriptionGraphItemPropsBase {
    return {
      loading: this.loading,
      inError: this.isError,
      graphComponentHeight: this.graphComponentHeight,
      legendDisplayMode: this.graphLegendDisplayMode,
    };
  }

  get storageGraphItem(): SubscriptionGraphItemProps {
    return {
      ...this.baseGraphItem,
      title: "Data Storage",
      subTitle: "High-Water Mark",
      displayedSum: this.graphItemsConfig.storage.displayedSum,
      displayedSumUnit: this.graphItemsConfig.storage.displayedSumUnit,
      graphType: "Bar",
      graphMargin: this.graphMargin,
      graphData: this.graphItemsConfig.storage.chartData,
      graphCustomProps: {},
      graphYaxisCustomCallback: (tick: any): any => {
        return tick.toString() + " GB";
      },
    };
  }

  get dtGraphItem(): SubscriptionGraphItemProps {
    // IMPORTANT : the 'graphData' property should be set BEFORE the 'displayedSum' and 'displayedSumUnit' properties.
    // Because the 'dataTransferChartDataFiltered' function make some updates to the values :
    // -> 'this.graphItemsConfig.dt.displayedSum'
    // -> and 'this.graphItemsConfig.dt.displayedSumUnit'
    return {
      ...this.baseGraphItem,
      title: "Data Transfer",
      subTitle: "Total",
      graphData: this.dataTransferChartDataFiltered,
      displayedSum: this.graphItemsConfig.dt.displayedSum,
      displayedSumUnit: this.graphItemsConfig.dt.displayedSumUnit,
      graphMargin: this.graphMargin,
      graphType: "Line",
      graphCustomProps: this.dtGraphItemCustomProps,
      legendFilter: this.dtLegendFilter,
      legendMessage: this.dtLegendMessage,
      graphYaxisCustomCallback: (tick: any): any => {
        return tick.toString() + " GB";
      },
    };
  }

  get pipelineGraphItem(): SubscriptionGraphItemProps {
    return {
      ...this.baseGraphItem,
      title: "Build Time",
      subTitle: "Total",
      graphData: this.graphItemsConfig.pl.chartData,
      displayedSum: this.graphItemsConfig.pl.displayedSum,
      displayedSumUnit: this.graphItemsConfig.pl.displayedSumUnit,
      graphType: "Line",
      graphCustomProps: {},
      graphYaxisCustomCallback: (tick: any): any => {
        return tick.toString() + " Min";
      },
    };
  }

  get cdnGraphItem(): SubscriptionGraphItemProps {
    return {
      ...this.baseGraphItem,
      title: "Data Transfer via CDN",
      subTitle: "Total",
      graphData: this.graphItemsConfig.cdn.chartData,
      displayedSum: this.graphItemsConfig.cdn.displayedSum,
      displayedSumUnit: this.graphItemsConfig.cdn.displayedSumUnit,
      graphType: "Line",
      graphCustomProps: {},
      graphYaxisCustomCallback: (tick: any): any => {
        return tick.toString() + " GB";
      },
    };
  }

  get dtLegendMessage(): SubscriptionGraphItemProps["legendFilter"] {
    return {
      component: XrayTeaserMessage,
      display: this.isSecurityReachable && !this.isMobile,
      // display: !this.isMobile,
      props: {
        inline: this.graphLegendDisplayMode === "inline",
      } as XrayTeaserMessageProps,
    };
  }

  get dtLegendFilter(): SubscriptionGraphItemProps["legendMessage"] {
    return {
      component: DTGraphFilter,
      display: !this.subscription.meta.isJCR,
      props: {
        inline: this.graphLegendDisplayMode === "inline",
        displayLabel: !this.isMobile,
        disableXray: !this.subscription.meta.hasXray,
        artifactoryCbChecked: true,
        xrayCbChecked: this.subscription.meta.hasXray,
      } as DTGraphFilterProps,
    };
  }

  get graphItems(): SubscriptionGraphItemProps[] {
    const items = [];
    items.push(this.storageGraphItem);
    items.push(this.dtGraphItem);
    if (this.hasPipelines) {
      items.push(this.pipelineGraphItem);
    }
    if (this.hasCdnEnabled) {
      items.push(this.cdnGraphItem);
    }
    return items;
  }

  get graphWrapperClasses() {
    const classes: string[] = [];
    if (this.nbGraphsDisplayed <= 3) {
      classes.push(`col-lg-${12 / this.nbGraphsDisplayed}`);
    } else {
      classes.push("col-lg-6");
    }
    return classes;
  }

  get isEnterprisePlus() {
    return this.subscription.meta.isEnterprisePlus || this.subscription.meta.isEnterprisePlusTrial;
  }

  get isSecurityReachable() {
    return this.isXrayNewForCustomer && this.$jfSubscriptions.isAddXrayPageReachable(this.subscription.meta);
  }

  get hasPipelines() {
    return this.subscription && !!this.subscription.meta.hasPipelines && !this.subscription.meta.isPipelinesBlocked;
  }

  get hasCdnEnabled() {
    return this.subscription && !!this.subscription.meta.hasCdnEnabled;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }

  get isLaptop() {
    return this.$mq === "laptop";
  }

  calcMultiSelectServerItemExtension(serverName: string) {
    if (!this.subscription.jpus) {
      return "";
    }
    const subscription = this.subscription.jpus.find(jpu => jpu.serverName === serverName);
    if (!subscription) {
      return "";
    }

    return subscription.products.filter(product => product.productName.includes("artifactory")).length === 1
      ? ""
      : " (Edge)";
  }

  get serverSelectAllActive() {
    return this.selectedServers.length > 0 && this.selectedServers.length !== this.serverOptions.length;
  }

  get serverDeSelectAllActive() {
    return this.selectedServers.length !== 0;
  }

  get regionSelectAllActive() {
    return this.selectedRegions.length > 0 && this.selectedRegions.length !== this.regionsOptions.length;
  }

  get regionDeSelectAllActive() {
    return this.selectedRegions.length !== 0;
  }

  onServersSelected(serverOptionSelected: MultiSelectOption[]) {
    this.selectedServers = serverOptionSelected.map(jpu => jpu.label);
    setTimeout(() => {
      this.requestStatistics();
    }, 0);
  }

  onRegionsSelected(regionOptionSelected: MultiSelectOption[]) {
    this.selectedRegions = regionOptionSelected.map(region => region.label);

    setTimeout(() => {
      this.parseResponse();
    }, 0);
  }

  get showMultiSelect() {
    return this.isEnterprisePlus;
  }

  get serverOptionsFiltered(): MultiSelectOption[] {
    return this.subscription && this.subscription.jpus && this.subscription.jpus.length > 0
      ? this.subscription.jpus.map(jpu => ({ code: jpu.serverName, label: jpu.serverName }))
      : [];
  }

  get regionsOptions(): MultiSelectOption[] {
    const regionsSet: Set<string> = new Set();
    if (!this.subscription.jpus) {
      return [];
    }
    this.subscription.jpus.forEach(jpu => {
      if (jpu.region) {
        regionsSet.add(jpu.region);
      }
    });

    return Array.from(regionsSet).map(region => ({ label: region, code: region }));
  }

  get graphDisclaimer() {
    const disclaimers: string[] = [];

    disclaimers.push(this.$jfMessages.subscription_usage_graph_disclaimer);

    if (this.hasCdnEnabled) {
      disclaimers.push(this.$jfMessages.subscription_cdn_graph_disclaimer);
    }

    // adding the stars prefix
    return disclaimers
      .map((disclaimer, index) => {
        return `${new Array(index + 1).fill("*").join("")} ` + disclaimer;
      })
      .join(`<br/>`);
  }

  get dataTransferChartDataFiltered() {
    const dataTransferChart = this.graphItemsConfig.dt.chartData;
    let dataTransfer = 0;
    dataTransferChart.datasets = [];
    if (this.artifactoryDataSet.id && this.displayArtifactoryDT) {
      dataTransfer += this.$utils.byteToGigaByte(this.dataTransferInBytesArtifactory);
      dataTransferChart.datasets.push(this.artifactoryDataSet);
    }
    if (this.xrayDataSet.id && this.displayXrayDT) {
      dataTransfer += this.$utils.byteToGigaByte(this.dataTransferInBytesXray);
      dataTransferChart.datasets.push(this.xrayDataSet);
    }
    this.graphItemsConfig.dt.displayedSum = dataTransfer === 0 ? "0" : dataTransfer.toFixed(2).toString();
    this.graphItemsConfig.dt.displayedSumUnit = "GB";
    return dataTransferChart;
  }

  get calcStartTime() {
    const date = this.$dateUtils.minus(new Date(), 6, "months").valueOf();
    const number = date > this.creationDate ? date : this.creationDate;
    return moment(number)
      .utc()
      .startOf("day")
      .toDate();
  }

  get calcEndOfStartTime() {
    return this.$dateUtils
      .minus(this.endTime, 1, "day")
      .utc()
      .startOf("day")
      .toDate();
  }

  get calcBeginingOfEndTime() {
    return this.$dateUtils
      .add(this.startTime, 1, "day")
      .utc()
      .startOf("day")
      .toDate();
  }

  get calcEndofEndTime() {
    return moment(new Date())
      .utc()
      .endOf("day")
      .toDate();
  }

  get creationDate() {
    const jpus = [...this.subscription.jpus];
    jpus.sort((a, b) => a.creationDate - b.creationDate);

    return jpus[0].creationDate;
  }

  get calcStartTimeInput() {
    const date = this.$dateUtils.minus(new Date(), 1, "months").valueOf();
    const number = date > this.creationDate ? date : this.creationDate;
    return moment(number)
      .utc()
      .startOf("day")
      .valueOf();
  }

  get activeServers(): string[] {
    return this.selectedServers.length === 0 ? this.serverOptions.map(jpu => jpu.label) : this.selectedServers;
  }

  get activeRegions(): string[] {
    return this.selectedRegions.length === 0 ? this.regionsOptions.map(region => region.label) : this.selectedRegions;
  }

  async requestStatistics() {
    this.isError = false;
    if (this.startTime <= 0 || this.endTime <= 0) {
      return;
    }
    const diffInDays = this.$dateUtils.dayDiff(this.startTime, this.endTime);
    const isMoreThan2Months = diffInDays > 61;
    this.period = isMoreThan2Months ? "monthly" : "daily";

    this.loading = true;
    try {
      this.rawStatisticsData = (await this.$jfSubscriptions.getMultipleStatistics({
        serverNames: this.activeServers,
        startTime: this.$dateUtils.getUtcValue(this.startTime),
        endTime: this.$dateUtils.getUtcValue(this.$dateUtils.add(this.endTime, 1, "day")),
        period: this.period,
      })) as MultipleStatisticsResponse | null;
      if (!this.rawStatisticsData) {
        throw new Error("statistics can't be null");
      }
      this.loading = false;
      this.parseResponse();
    } catch (e) {
      this.$log.error(e);
      this.loading = false;
      this.isError = true;
      this.$jfNotification.error({ text: this.$jfMessages.subscription_usage_graph_loading_failed });
    }
  }

  resetStatistics() {
    this.artifactoryDataSet = {};
    this.xrayDataSet = {};
    this.graphItemsConfig.storage.chartData = {
      labels: [],
      datasets: [],
    };
    this.graphItemsConfig.dt.chartData = {
      labels: [],
      datasets: [],
    };
    this.graphItemsConfig.pl.chartData = {
      labels: [],
      datasets: [],
    };
    this.graphItemsConfig.cdn.chartData = {
      labels: [],
      datasets: [],
    };
    this.graphItemsConfig.storage.displayedSum = "0";
    this.graphItemsConfig.storage.displayedSumUnit = "Bytes";
    this.graphItemsConfig.dt.displayedSum = "0";
    this.graphItemsConfig.dt.displayedSumUnit = "Bytes";
    this.graphItemsConfig.cdn.displayedSum = "0";
    this.graphItemsConfig.cdn.displayedSumUnit = "Bytes";
  }

  isAnActiveRegion(regionToCheck: string): boolean {
    return !!this.activeRegions.find(region => region.toLowerCase().includes(regionToCheck.toLowerCase()));
  }

  datesCallback(timeStampStr: string) {
    const date = moment(parseInt(timeStampStr));
    const format = this.period === "monthly" ? "MMMM" : "MMM D";
    return this.$dateUtils.format(date, format);
  }

  // response is an array of servers. each server has statistics array.
  private parseResponse() {
    if (!this.rawStatisticsData) {
      this.resetStatistics();
      return;
    }
    const artifactoryData: { [key: string]: number } = {};
    const cdnData: { [key: string]: number } = {};
    const pipelinesDataGroupedByDate: { [key: string]: number } = {};
    const xrayData: { [key: string]: number } = {};
    const storageData: { [key: string]: number } = {};

    const serversStatistics = this.rawStatisticsData.serverStatistics
      .filter(servers => this.activeServers.includes(servers.serverName))
      .map(serverStatistic => {
        // convert pipelines from millis to Minutes
        return {
          ...serverStatistic,
          statistics: serverStatistic.statistics.map(statistic => {
            if (!statistic.pipelines) {
              return statistic;
            }
            return { ...statistic, pipelines: Math.ceil(statistic.pipelines / 60000) };
          }),
        };
      });

    if (serversStatistics.length === 0) {
      this.resetStatistics();
      return;
    }
    this.createTotalValueForGraphs(serversStatistics);

    serversStatistics.forEach(serverStatistic => {
      const subscriptionJPUs = this.subscription.jpus;
      if (!subscriptionJPUs) {
        throw new Error("no JPDs found.");
      }

      if (
        !serverStatistic.serverProductTypes.find(
          product => product === "artifactory" || product === "edge" || product === "jcr",
        )
      ) {
        return;
      }

      // there can be a miss match when developing with regions.
      if (!this.isEnterprisePlus && !this.isAnActiveRegion(serverStatistic.region)) {
        this.$log.warn(`region ${serverStatistic.region} doesn't exists in active jpd regions ${this.activeRegions}`);
      }
      const matchingJPU = subscriptionJPUs.find(jpu => jpu.serverName === serverStatistic.serverName);
      if (!matchingJPU || !this.isAnActiveRegion(matchingJPU.region)) {
        return;
      }

      // creates data for each statistics { date : value }
      serverStatistic.statistics.forEach((statistics: ServerStatisticsDTO) => {
        const date = statistics.date;
        GraphHelpers.parser(
          statistics.dataTransfer +
            (statistics.dataTransferTrafficLogArt ? statistics.dataTransferTrafficLogArt : 0) +
            statistics.dataTransferRedirectArtifactory +
            statistics.dataTransferCloudFrontArtifactory +
            statistics.dataTransferArtReplicator,
          // statistics.dataTransferArtP2p,
          date,
          artifactoryData,
        );
        GraphHelpers.parser(
          statistics.dataTransferCloudFrontArtifactory + statistics.dataTransferCloudFrontXray,
          date,
          cdnData,
        );
        GraphHelpers.parser(
          statistics.dataTransferXray +
            statistics.dataTransferRedirectXray +
            statistics.dataTransferCloudFrontXray +
            (statistics.dataTransferTrafficLogXray ? statistics.dataTransferTrafficLogXray : 0),
          date,
          xrayData,
        );
        GraphHelpers.parser(statistics.pipelines, date, pipelinesDataGroupedByDate);
        GraphHelpers.parser(statistics.storage, date, storageData);
      });
    });

    // original dates in number format
    const artifactoryDates = Object.keys(artifactoryData).sort();
    const cdnDates = Object.keys(cdnData).sort();
    const xrayDates = Object.keys(xrayData).sort();
    const pipelinesDates = Object.keys(pipelinesDataGroupedByDate).sort();
    const storageDates = Object.keys(storageData).sort();

    const max = storageDates.reduce((acc, storageDateKey) => Math.max(storageData[storageDateKey], acc), 0);

    this.graphItemsConfig.pl.displayedSum = this.sumPipelinesInMinutes.toString();
    this.graphItemsConfig.pl.displayedSumUnit = "Min";

    this.graphItemsConfig.storage.displayedSum = this.$utils.byteToGigaByte(max).toString();
    this.graphItemsConfig.storage.displayedSumUnit = "GB";

    // will format dates so it will show a month or a day.
    const artifactoryDatesArray = artifactoryDates.map(this.datesCallback);
    const cdnDatesArray = cdnDates.map(this.datesCallback);
    const xrayDatesArray = xrayDates.map(this.datesCallback);
    const setDT = new Set([...artifactoryDatesArray, ...xrayDatesArray, ...cdnDatesArray]);
    const DTdatesArray = Array.from(setDT);

    const PLdatesArray = pipelinesDates.map(this.datesCallback);
    const datesStorageArray = storageDates.map(this.datesCallback);

    const dataStorageInBytes = storageDates.map((date: string) => storageData[date]);
    const artifactoryDataInBytes = artifactoryDates.map((date: string) => artifactoryData[date]);
    const cdnDataInBytes = cdnDates.map((date: string) => cdnData[date]);
    // check if cdn has any data to know if need to show graph.
    this.isCdnDataEmpty = cdnDataInBytes.reduce((prev, current) => prev + current, 0) === 0;
    const xrayDataInBytes = xrayDates.map((date: string) => xrayData[date]);

    const pipelinesInMinutes = pipelinesDates.map((date: string) => pipelinesDataGroupedByDate[date]);
    this.artifactoryDataSet = {
      id: "Artifactory",
      pointBackgroundColor: "#7730C8",
      label: "Artifactory",
      pointStyle: "rect",
      lineTension: 0.1,
      pointRadius: 0,
      data: artifactoryDataInBytes.map(data => this.$utils.byteToGigaByte(data)),
    };

    this.xrayDataSet = {
      id: "Xray",
      pointBackgroundColor: "#7730C8",
      backgroundColor: "rgba(119,49,199,1)",
      label: "Security & Compliance",
      pointStyle: "rect",
      lineTension: 0,
      data: xrayDataInBytes.map(data => this.$utils.byteToGigaByte(data)),
    };

    this.graphItemsConfig.storage.chartData = {
      labels: datesStorageArray,
      datasets: [
        {
          id: "Day",
          label: "Storage Data",
          backgroundColor: "rgba(119,49,199,1)",
          data: dataStorageInBytes.map(data => this.$utils.byteToGigaByte(data)),
        },
      ],
    };

    this.graphItemsConfig.cdn.chartData.labels = cdnDatesArray;

    this.graphItemsConfig.cdn.chartData = {
      labels: cdnDatesArray,
      datasets: [
        {
          id: "Cdn",
          pointBackgroundColor: "#7730C8",
          label: "cdn",
          pointStyle: "rect",
          lineTension: 0.1,
          pointRadius: 0,
          data: cdnDataInBytes.map(data => this.$utils.byteToGigaByte(data)),
        },
      ],
    };

    this.graphItemsConfig.dt.chartData.labels = DTdatesArray;

    this.graphItemsConfig.pl.chartData = {
      labels: PLdatesArray,
      datasets: [
        {
          id: "pipelines",
          pointBackgroundColor: "#7730C8",
          label: "Pipelines Usage",
          pointStyle: "rect",
          lineTension: 0.1,
          pointRadius: 0,
          data: pipelinesInMinutes,
        },
      ],
    };
  }

  // creates total sum of 1/2/3 values from array of statistics in this case creates total for each graph.
  private createTotalValueForGraphs(serversStatistics: MultipleStatisticsResponse["serverStatistics"]) {
    this.dataTransferInBytesArtifactory = serversStatistics.reduce(
      GraphHelpers.sumCallback([
        "dataTransfer",
        "dataTransferTrafficLogArt",
        "dataTransferRedirectArtifactory",
        "dataTransferCloudFrontArtifactory",
        "dataTransferArtReplicator",
        // "dataTransferArtP2p"
      ]),
      0,
    );
    this.graphItemsConfig.cdn.displayedSum = this.$utils
      .byteToGigaByte(
        serversStatistics.reduce(
          GraphHelpers.sumCallback(["dataTransferCloudFrontArtifactory", "dataTransferCloudFrontXray"]),
          0,
        ),
      )
      .toString();

    this.dataTransferInBytesXray = serversStatistics.reduce(
      GraphHelpers.sumCallback([
        "dataTransferRedirectXray",
        "dataTransferXray",
        "dataTransferCloudFrontXray",
        "dataTransferTrafficLogXray",
      ]),
      0,
    );
    this.sumPipelinesInMinutes = serversStatistics.reduce(GraphHelpers.sumCallback(["pipelines"]), 0);
  }

  onDisplayArtifactoryDTChanged(displayArtifactoryDT: boolean) {
    this.displayArtifactoryDT = displayArtifactoryDT;
  }

  onDisplayXrayDTChanged(displayXrayDT: boolean) {
    this.displayXrayDT = displayXrayDT;
  }

  graphBusEvents: { [key: string]: (...params: any[]) => any } = {
    displayArtifactoryDTChanged: this.onDisplayArtifactoryDTChanged,
    displayXrayDTChanged: this.onDisplayXrayDTChanged,
  };

  created() {
    for (let key in this.graphBusEvents) {
      this.graphEventBus.$on(key, this.graphBusEvents[key]);
    }
  }

  async mounted() {
    this.displayXrayDT = !!this.subscription.meta.hasXray;
    this.startTime = this.calcStartTimeInput;
    const subscriptions = await this.$jfSubscriptions.getSubscriptions();
    this.isXrayNewForCustomer = this.$jfSubscriptions.isXrayNewForCustomer(
      subscriptions.map(subscription => subscription.meta),
    );
    if (!this.isEnterprisePlus) {
      this.selectedServers = [this.subscription.serverName];
      this.selectedRegions = this.subscription.region === "-" ? [] : [this.subscription.region];
    }
    this.requestStatistics();
  }

  beforeDestroy() {
    for (let key in this.graphBusEvents) {
      this.graphEventBus.$off(key, this.graphBusEvents[key]);
    }
  }
}
