
import BarChart from "@/components/charts/BarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import GraphTitle from "@/components/views/subscriptions/subscriptionGraphs/graphItem/GraphTitle.vue";
import GraphOptions from "@/components/views/subscriptions/subscriptionGraphs/GraphOptions";
import { ChartsProps } from "@/types/charts";
import { ChartData, SubscriptionGraphItemProps } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SubscriptionGraphItem",
  components: { GraphTitle, BarChart, LineChart },
})
export default class SubscriptionGraphItem extends Vue {
  @Prop({ default: true })
  private loading!: SubscriptionGraphItemProps["loading"];
  @Prop({ default: false })
  private inError!: SubscriptionGraphItemProps["inError"];
  @Prop() private title!: SubscriptionGraphItemProps["title"];
  @Prop() private titlePrecision!: SubscriptionGraphItemProps["titlePrecision"];
  @Prop() private subTitle!: SubscriptionGraphItemProps["subTitle"];
  @Prop() private displayedSum!: SubscriptionGraphItemProps["displayedSum"];
  @Prop() private displayedSumUnit!: SubscriptionGraphItemProps["displayedSumUnit"];
  @Prop() private graphType!: SubscriptionGraphItemProps["graphType"];
  @Prop({ default: 100 })
  private graphComponentHeight!: SubscriptionGraphItemProps["graphComponentHeight"];
  @Prop({ default: "" })
  private graphMargin!: SubscriptionGraphItemProps["graphMargin"];
  @Prop() private graphData!: SubscriptionGraphItemProps["graphData"];
  @Prop({ default: undefined })
  private graphCustomProps!: SubscriptionGraphItemProps["graphCustomProps"];
  @Prop({ default: "inline" })
  private legendDisplayMode!: SubscriptionGraphItemProps["legendDisplayMode"];
  @Prop({ default: () => null })
  private graphYaxisCustomCallback!: SubscriptionGraphItemProps["graphYaxisCustomCallback"];
  @Prop() private legendMessage!: SubscriptionGraphItemProps["legendMessage"];
  @Prop() private legendFilter!: SubscriptionGraphItemProps["legendFilter"];

  localGraphType: SubscriptionGraphItemProps["graphType"] = "Bar";

  get isBarChart() {
    return this.localGraphType === "Bar";
  }

  get calcGraphStyle() {
    return { margin: this.graphMargin };
  }

  get chartProps(): ChartsProps {
    let chartData: ChartData = {
      labels: this.graphData.labels,
      datasets: this.graphData.datasets.map(dataset => ({
        ...dataset,
        barPercentage: this.hasData && this.isBarChart ? this.calculateStorageBarPercent() : 0.9,
      })),
    };
    return {
      customOptions: this.customOptions,
      data: chartData,
      ...this.graphCustomProps,
    };
  }

  get customOptions() {
    const baseOpts = this.isBarChart ? { ...GraphOptions.barOptions } : { ...GraphOptions.lineOptions };
    baseOpts.scales.yAxes[0].ticks.userCallback = this.graphYaxisCustomCallback;
    return baseOpts;
  }

  calculateStorageBarPercent(): number {
    if (!this.graphData || !this.graphData.labels) {
      return 0.9;
    }
    const nbBars = this.graphData.labels.length;
    if (nbBars >= 25) return 0.9;
    if (nbBars >= 20) return 0.8;
    if (nbBars >= 15) return 0.7;
    if (nbBars >= 10) return 0.5;
    if (nbBars >= 5) return 0.4;
    if (nbBars >= 3) return 0.3;
    return 0.2;
  }
  get displayGraph() {
    return !this.loading && !this.inError;
  }

  get graphLegendClasses() {
    const classes: string[] = [this.legendDisplayMode];
    return classes;
  }

  get getGraphMessageClasses() {
    const classes: string[] = [];
    if (this.inError && !this.loading) {
      classes.push("error");
    }
    return classes;
  }

  get hasData() {
    return this.graphData && this.graphData.datasets && this.graphData.datasets.length !== 0;
  }

  get graphInfo(): string {
    if (this.loading) {
      return "Loading...";
    }
    if (this.inError) {
      return "Error";
    }
    return "";
  }

  get displayLegendMessage() {
    return !!this.legendMessage && this.legendMessage.display;
  }

  get displayLegendFilter() {
    return !!this.legendFilter && this.legendFilter.display;
  }

  mounted() {
    setTimeout(() => {
      this.localGraphType = this.graphType;
    }, 0);
  }
}
