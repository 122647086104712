
import ModalMonthlyEOYDiscount from "@/components/content/promotions/monthlyEOYDiscount/ModalMonthlyEOYDiscount.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "MonthlyEOYDiscountTrigger",
  components: {},
})
export default class MonthlyEOYDiscountTrigger extends Vue {
  triggerText = "<span italic>Scale On Us</span> Promotion is available for you";
  dataGTMEvent = "subscription-title|eoy-promotional-offer-link";

  displayModal() {
    this.$jfModal.showCustom(
      ModalMonthlyEOYDiscount,
      "md",
      {},
      {
        title: "Scale On Us",
        headerBorder: false,
        footerBorder: false,
      },
    );
  }
}
