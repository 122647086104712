
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import { LoadingMaskProps } from "@/types/loadingMask";
import { ManageEndpointRequest } from "@jfrog-ba/myjfrog-common";
import { FlatSubscription } from "@/types/localtypes";

@Component({
  name: "ModalPrivateLink",
  components: {
    LoadingMask,
    JFCheckBox,
  },
})
export default class ModalPrivateLink extends Vue {
  @Prop() private manageEndpointRequest!: ManageEndpointRequest;
  @Prop() private region!: string;
  @Prop() private setLoading!: (isLoading: boolean) => undefined;
  @Prop() private refreshMenu!: () => undefined;
  @Prop() private subscription!: FlatSubscription;

  checkBoxValue = false;

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: false,
    };
  }

  get isDisabled() {
    return !this.checkBoxValue;
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }

  async deleteEndpoint() {
    try {
      this.setLoading(true);
      await this.$jfSubscriptions.manageEndpoint(this.subscription.accountNumber, this.manageEndpointRequest);
      this.setLoading(false);
      this.refreshMenu();
      this.$jfModal.dismiss();
    } catch (e) {
      this.setLoading(false);
      this.$jfNotification.error({
        text: this.$jfMessages.subscriptions_narcissus_task_in_progress,
      });
      this.$log.error(e);
    }
  }

  onChangeBox(checkboxState: boolean) {
    this.checkBoxValue = checkboxState;
  }
}
