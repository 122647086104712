
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { Region, FlatSubscription } from "@/types/localtypes";
import { JpuDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ModalChangeRegion",
  components: { LoadingMask },
})
export default class ModalChangeRegion extends Vue {
  @Prop() private onApprove!: (selectedRegion: JpuDTO["region"]) => any;
  @Prop() private subscription!: FlatSubscription;
  @Prop() private regions!: Region[];

  selectedRegion: JpuDTO["region"] = "";

  get disclaimer() {
    return this.subscription.meta.isTrial
      ? "Change Cloud Region is only available for paid subscriptions. Complete your purchase to activate this option."
      : "Select the new region you would like to switch to and click confirm. Your request will be sent to a JFrog technical representative for review, and we will contact you with an update on your request as soon as possible.<br/><br/>Note: The region update process will only start once it is confirmed by a JFrog representative.";
  }
  get isMobile() {
    return this.$mq === "mobile";
  }

  get modalBodyStyle() {
    let minHeight = "380px";
    const viewHeight = document.body.clientHeight;
    if (this.isMobile && viewHeight < 500) {
      minHeight = "auto";
    }
    return {
      minHeight: minHeight,
    };
  }

  get contextualRegionsInputs() {
    if (this.subscription) {
      return this.regions
        .filter(
          region =>
            region.jpuProviderCode === this.subscription.cloudProviderCode &&
            region.name !== this.subscription.region &&
            region.name.toLowerCase() !== "default",
        )
        .map(region => ({ label: region.name, code: region.code }));
    }
    return [];
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading(): boolean {
    return !this.subscription;
  }

  get shouldLockConfirmButton() {
    return this.subscription.meta.isTrial || this.isLoading || !this.selectedRegion;
  }

  getProviderImage(providerCode: FlatSubscription["cloudProviderCode"]) {
    return this.$jfImages.get(this.$jfImages.getProviderLogoFileName(providerCode));
  }

  getProviderDisplayName(providerCode: JpuDTO["cloudProvider"]) {
    return this.$jfSubscriptions.extractProviderName(providerCode);
  }
  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }

  dismiss() {
    this.$jfModal.dismiss();
  }
  confirm() {
    if (!this.shouldLockConfirmButton) {
      this.onApprove(this.selectedRegion);
      this.$jfModal.close();
    }
  }
}
