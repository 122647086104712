
import LoadingMask from "@/components/common/LoadingMask.vue";
import { JFCheckBox, JFFormControl } from "jfrog-ui-vue-essentials";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { LoadingMaskProps } from "@/types/loadingMask";
import { GenerateTokenResponse } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "ModalGenerateTokenAdmin",
  components: { LoadingMask, JFCheckBox, JFFormControl },
})
export default class ModalGenerateTokenAdmin extends Vue {
  @Prop() private migrationCandidates!: string[];
  @Prop() private selectedInstance!: string;
  @Inject() readonly globalBus!: Vue;

  isLoading: boolean = false;
  currentStep = "";
  eventBus: Vue = new Vue();
  generatedToken = "";

  get isDisabledGenerateToken() {
    return !this.selectedInstance;
  }

  get isDisabledCopy() {
    return !this.generatedToken;
  }

  get instances() {
    return this.migrationCandidates;
  }

  get hasMigrationCandidateServer() {
    return this.migrationCandidates.length > 0;
  }

  get hasMultipleMigrationCandidateServer() {
    return this.migrationCandidates.length > 1;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: false,
    };
  }

  get noCandidateMigrationExistsMessage() {
    return this.$jfMessages.jmis_no_candidate_migration_exists;
  }

  mounted() {}

  dismiss() {
    this.$jfModal.dismiss();
  }

  async generateToken() {
    try {
      await this.$jfJmisService
        .generateToken({ serverName: this.selectedInstance })
        .then((data: GenerateTokenResponse) => {
          this.generatedToken = data.token;
        });
    } catch (error) {
      this.$log.error(error.message);
      this.$jfNotification.error({ text: "Failed to generate token", duration: 15000 });
    }
  }

  copySignedUrlToClipboard() {
    this.copyToClipboard(this.generatedToken);
    this.$jfNotification.show(
      {
        group: "general",
        title: "Copied !",
        type: "success",
        duration: 1250,
        text: "Generated token has been copied successfully",
      },
      "success",
    );
  }

  copyToClipboard(text: string) {
    const el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("style", "position: absolute; left: -9999px;");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}
