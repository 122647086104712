
import { FlatSubscription } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

enum SubscriptionResumeItemTag {
  TYPE = "TYPE",
  SERVER = "SERVER",
  PROVIDER = "PROVIDER",
  REGION = "REGION",
  STATUS = "STATUS",
  SERVICES = "SERVICES",
}
interface ResumeItem {
  label: string;
  icon: string;
  value: string;
  classes: string[];
  valueClasses: string[];
  tooltipContent: string | false;
  onClick?: () => void;
  dataGtmEventTitle?: string;
  extraLink?: ResumeItemExtraLink;
}

interface ResumeItemExtraLink {
  label: string;
  iconClasses: string[];
  onClick: () => void;
  dataGtmEventTitle: string;
}

@Component({
  name: "SubscriptionResume",
  components: {},
})
export default class SubscriptionResume extends Vue {
  @Prop() private subscription!: FlatSubscription;

  get isMobile() {
    return this.$mq === "mobile";
  }
  get isTablet() {
    return this.$mq === "tablet";
  }
  get isLaptop() {
    return this.$mq === "laptop";
  }

  resumeItemClasses(itemTag: SubscriptionResumeItemTag) {
    const classes = [this.$mq, itemTag.toString().toLowerCase()];

    if (this.subscription.meta.isTrial) {
      classes.push("less-padding");
    }

    // set the width of the item
    let widthClass = "fx1";
    if (this.isMobile) {
      widthClass = "w-100";
    } else if (this.isTablet) {
      widthClass = "w-50";
    } else if (this.isLaptop) {
      switch (itemTag) {
        case "SERVER":
          widthClass = "w-25";
          break;
        case "STATUS":
          widthClass = "w-15";
          break;
        default:
          widthClass = "w-20";
      }
    }
    classes.push(widthClass);

    // other classes
    if (itemTag === "SERVER") {
      classes.push("link-element");
    }

    return classes;
  }

  get resumeItems(): ResumeItem[] {
    if (!this.subscription) {
      return [];
    }
    return [
      {
        label: "Subscription Type",
        icon: "icon-mjf-type",
        value: this.subscription.meta.displayName || "N/A",
        tooltipContent: this.subscription.meta.displayName || "N/A",
        classes: this.resumeItemClasses(SubscriptionResumeItemTag.TYPE),
        valueClasses: [],
      },
      {
        label: "Included Services",
        icon: "icon-art-puppet",
        value: this.$jfSubscriptions.getIncludedServicesLabel(this.subscription.meta),
        tooltipContent: false,
        classes: this.resumeItemClasses(SubscriptionResumeItemTag.SERVICES),
        valueClasses: [],
      },
      {
        label: "Server Name",
        icon: this.subscription.meta.isJCR ? "icon-mjf-jcr" : "icon-mjf-artifactory",
        value: this.subscription.serverName,
        tooltipContent: this.subscription.serverName,
        classes: this.resumeItemClasses(SubscriptionResumeItemTag.SERVER),
        valueClasses: ["server-value"],
        onClick: this.goToArtifactory,
        dataGtmEventTitle: "to-artifactory-link",
        extraLink: {
          label: "Go to Xray",
          iconClasses: ["icon-art-xray", "pr-2"],
          onClick: this.goToXray,
          dataGtmEventTitle: "to-xray-link",
        },
      },
      {
        label: "Cloud Provider",
        icon: "icon-mjf-provider",
        tooltipContent: false,
        value: `${
          this.subscription.cloudProviderCode
            ? `<img height="100%" src="${this.$jfImages.get(
                this.$jfImages.getProviderLogoFileName(this.subscription.cloudProviderCode),
              )}" />`
            : "-"
        }`,
        classes: this.resumeItemClasses(SubscriptionResumeItemTag.PROVIDER),
        valueClasses: [],
      },
      {
        label: "Cloud Region",
        icon: "icon-mjf-region",
        value: this.subscription.region,
        tooltipContent: false,
        classes: this.resumeItemClasses(SubscriptionResumeItemTag.REGION),
        valueClasses: [],
      },
      {
        label: "Subscription Status",
        icon: this.subscription.status === "Active" ? "icon-mjf-active" : "icon-mjf-canceled",
        value: this.subscriptionStatus(true),
        tooltipContent: this.subscriptionStatus(false),
        classes: this.resumeItemClasses(SubscriptionResumeItemTag.STATUS),
        valueClasses: [],
      },
    ];
  }

  onItemClick(item: ResumeItem) {
    item.onClick && item.onClick();
  }
  onExtraLinkClick(extraLink: ResumeItemExtraLink) {
    extraLink.onClick && extraLink.onClick();
  }

  renderTooltipProps(tooltipContent: string | false) {
    if (tooltipContent) {
      return {
        content: tooltipContent,
        html: true,
      };
    }
    return false;
  }

  subscriptionStatus(withIcon: boolean) {
    const meta = this.subscription.meta;
    let stateSuffix = "";
    if (meta.isTrial && !meta.isExpiredTrial && this.subscription.status === "Active") {
      const daysLeft = this.$dateUtils.dayDiff(new Date(), this.subscription.endOfTrial);
      const metaText = daysLeft === 0 ? "Last Day" : `${daysLeft} day${daysLeft > 1 ? "s" : ""} remaining`;
      const metaTextElement = `<span class="meta-text-label">${withIcon ? metaText : "(" + metaText + ")"}</span>`;
      // const metaIconColorClass = daysLeft <= 5 ? "danger" : daysLeft <= 14 ? "warning" : "info";
      const metaIconColorClass = "info";
      const icon = withIcon
        ? `<i class="icon-art-xray-moitoring-alert-new alert-trial-icon meta-text-icon ${metaIconColorClass}"></i>`
        : ``;
      stateSuffix = `<span ml-2 class="meta-text-wrapper">${icon}${metaTextElement}</span>`;
    }
    return `${this.subscription.status}${stateSuffix}`;
  }

  goToArtifactory() {
    if (this.subscription.jpus[0].status !== "ACTIVE") {
      return;
    }
    this.goToServer("artifactory");
  }
  goToXray() {
    this.goToServer("xray");
  }

  goToServer(product: "artifactory" | "xray") {
    const urlExtension = process.env.NODE_ENV === "production" ? "io" : "info";
    const serverName = this.subscription.serverName;
    let url: string | null = null;
    switch (product) {
      case "artifactory":
        url = `https://${serverName}.jfrog.${urlExtension}/`;
        break;
      case "xray":
        url = `https://${serverName}-xray.jfrog.${urlExtension}`;
        break;
    }
    if (url) {
      window.open(url, "_blank");
    } else {
      this.$log.error(`URL not defined for product '${product}'`);
    }
  }
}
