
declare var PLATFORM_DOMAIN: any;
declare var ENABLE_GCP_PRIVATE_LINK: any;
import { allCountries } from "@/assets/countries";
import ModalConfigureEPlusIntances from "@/components/views/subscriptions/ePlus/modals/eplusInstancesConfiguration/ModalConfigureEPlusIntances.vue";
import ModalIPListing from "@/components/views/subscriptions/modals/ModalIPListing.vue";
import { JFEssentialsGridPopupMenuOptionsGRID } from "@/types/3rdPartyLibs";
import {
  AnyObject,
  FlatSubscription,
  JpusFormatedForTopology,
  PrivateEndpointTopologyStatus,
  Region,
  SubscriptionJPDCardProps,
  TopologyGridColumns,
  TopologyGridProps,
} from "@/types/localtypes";
import { JFGrid } from "jfrog-ui-vue-essentials";
import _diffrence from "lodash/difference";
import _pull from "lodash/pull";
import {
  EnterprisePlusAddServersRequest,
  JpuDTO,
  ReadServerIPMetaResponse,
  ReadServerStatusResponse,
  UpdateGeoIPCountriesRequest,
} from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import ModalGeoRestriction from "../modals/ModalGeoRestriction.vue";
import SubscriptionJPDCard from "./SubscriptionJPDCard.vue";
import ModalPrivateLink from "@/components/views/subscriptions/modals/privateLink/ModalPrivateLink.vue";

enum ServerMetadataStatusPrefix {
  "Loading" = "Loading",
  "InProgress" = "In Progress",
  "Active" = "Active",
  "NotActive" = "Not Active",
  "Error" = "Error",
}

const privateEndpointStatus: PrivateEndpointTopologyStatus = {
  InProgress: { label: "In Progress", statuses: ["add", "remove", "reject"] },
  Failed: { label: "Failed", statuses: ["add_error", "remove_error"] },
  Create: { label: "Create", statuses: [] },
  NotActive: { label: "Not Active", statuses: ["blocked"] },
  Connected: { label: "Connected", statuses: ["connected"] },
  Loading: "Loading",
  Removed: { label: "Removed", statuses: ["removed"] },
};

enum ServerStatusPrefix {
  "Loading" = "Loading",
  "InProgress" = "In Progress",
  "Active" = "Active",
  "NotActive" = "Not Active",
  "Error" = "Error",
}

interface TopologyNotification {
  displayed: boolean;
  disabled: boolean;
  message: string;
  subMessage: string;
  btnText: string;
  onBtnClick: () => void;
  wrapperClasses: string[];
}

@Component({
  name: "SubscriptionTopology",
  components: {
    JFGrid,
    SubscriptionJPDCard,
  },
})
export default class SubscriptionTopology extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private subscription!: FlatSubscription;

  regionsList: Region[] | null = null;

  @Watch("serverIpMetaData")
  onServerIpMetaDataChange() {
    this.reRenderActionDropdown();
  }

  @Watch("serversStatus")
  onServersStatusChange() {
    this.reRenderStatusCells();
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  productsListSeparator = ",";
  whiteMarkIp = "0.0.0.0/0";
  columnFiltersState: AnyObject = {};
  serverIpMetaData: ReadServerIPMetaResponse | null = null;
  serversIpMetaDataLoaded = false;
  serversStatus: ReadServerStatusResponse | null = null;
  serversStatusLoaded = false;
  serverNamesNotYetDeployed: JpuDTO["serverName"][] = [];

  gridEventBus = new Vue();
  gridProps: TopologyGridProps = {
    eventBus: this.gridEventBus,
    theme: "light",
    displayToolbar: false,
    columns: [
      {
        label: "Type",
        field: "type",
        tooltip: false,
        sortable: true,
      },
      {
        label: "Server Name",
        field: "serverName",
        tooltip: false,
        sortable: true,
        cellCssCalculatedData: [
          row => {
            return row ? this.getclickableGridElementClasses(row) : "";
          },
        ],
        onClick: this.goToArtifactory,
      },
      {
        label: "Region",
        field: "region",
        tooltip: false,
        sortable: true,
      },
      {
        label: "Cloud Provider",
        field: "cloudProvider",
        tooltip: false,
        sortable: true,
        getHtml: this.renderCloudProviderCellHtml,
      },
      {
        label: "Services",
        field: "products",
        tooltip: true,
        sortable: true,
        selectFilterOptions: this.retrieveProductNames(),
      },
      {
        label: "Status",
        field: "formattedStatus",
        tooltip: false,
        sortable: true,
        getHtml: (value, row, index) => {
          return this.formatStatus(row.serverName, row.status);
        },
        cellCssCalculatedData: [
          row => {
            return row ? this.getServerStatusSubLabelClasses(this.formatStatus(row.serverName, row.status)) : "";
          },
        ],
      },
      {
        label: "Actions",
        field: "actions",
        tooltip: false,
        sortable: false,
        selectFilter: "off",
        popupMenuConfig: (row: JpusFormatedForTopology, column: TopologyGridColumns) => {
          return this.definePopupMenuConfigs(row);
        },
      },
    ],
    state: {
      page: 1,
      orderBy: "type",
      order: "asc",
    },
    config: {
      displayColumnSelectFilters: true,
      fetchDataPromise: () => {
        return Promise.resolve({ data: this.formatedJPUs, total: this.formatedJPUs ? this.formatedJPUs.length : 0 });
      },
      noPagination: true,
      mode: "client",
    },
  };

  definePopupMenuConfigs(jpu: JpusFormatedForTopology) {
    const forCard = !this.displayModeIsGrid;
    let whitelistIpSublabelValue = this.getWhitelistIpsLabel(jpu);
    let geoIPCountriesSubLabelValue = this.getGeoIPRestrictionsStatusLabel(jpu);
    let privateLinkValue = this.getPrivateLinkStatusLabel(jpu);
    const menuConfig: JFEssentialsGridPopupMenuOptionsGRID = {
      disabled: false,
      placement: "auto",
      theme: "web",
      title: "Actions",
      displayTriggerText: forCard,
      trigger: "click",
      iconClasses: forCard ? ["rotate-90"] : [],
      titlePlacement: "left",
      iconName: forCard ? "icon-arrow" : "icon-mjf-pipelines-more",
      items: [
        {
          label: `IP/CIDR Whitelist`,
          subLabel: {
            text: whitelistIpSublabelValue,
            customClasses: this.getServerMetadataSubLabelClasses(whitelistIpSublabelValue),
          },
          isDisabled: () => this.isServermetadataActionDisabled(jpu, whitelistIpSublabelValue),
          click: item => this.handleChangeIPListing(jpu),
          icon: {
            iconName: "icon-more",
            iconClasses: ["mjf-green"],
            iconStyles: ["font-size:14px"],
          },
        },
        {
          label: "Geolocation Restrictions",
          subLabel: {
            text: geoIPCountriesSubLabelValue,
            customClasses: this.getServerMetadataSubLabelClasses(geoIPCountriesSubLabelValue),
          },
          isDisabled: () => this.isServermetadataActionDisabled(jpu, geoIPCountriesSubLabelValue),
          click: item => this.handleGeoRestrictionModal(jpu),
          icon: {
            iconName: "icon-more",
            iconClasses: ["mjf-green"],
            iconStyles: ["font-size:14px"],
          },
        },
      ],
    };

    if (this.isShowPrivateLink(jpu)) {
      menuConfig.items.push({
        label: "PrivateLinks",
        subLabel: {
          text: privateLinkValue,
          customClasses: this.getPrivateLinkSubLabelClasses(privateLinkValue),
        },
        isDisabled: () => this.isServermetadataActionDisabled(jpu, geoIPCountriesSubLabelValue),
        click: () => this.handlePrivateLink(jpu),
        icon: {
          iconName: "icon-more",
          iconClasses: ["mjf-green"],
          iconStyles: ["font-size:14px"],
        },
      });
    }

    return menuConfig;
  }

  isShowPrivateLink(jpu: JpusFormatedForTopology) {
    const meta = this.subscription.meta;
    return (
      (this.isJpuAmazon(jpu) || this.isJpuAzure(jpu) || this.isJpuGoogle(jpu)) &&
      (meta.isEnterprisePlus || meta.isEnterprise) &&
      !this.isMobile
    );
  }

  isJpuAmazon(jpu: JpusFormatedForTopology) {
    return jpu.cloudProvider === "amazon";
  }

  isJpuAzure(jpu: JpusFormatedForTopology) {
    return jpu.cloudProvider === "azure";
  }

  isJpuGoogle(jpu: JpusFormatedForTopology) {
    return jpu.cloudProvider === "google" && ENABLE_GCP_PRIVATE_LINK === "true";
  }

  async handlePrivateLink(jpu: JpusFormatedForTopology) {
    this.$jfModal.showCustom(
      ModalPrivateLink,
      "xl",
      {
        jpu,
        subscription: this.subscription,
        accountNumber: this.subscription.accountNumber,
      },
      {
        title: "",
        onDismiss: () => this.globalBus.$emit("onRefreshTopologyData"),
        forceDisplayTitle: true,
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  isServermetadataActionDisabled(jpu: JpusFormatedForTopology, serverMetadataValue: string) {
    return !this.serversIpMetaDataLoaded || this.serverActionIsDisabledByStatus(serverMetadataValue);
  }

  jpdMobileCardProps(jpu: JpusFormatedForTopology): SubscriptionJPDCardProps {
    return {
      jpu,
      whitelistIpLabel: this.getWhitelistIpsLabel(jpu),
      whitelistIpClasses: this.getServerMetadataSubLabelClasses(this.getWhitelistIpsLabel(jpu)),
      onWhitelistIpTriggerClick: this.handleChangeIPListing,
      popupMenuConfig: this.definePopupMenuConfigs(jpu),
    };
  }

  ipsToSaveAreNew(originalIps: string[], newIps: string[]): boolean {
    // removing the original whitemark IP
    // (not returned by the modal but can be present in the original Ips returned by the server. So we should remove it to be accurate in the difference calculation)
    _pull(originalIps, this.whiteMarkIp);

    // building the array of diffrences between the original ips and the new ones
    const differences = [..._diffrence(originalIps, newIps), ..._diffrence(newIps, originalIps)];

    // if there are differences that means that the provided IP's are new and should be saved.
    return !!differences.length;
  }

  getServiceIcon(service: string) {
    const serviceName = this.$utils.trimAll(service).toLowerCase();
    switch (serviceName) {
      case "artifactory":
      case "xray":
      case "pipelines":
      case "edge":
      case "jcr":
        return `icon-mjf-${serviceName}`;
      case "distribution":
        return `icon-mjf-bintray`;
      case "missioncontrol":
        return `icon-mjf-jfmc`;
      default:
        return null;
    }
  }

  renderCloudProviderCellHtml(cloudProvider: string, jpu: JpusFormatedForTopology, index: number) {
    if (!cloudProvider) {
      return `-`;
    }
    const providerLogoSrc = this.$jfImages.get(
      this.$jfImages.getProviderLogoFileName(cloudProvider as JpuDTO["cloudProvider"]),
    );
    const providerImgTag = `<div fx mr-2 class="cell-logo-wrapper"><img src="${providerLogoSrc}" alt="cloudProvider" class="cell-logo-img ${cloudProvider}" /></div>`;
    const providerNameTag = `<div fx capitalize>${cloudProvider}</div>`;
    return `<div fx>${providerImgTag} ${providerNameTag}</div>`;
  }

  get meta() {
    return this.subscription.meta;
  }

  get nbInstancesToConfigure() {
    return this.subscription.nbInstanceConfigurable || 0;
  }

  get nbEdgesToConfigure() {
    return this.subscription.nbEdgesConfigurable || 0;
  }

  get totalServersToConfigure() {
    return this.nbInstancesToConfigure + this.nbEdgesToConfigure;
  }

  get hasSomeServersToConfigure() {
    return !!this.totalServersToConfigure;
  }

  get canConfigureSomeServers() {
    return (
      !this.isMobile &&
      !this.isTablet &&
      this.$jfFeatureNotifications.subscriptionCanConfigureNewInstances(this.subscription) &&
      this.hasSomeServersToConfigure
    );
  }

  get canConfigureNewInstance() {
    return (
      this.hasSomeServersToConfigure &&
      this.serversStatusLoaded &&
      !this.topologyIsInProgress &&
      !this.topologyIsInError &&
      this.regionsList
    );
  }

  get topologyIsInError() {
    if (!this.formatedJPUs) {
      return false;
    }
    const nbInError = this.formatedJPUs.filter(formatedJpu => formatedJpu.formattedStatus === ServerStatusPrefix.Error)
      .length;

    return nbInError;
  }

  get topologyIsInProgress() {
    if (!this.formatedJPUs) {
      return true;
    }

    const nbInProgress = this.formatedJPUs.filter(
      formatedJpu => formatedJpu.formattedStatus === ServerStatusPrefix.InProgress,
    ).length;

    return nbInProgress;
  }

  get notificationSubmessage() {
    if (!this.serversStatusLoaded || !this.regionsList) {
      return "Loading your topology...";
    }
    if (this.topologyIsInError) {
      return `We've encountered an error, please contact <a href="mailto:service@jfrog.com" simple-link>service@jfrog.com</a>.`;
    }
    if (this.topologyIsInProgress) {
      return "Updating your environment. This may take a while.";
    }
    return "";
  }

  get notificationExtraClasses() {
    const classes: string[] = [];
    if (!this.canConfigureNewInstance) {
      classes.push("disabled");
    }
    return classes;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }

  get displayModeIsGrid() {
    return !this.isMobile && !this.isTablet;
  }

  get isHybridEnterprisePlus() {
    return this.subscription.paymentType === "ENTERPRISE_PLUS_HYBRID";
  }

  get filteredJPUStatus(): JpuDTO["status"][] {
    return ["DELETED", "HISTORY", "MOVED"];
  }

  get formatedJPUs(): JpusFormatedForTopology[] | undefined {
    return (
      this.subscription &&
      this.subscription.jpus &&
      this.subscription.jpus
        .filter(jpu => !this.filteredJPUStatus.includes(jpu.status))
        .map(jpu => ({
          ...jpu,
          cloudProvider: jpu.cloudProvider,
          type:
            jpu.products.filter(product => product.productName.includes("artifactory")).length === 1
              ? "Artifactory"
              : "Distribution Edge",
          products: jpu.products
            .map(p => this.$utils.capitalize(this.formatProductNames(p.productName)))
            .join(`${this.productsListSeparator} `),
          formattedStatus: this.formatStatus(jpu.serverName, jpu.status),
        }))
    );
  }

  serverIsReallyActive(jpu: JpusFormatedForTopology) {
    return this.formatStatus(jpu.serverName, jpu.status).startsWith(ServerMetadataStatusPrefix.Active);
  }

  serverActionIsDisabledByStatus(serverActionStatusValue: string) {
    return (
      serverActionStatusValue.startsWith(ServerMetadataStatusPrefix.Loading) ||
      serverActionStatusValue.startsWith(ServerMetadataStatusPrefix.Error)
    );
  }

  getServerMetadataSubLabelClasses(metaDataValue: string) {
    if (
      metaDataValue.startsWith(ServerMetadataStatusPrefix.Loading) ||
      metaDataValue.startsWith(ServerMetadataStatusPrefix.NotActive)
    ) {
      return ["jf-almost-black"];
    }
    if (metaDataValue.startsWith(ServerMetadataStatusPrefix.Error)) {
      return ["danger"];
    }
    if (metaDataValue.startsWith(ServerMetadataStatusPrefix.InProgress)) {
      return ["jf-yellow"];
    }
    if (metaDataValue.startsWith(ServerMetadataStatusPrefix.Active)) {
      return ["mjf-green"];
    }
    return [];
  }

  getPrivateLinkSubLabelClasses(metaDataValue: string) {
    if (
      metaDataValue.startsWith(privateEndpointStatus.Loading) ||
      privateEndpointStatus.Create.label === metaDataValue ||
      privateEndpointStatus.NotActive.label === metaDataValue
    ) {
      return ["jf-almost-black"];
    }
    if (privateEndpointStatus.Failed.label === metaDataValue) {
      return ["danger"];
    }
    if (privateEndpointStatus.InProgress.label === metaDataValue) {
      return ["jf-yellow"];
    }
    if (privateEndpointStatus.Connected.label === metaDataValue) {
      return ["mjf-green"];
    }
    return ["danger"];
  }

  getServerStatusSubLabelClasses(serverStatusValue: string) {
    if (serverStatusValue.startsWith(ServerMetadataStatusPrefix.Loading)) {
      return ["jf-almost-black"];
    }
    if (serverStatusValue.startsWith(ServerMetadataStatusPrefix.Error)) {
      return ["danger"];
    }
    if (serverStatusValue.startsWith(ServerMetadataStatusPrefix.InProgress)) {
      return ["jf-yellow"];
    }
    if (serverStatusValue.startsWith(ServerMetadataStatusPrefix.Active)) {
      return ["mjf-green"];
    }
    return [];
  }

  formatStatus(serverName: JpuDTO["serverName"], status: JpuDTO["status"]): string {
    switch (status) {
      case "ACTIVE":
        return this.getServersStatusLabel(serverName);
      case "BLOCKED":
        return ServerStatusPrefix.NotActive;
      case "NEW":
      case "FAILED":
        return ServerStatusPrefix.InProgress;
      default:
        return status;
    }
  }

  getServersStatusLabel(serverName: JpuDTO["serverName"]): string {
    if (!this.serversStatusLoaded || this.serversStatus === null) {
      return `${ServerStatusPrefix.Loading}...`;
    }

    if (!Object.keys(this.serversStatus).includes(serverName)) {
      return ServerStatusPrefix.Error;
    }

    const fetchedServer = this.serversStatus[serverName];

    if (fetchedServer.inProgress) {
      return ServerStatusPrefix.InProgress;
    }

    return ServerMetadataStatusPrefix.Active;
  }

  getWhitelistIpsLabel(jpu: JpusFormatedForTopology): string {
    const { serverName } = jpu;

    if (!this.serversIpMetaDataLoaded || this.serverIpMetaData === null) {
      return `${ServerMetadataStatusPrefix.Loading}...`;
    }
    if (!Object.keys(this.serverIpMetaData).includes(serverName)) {
      return ServerMetadataStatusPrefix.Error;
    }

    const whitelistIps = this.serverIpMetaData[serverName].whitelistIPMeta;
    if (whitelistIps.inProgress) {
      return ServerMetadataStatusPrefix.InProgress;
    }

    const nbIps = whitelistIps.ips.filter(ip => ip !== this.whiteMarkIp).length;
    return nbIps ? ServerMetadataStatusPrefix.Active : ServerMetadataStatusPrefix.NotActive;
  }

  getGeoIPRestrictionsStatusLabel(jpu: JpusFormatedForTopology): string {
    const { serverName } = jpu;

    if (!this.serversIpMetaDataLoaded || this.serverIpMetaData === null) {
      return `${ServerMetadataStatusPrefix.Loading}...`;
    }
    if (!Object.keys(this.serverIpMetaData).includes(serverName)) {
      return ServerMetadataStatusPrefix.Error;
    }
    const geoIPRestrictionData = this.serverIpMetaData[serverName].geoIPMeta;
    if (geoIPRestrictionData.inProgress) {
      return ServerMetadataStatusPrefix.InProgress;
    }

    const nbCountries = geoIPRestrictionData.geoIpRestrictionCountriesList.length;
    return nbCountries ? ServerMetadataStatusPrefix.Active : ServerMetadataStatusPrefix.NotActive;
  }

  getPrivateLinkStatusLabel(jpu: JpusFormatedForTopology): string {
    const { serverName } = jpu;

    if (jpu.status === "BLOCKED") {
      return privateEndpointStatus.NotActive.label;
    }

    if (!this.serversIpMetaDataLoaded || this.serverIpMetaData === null) {
      return `${privateEndpointStatus.Loading}...`;
    }

    if (!Object.keys(this.serverIpMetaData).includes(serverName)) {
      return privateEndpointStatus.Failed.label;
    }
    const privateLinkMeta = this.serverIpMetaData[serverName].privateLinkMeta;

    if (privateLinkMeta.endpoints.length === 0) {
      return privateEndpointStatus.Create.label;
    }

    const isConnected = privateLinkMeta.endpoints.some(endpoint =>
      privateEndpointStatus.Connected.statuses.includes(endpoint.status),
    );

    if (isConnected) {
      return privateEndpointStatus.Connected.label;
    }

    const isInProgress = privateLinkMeta.endpoints.some(endpoint =>
      privateEndpointStatus.InProgress.statuses.includes(endpoint.status),
    );

    if (isInProgress) {
      return privateEndpointStatus.InProgress.label;
    }

    return privateEndpointStatus.Failed.label;
  }

  isExcludedBySelectFilters(row: any, columnFiltersState: AnyObject) {
    for (let column in columnFiltersState) {
      if (columnFiltersState.hasOwnProperty(column)) {
        const rowValue = row[column].toLowerCase();
        const filterValue = columnFiltersState[column];
        if (filterValue) {
          if (Array.isArray(filterValue)) {
            if (filterValue.length) {
              const found = filterValue.map(v => v.toLowerCase()).find(v => rowValue.includes(v));
              if (!found) {
                return true;
              }
            }
          } else if (!rowValue.includes(filterValue.toLowerCase())) {
            return true;
          }
        }
      }
    }
    return false;
  }

  get filteredFormatedJPUs() {
    // in mobile we should implement the filter system by ourself (in larger screens, Essentials handle this filter system built-in).
    // So here we implement the essentials mechanism for mobile display.
    return (
      this.formatedJPUs &&
      this.formatedJPUs.filter(row => !this.isExcludedBySelectFilters(row, this.columnFiltersState))
    );
  }

  get filterableColumns() {
    return this.gridProps.columns.filter(
      (c: TopologyGridColumns) => c.selectFilter === undefined || c.selectFilter !== "off",
    );
  }

  isColumnFilterSelectMultiple(column: TopologyGridColumns) {
    return column.selectFilter && column.selectFilter === "multiple";
  }

  generateMobileSelectOptions(column: TopologyGridColumns) {
    // for mobile view we should implement the select options logic manually (for no mobile views the select options logic is implemented in the Grid system of Essentials Table):
    // if the column config contains custom options : we take theses options.
    if (column.selectFilterOptions) {
      return column.selectFilterOptions;
    }
    if (!this.formatedJPUs) {
      return [];
    }
    // if no, the options of the select will be a distinct Set of all available values in the customer data (default behavior of the Grid Select Options in Essentials)
    const options = new Set();
    // @ts-ignore
    this.formatedJPUs.forEach((jpu: JpusFormatedForTopology) => options.add(jpu[column.field]));
    return Array.from(options);
  }

  retrieveProductNames() {
    const products = new Set<string>();
    this.subscription.jpus &&
      this.subscription.jpus.map(jpu =>
        jpu.products.map(product => {
          products.add(this.$utils.capitalize(this.formatProductNames(product.productName)));
        }),
      );
    return Array.from(products);
  }

  formatProductNames(product: string) {
    if (product === "jfmc") {
      return "Mission Control";
    }
    if (product === "edge") {
      return "Artifactory";
    }
    return product;
  }

  async fetchServersIpMetaData() {
    if (!this.subscription.jpus) {
      return;
    }
    try {
      this.serversIpMetaDataLoaded = false;
      this.serverIpMetaData = await this.$jfSubscriptions.getServersIpMetaData(this.subscription.accountNumber, {
        technicalServerNames: this.subscription.jpus.map(jpu => jpu.serverName),
      });
      this.serversIpMetaDataLoaded = true;
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_server_ip_meta_data_fetch_error);
      this.serverIpMetaData = {};
      this.serversIpMetaDataLoaded = true;
      this.reRenderAllActionDropdown();
    }
  }

  async fetchServersStatus() {
    if (!this.subscription.jpus) {
      return;
    }
    const activeServerNames = this.subscription.jpus.filter(jpu => jpu.status === "ACTIVE").map(jpu => jpu.serverName);
    if (activeServerNames.length === 0) {
      this.serversStatusLoaded = true;
    }
    try {
      this.serversStatus = await this.$jfSubscriptions.getServersStatus(this.subscription.accountNumber, {
        technicalServerNames: activeServerNames,
      });
      this.serversStatusLoaded = true;
    } catch (e) {
      this.$log.error(e);
      this.notifyError(this.$jfMessages.subscriptions_servers_status_fetch_error);
      this.serversStatus = {};
      this.serversStatusLoaded = true;
      this.reRenderStatusCells();
    }
  }

  updateActionDropdown(serverName: string) {
    // sending events to the grid to re render dropdown cells
    this.gridEventBus.$emit("refreshPopupCells", "serverName", serverName);
  }

  updateCell(targetColumnField: string, targetColumnFieldValue: string, rowKey: string, rowKeyValue: string) {
    this.gridEventBus.$emit("onUpdateCell", targetColumnField, targetColumnFieldValue, rowKey, rowKeyValue);
  }

  reRenderStatusCells() {
    if (this.serversStatus === null) {
      return;
    }
    Object.keys(this.serversStatus).forEach(serverName => {
      this.updateCell("Status", this.getServersStatusLabel(serverName), "serverName", serverName);
    });
  }

  reRenderActionDropdown() {
    if (this.serverIpMetaData === null) {
      return;
    }
    Object.keys(this.serverIpMetaData).forEach(serverName => {
      this.updateActionDropdown(serverName);
    });
  }

  reRenderAllActionDropdown() {
    this.subscription.jpus && this.subscription.jpus.forEach(jpu => this.updateActionDropdown(jpu.serverName));
  }

  async fetchRegionsList() {
    try {
      this.regionsList = await this.$jfSubscriptions.getRegionsList();
    } catch (error) {
      this.$log.error(error);
      this.$jfNotification.error({ text: this.$jfMessages.subscription_regions_list_fetch_failed });
    }
  }

  mounted() {
    this.fetchServersIpMetaData();
    this.fetchServersStatus();
    this.fetchRegionsList();
    this.globalBus.$on("onRefreshTopologyData", () => this.fetchServersIpMetaData());
  }

  destroyed() {
    this.globalBus.$off("onRefreshTopologyData");
  }

  async handleChangeIPListing(jpu: JpusFormatedForTopology) {
    let serverName = jpu.serverName;
    if (
      !this.serverIpMetaData ||
      !this.serverIpMetaData[serverName] ||
      this.isServermetadataActionDisabled(jpu, this.getWhitelistIpsLabel(jpu))
    ) {
      return;
    }
    const currentWhiteListIps = this.serverIpMetaData[serverName].whitelistIPMeta;
    this.$jfModal.showCustom(
      ModalIPListing,
      "md",
      {
        subscription: this.subscription,
        ips: currentWhiteListIps.ips,
        ipInProgress: currentWhiteListIps.inProgress,
        serverInProgress: !this.serverIsReallyActive(jpu),
        isUnderLevelEnterprise: false,
        onApprove: async (updatedIps: string[]) => {
          try {
            if (this.ipsToSaveAreNew(currentWhiteListIps.ips, updatedIps)) {
              let whiteListIPSResponse = await this.$jfSubscriptions.updateWhiteListIPs(
                this.subscription.accountNumber,
                serverName,
                {
                  ips: updatedIps,
                },
              );

              (this.serverIpMetaData as ReadServerIPMetaResponse)[serverName].whitelistIPMeta = whiteListIPSResponse;

              // forcing serverStatus to 'inProgress'
              (this.serversStatus as ReadServerStatusResponse)[serverName].inProgress = true;

              this.updateActionDropdown(serverName);
              this.$jfNotification.success({
                duration: 1000,
                text: this.$jfMessages.subscriptions_whitelist_ips_set_success,
              });
            }
            return true;
          } catch (error) {
            this.$log.error(error);
            this.notifyError(this.$jfMessages.subscriptions_whitelist_ips_set_error);
            return false;
          }
        },
      },
      {
        title: "IP/CIDR Whitelisting",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  handleGeoRestrictionModal(jpu: JpusFormatedForTopology): void {
    let serverName = jpu.serverName;
    if (!this.serverIpMetaData || !this.serverIpMetaData[serverName]) {
      return;
    }
    const currentGeoIpCountriesData = this.serverIpMetaData[serverName].geoIPMeta;
    const geoIPRestrictionsStatusLabel = this.getGeoIPRestrictionsStatusLabel(jpu);
    this.$jfModal.showCustom(
      ModalGeoRestriction,
      "lg",
      {
        fetchedGeoRestrictionCountries: currentGeoIpCountriesData.geoIpRestrictionCountriesList,
        restrictionType: currentGeoIpCountriesData.geoIpRestrictionType,
        geoIpInProgress: currentGeoIpCountriesData.inProgress,
        serverInProgress: !this.serverIsReallyActive(jpu),
        statusLabel: geoIPRestrictionsStatusLabel,
        statusClasses: this.getServerMetadataSubLabelClasses(geoIPRestrictionsStatusLabel),
        allCountries: allCountries,
        onApprove: async (request: UpdateGeoIPCountriesRequest) => {
          try {
            await this.$jfLoaders.showGlobal();
            let updatedGeIPCountriesResponse = await this.$jfSubscriptions.updateGeoIPCountries(
              this.subscription.accountNumber,
              serverName,
              request,
            );
            (this.serverIpMetaData as ReadServerIPMetaResponse)[serverName].geoIPMeta = updatedGeIPCountriesResponse;

            // forcing serverStatus to 'inProgress'
            (this.serversStatus as ReadServerStatusResponse)[serverName].inProgress = true;

            this.updateActionDropdown(serverName);
            await this.$jfLoaders.hideGlobal();
            this.$jfNotification.success({
              duration: 1000,
              text: this.$jfMessages.subscriptions_Geo_Restriction_set_success,
            });
            this.$jfWebeyez.send({
              goal_key: "geo_restrictions",
              isSuccess: true,
            });
            return true;
          } catch (error) {
            await this.$jfLoaders.hideGlobal();
            this.$log.error(error);
            this.notifyError(this.$jfMessages.subscriptions_Geo_Restriction_set_error);
            this.$jfWebeyez.send({
              goal_key: "geo_restrictions",
              isSuccess: false,
              errorMessage: this.$jfMessages.subscriptions_Geo_Restriction_set_error,
            });
            return false;
          }
        },
      },
      {
        title: "Geolocation Restrictions",
        // forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
        submitOnEnterPress: false,
      },
    );
  }

  serverIsNotYetDeployed(jpu: JpusFormatedForTopology) {
    return jpu.status !== "ACTIVE" || this.serverNamesNotYetDeployed.includes(jpu.serverName);
  }

  goToArtifactory(jpu: JpusFormatedForTopology) {
    if (this.serverIsNotYetDeployed(jpu)) {
      return;
    }
    this.goToServer(jpu.serverName);
  }

  goToServer(serverName: string) {
    let url: string | null = `https://${serverName}.${PLATFORM_DOMAIN}/`;
    if (url) {
      window.open(url, "_blank");
    } else {
      this.$log.error(`URL not defined for product Artifactory`);
    }
  }

  getclickableGridElementClasses(jpu: JpusFormatedForTopology) {
    if (this.serverIsNotYetDeployed(jpu)) {
      return [];
    }
    return ["clickable", "green-link"];
  }

  get newInstancesNotification(): TopologyNotification {
    return {
      displayed: this.canConfigureSomeServers,
      disabled: !this.canConfigureNewInstance,
      message: `You have ${this.totalServersToConfigure} instance${
        this.totalServersToConfigure > 1 ? "s" : ""
      } ready to be configured.`,
      btnText: "Configure Now",
      subMessage: this.notificationSubmessage,
      wrapperClasses: this.notificationExtraClasses,
      onBtnClick: () => {
        this.$jfModal.showCustom(
          ModalConfigureEPlusIntances,
          "xxl",
          {
            subscription: this.subscription,
            nbInstancesConfigurable: this.nbInstancesToConfigure,
            nbCloudEdgesConfigurable: this.nbEdgesToConfigure,
            regions: this.regionsList,
            onApprove: async (serversConfigList: EnterprisePlusAddServersRequest["serversConfigList"]) => {
              await this.$jfLoaders.showGlobal();

              try {
                const updatedSubscription = await this.$jfSubscriptions.addServersToTopology({
                  accountNumber: this.subscription.accountNumber.toString(),
                  serversConfigList,
                });

                // forcing all serverStatus to inProgress except hybrid
                if (this.serversStatus && this.subscription.paymentType !== "ENTERPRISE_PLUS_HYBRID") {
                  Object.keys(this.serversStatus).forEach(key => {
                    (this.serversStatus as ReadServerStatusResponse)[key].inProgress = true;
                  });
                }

                // tagging new servers as not yet deployed and adding default ips/geoip
                serversConfigList.forEach(serverConfig => {
                  const newServerName = serverConfig.serverName;
                  this.serverNamesNotYetDeployed.push(newServerName);

                  (this.serverIpMetaData as ReadServerIPMetaResponse)[newServerName] = {
                    whitelistIPMeta: {
                      inProgress: false,
                      ips: [],
                    },
                    geoIPMeta: {
                      inProgress: false,
                      geoIpRestrictionType: "deny",
                      geoIpRestrictionCountriesList: [],
                    },
                    privateLinkMeta: {
                      endpoints: [],
                    },
                  };
                });

                await this.$jfLoaders.hideGlobal();

                this.globalBus.$emit("subscriptionUpdated", updatedSubscription);

                setTimeout(() => {
                  this.globalBus.$emit("shouldRerenderGraphs");
                  this.gridEventBus.$emit("reloadData");
                }, 0);
                this.$jfWebeyez.send({
                  type: "goal",
                  goal_key: "configure_additional_instances_and_edge",
                  isSuccess: true,
                });

                return true;
              } catch (error) {
                this.$log.error(error);
                await this.$jfLoaders.hideGlobal();

                this.notifyError(this.$jfMessages.subscriptions_eplus_instances_configuration_unexpected_error);
                this.$jfWebeyez.send({
                  goal_key: "configure_additional_instances_and_edge",
                  isSuccess: false,
                  errorMessage: this.$jfMessages.subscriptions_eplus_instances_configuration_unexpected_error,
                });
                return false;
              }
            },
          },
          {
            title: "Configure New Instances",
            headerBorder: false,
            footerBorder: false,
            clickShouldClose: false,
            shakeIfCantClose: false,
            headerIcons: [
              {
                wrapperClasses: ["fx"],
                iconClasses: ["icon-art-notif-icon", "fx", "pointer"],
                bottomOffset: -10,
                leftOffset: 10,
                tooltip: {
                  content: `Need more <a href="https://www.jfrog.com/confluence/display/JFROG/MyJFrog+Cloud+Portal" target="_blank">information</a>?`,
                  html: true,
                  trigger: "click",
                  placement: "bottom-end",
                  classes: ["tooltip_light", "modal-tooltip"],
                },
              },
            ],
          },
        );
      },
    };
  }
}
