
import LoadingMask from "@/components/common/LoadingMask.vue";
import EplusInstanceConfigItem from "@/components/views/subscriptions/ePlus/modals/eplusInstancesConfiguration/EplusConfigServerItem.vue";
import { JFEssentialsConfirmModalOptions } from "@/types/3rdPartyLibs";
import { LoadingMaskProps } from "@/types/loadingMask";
import { Region } from "@/types/localtypes";
import { EnterprisePlusAddServersRequest, JpuDTO, ServerRequestModel } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { FlatSubscription } from "@/types/localtypes";

type EPlusConfigItemState = {
  itemKey: EPlusConfigServerItemProps["itemKey"];
  itemRef: EPlusConfigServerItemProps["itemRef"];
  active: boolean;
  ready: boolean;
  type: EPlusConfigServerItemProps["type"];
  displayApplyAll: EPlusConfigServerItemProps["displayApplyAll"];
  serverModel: ServerRequestModel;
};

type ConfigServerItemType = "instance" | "edge";
export interface EPlusConfigServerItemProps {
  itemEventBus: Vue;
  itemKey: string;
  itemRef: string;
  type: ConfigServerItemType;
  index: number;
  initialActiveState: boolean;
  displayApplyAll: boolean;
  regions: Region[];
  onActiveChange: (itemKey: string, active: boolean) => void;
  onReadyChange: (itemKey: string, ready: boolean) => void;
  enforcedProvider: JpuDTO["cloudProvider"] | null;
}

@Component({
  name: "ModalConfigureEPlusIntances",
  components: { LoadingMask, EplusInstanceConfigItem },
})
export default class ModalConfigureEPlusIntances extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Prop() private subscription!: FlatSubscription;
  @Prop()
  private onApprove!: (serversConfigList: EnterprisePlusAddServersRequest["serversConfigList"]) => Promise<boolean>;
  @Prop() private nbInstancesConfigurable!: number;
  @Prop() private nbCloudEdgesConfigurable!: number;
  @Prop() private regions!: Region[];

  currentStep = 1;
  itemStates: EPlusConfigItemState[] = [];
  itemsActivatedByDefault = true;
  itemEventBus: Vue = new Vue();

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
    this.initItemStates();
    this.focusFirstField();
  }

  focusFirstField() {
    const firstItemRef = this.itemStates[0].itemRef;
    // @ts-ignore
    this.$refs[firstItemRef][0].putFocusOnFirstField();
  }

  get displayingConfirmation() {
    return this.currentStep === 2;
  }

  get successMessage() {
    return this.$jfMessages.subscriptions_eplus_instances_configuration_success;
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({
      goal_key: "configure_additional_instances_and_edge",
      isSuccess: false,
      errorMessage: errorMessage,
    });
  }

  initItemStates() {
    const allItems = [...this.instanceItemsProps, ...this.cloudEdgesItemsProps];
    allItems.forEach(itemProps => {
      this.itemStates.push({
        active: this.itemsActivatedByDefault,
        ready: false,
        itemKey: itemProps.itemKey,
        itemRef: itemProps.itemRef,
        type: itemProps.type,
        displayApplyAll: itemProps.displayApplyAll,
        serverModel: {
          serverName: "",
          regionCode: "",
          products: [],
          initialPassword: "",
        },
      });
    });
  }

  findItemState(itemKey: EPlusConfigItemState["itemKey"]) {
    const itemState = this.itemStates.find(itemState => itemState.itemKey === itemKey);
    if (!itemState) {
      this.$log.error(`unable to find itemState with key '${itemKey}'`);
      this.notifyError(this.$jfMessages.subscriptions_eplus_instances_configuration_state_management_error);
      return;
    }
    return itemState;
  }

  get hasInstancesToConfigure() {
    return this.nbInstancesConfigurable > 0;
  }

  get hasEdgesToConfigure() {
    return this.nbCloudEdgesConfigurable > 0;
  }

  get enableApplyToAllFeature() {
    return this.nbInstancesConfigurable + this.nbCloudEdgesConfigurable > 1;
  }

  get enforcedProvider(): JpuDTO["cloudProvider"] | null {
    const meta = this.subscription.meta;
    if (!meta.isMP) {
      return null;
    }
    if (meta.isAWSMP) {
      return "amazon";
    }
    if (meta.isAzureMP) {
      return "azure";
    }
    if (meta.isGCPMP) {
      return "google";
    }
    return null;
  }

  get instanceItemsProps(): EPlusConfigServerItemProps[] {
    const items: EPlusConfigServerItemProps[] = [];
    for (let i = 0; i < this.nbInstancesConfigurable; i++) {
      const type: EPlusConfigServerItemProps["type"] = "instance";
      const itemKey = `${type}${i}`;
      const itemRef = `config_ref_${itemKey}`;
      const displayApplyAll = this.enableApplyToAllFeature && i === 0;
      items.push({
        itemEventBus: this.itemEventBus,
        itemKey,
        itemRef,
        type,
        index: i + 1,
        initialActiveState: this.itemsActivatedByDefault,
        displayApplyAll,
        regions: this.regions,
        onActiveChange: this.handleItemActiveChange,
        onReadyChange: this.handleItemReadyChange,
        enforcedProvider: this.enforcedProvider,
      });
    }

    return items;
  }

  get cloudEdgesItemsProps(): EPlusConfigServerItemProps[] {
    const items: EPlusConfigServerItemProps[] = [];
    for (let i = 0; i < this.nbCloudEdgesConfigurable; i++) {
      const type: EPlusConfigServerItemProps["type"] = "edge";
      const itemKey = `${type}${i}`;
      const itemRef = `config_ref_${itemKey}`;
      const displayApplyAll = this.enableApplyToAllFeature && !this.hasInstancesToConfigure && i === 0;
      items.push({
        itemEventBus: this.itemEventBus,
        itemKey,
        itemRef,
        type,
        index: i + 1,
        initialActiveState: this.itemsActivatedByDefault,
        displayApplyAll,
        regions: this.regions,
        onActiveChange: this.handleItemActiveChange,
        onReadyChange: this.handleItemReadyChange,
        enforcedProvider: this.enforcedProvider,
      });
    }

    return items;
  }

  handleItemActiveChange(itemKey: string, active: boolean) {
    const itemState = this.findItemState(itemKey);
    if (itemState) {
      itemState.active = active;
    }
  }
  handleItemReadyChange(itemKey: string, ready: boolean) {
    const itemState = this.findItemState(itemKey);
    if (itemState) {
      itemState.ready = ready;
    }
  }

  get activeItems() {
    return this.itemStates.filter(itemState => itemState.active);
  }

  get nbActiveItems() {
    return this.activeItems.length;
  }

  get atLeastOneItemIsActive() {
    return !!this.nbActiveItems;
  }

  get allActiveItemsAreReady() {
    return this.activeItems.find(activeItem => !activeItem.ready) === undefined;
  }

  get canSendForm() {
    return (
      this.displayingConfirmation || (!this.isLoading && this.atLeastOneItemIsActive && this.allActiveItemsAreReady)
    );
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading(): boolean {
    return false;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get confirmBtnText() {
    return this.displayingConfirmation ? "Got it !" : "Add Instance(s)";
  }

  confirm() {
    if (this.canSendForm) {
      if (this.displayingConfirmation) {
        this.confirmStep2();
        return;
      }
      this.confirmStep1();
    }
  }

  collectServerConfigs() {
    // collecting server configs from each child
    this.itemStates.forEach(itemState => {
      // @ts-ignore
      itemState.serverModel = this.$refs[itemState.itemRef][0].generateServerRequestModel() as ServerRequestModel;
    });
  }

  get activeServersConfigList(): EnterprisePlusAddServersRequest["serversConfigList"] {
    return this.activeItems.map(activeItem => activeItem.serverModel);
  }

  hasDistinctServerNames() {
    const nbActiveConfigs = this.activeServersConfigList.length;
    const distinctServernames = new Set(this.activeServersConfigList.map(activeConfig => activeConfig.serverName));

    return nbActiveConfigs === distinctServernames.size;
  }

  async confirmStep1() {
    this.collectServerConfigs();

    if (!this.hasDistinctServerNames()) {
      this.notifyError(this.$jfMessages.registration_servername_validation_error_servernames_not_distinct);
      return;
    }

    const canDisplaySuccess = await this.onApprove(this.activeServersConfigList);
    if (canDisplaySuccess) {
      this.currentStep = 2;
      this.$emit("onResize", "md");
      this.$emit("onTitleChanged", "New instances configured successfully");
      this.$emit("onHeaderIconsChanged", [] as JFEssentialsConfirmModalOptions);
    }
  }

  confirmStep2() {
    this.$jfModal.close();
  }
}
