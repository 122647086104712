export default {
  lineOptions: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    elements: {
      line: { tension: 0.1 },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          ticks: {
            beginAtZero: true,
            userCallback: function(tick: any): any {
              return "";
            },
          },
          gridLines: {
            display: true,
            borderDash: [10, 10],
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: false,
            labelString: "Day",
          },
          gridLines: {
            display: true,
            borderDash: [10, 10],
          },
        },
      ],
    },
  },
  barOptions: {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      yAxes: [
        {
          type: "linear",
          ticks: {
            beginAtZero: true,
            userCallback: function(tick: any): any {
              return "";
            },
          },
          gridLines: {
            display: true,
            borderDash: [10, 10],
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: false,
            labelString: "Day",
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  },
  PipelinesLineOptions: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    elements: {
      line: { tension: 0.1 },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          ticks: {
            beginAtZero: true,
            userCallback: function(tick: any): any {
              return tick.toString() + "Min";
            },
          },
          gridLines: {
            display: true,
            borderDash: [10, 10],
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: false,
            labelString: "Day",
          },
          gridLines: {
            display: true,
            borderDash: [10, 10],
          },
        },
      ],
    },
  },
};
