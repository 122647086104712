
import { DraggableItem } from "@/types/localtypes";
import { JFTextField } from "jfrog-ui-vue-essentials";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";

interface DragEvent {
  added?: { newIndex: number; element: DraggableItem };
  removed?: { oldIndex: number; element: DraggableItem };
  moved?: { newIndex: number; oldIndex: number; element: DraggableItem };
}

@Component({
  name: "DraggableSelect",
  components: {
    draggable,
    JFTextField,
  },
})
export default class DraggableSelect extends Vue {
  @Prop() private disabled!: boolean;
  @Prop() private items!: DraggableItem[];
  @Prop() private itemLabel!: string;
  @Prop() private itemLabelPlural!: string;
  @Watch("localData")
  onLocalDataChanged() {
    this.$emit("onItemsUpdated", this.localData);
    this.clearFilters();
  }
  localData: DraggableItem[] = this.items;
  unselectedFilterValue: string = "";
  selectedFilterValue: string = "";
  selectedWrapperClass: string = "selected-wrapper";
  unSelectedWrapperClass: string = "unselected-wrapper";

  get sectionTitleClasses() {
    const classes = [];
    if (this.disabled) {
      classes.push("disabled");
    }
    return classes;
  }
  get selectedData() {
    return this.localData.filter(localData => localData.selected);
  }

  get unselectedData() {
    return this.localData.filter(localData => !localData.selected);
  }

  get unselectedDataAndNoneIsChecked() {
    return this.unselectedData.filter(item => item.checked).length === 0;
  }

  get selectedDataAndNoneIsChecked() {
    return this.selectedData.filter(item => item.checked).length === 0;
  }

  get unselectedUnfilteredData() {
    return this.unselectedData
      .filter(d => !this.isDataFilteredFromUnselectedList(d))
      .sort((a, b) => this.$jfUtil.sortStringDesc(a.label, b.label));
  }

  get selectedUnfilteredData() {
    return this.selectedData
      .filter(d => !this.isDataFilteredFromSelectedList(d))
      .sort((a, b) => this.$jfUtil.sortStringDesc(a.label, b.label));
  }

  get isEmptyUnselectedData() {
    return this.unselectedData.length === 0;
  }

  get isEmptySelectedData() {
    return this.selectedData.length === 0;
  }

  get getUnselectedWrapperClass() {
    return this.unSelectedWrapperClass;
  }

  get getSelectedWrapperClass() {
    return this.selectedWrapperClass;
  }

  get unselectedFilteredDataAndAllAreChecked() {
    return this.unselectedUnfilteredData.filter(item => item.checked).length === this.unselectedUnfilteredData.length;
  }

  get unselectedFilteredDataAndAtLeastOneIsChecked() {
    return this.unselectedUnfilteredData.filter(item => item.checked).length >= 1;
  }

  get selectedFilteredDataAndAllAreChecked() {
    return this.selectedUnfilteredData.filter(item => item.checked).length === this.selectedUnfilteredData.length;
  }

  get selectedFilteredDataAndAtLeastOneIsChecked() {
    return this.selectedUnfilteredData.filter(item => item.checked).length >= 1;
  }

  toggleCheckAllUnselected(): void {
    if (this.disabled) {
      return;
    }
    if (this.unselectedFilteredDataAndAllAreChecked) {
      this.unselectedUnfilteredData.map(item => (item.checked = false));
    } else {
      this.unselectedUnfilteredData.map(item => (item.checked = true));
    }
  }

  toggleCheckAllSelected(): void {
    if (this.disabled) {
      return;
    }
    if (this.selectedFilteredDataAndAllAreChecked) {
      this.selectedUnfilteredData.map(item => (item.checked = false));
    } else {
      this.selectedUnfilteredData.map(item => (item.checked = true));
    }
  }

  isDataFiltered(data: DraggableItem, filterValue: string) {
    return !data.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
  }

  isDataFilteredFromUnselectedList(data: DraggableItem) {
    return this.isDataFiltered(data, this.unselectedFilterValue);
  }

  isDataFilteredFromSelectedList(data: DraggableItem) {
    return this.isDataFiltered(data, this.selectedFilterValue);
  }

  selectAll() {
    if (this.disabled) {
      return;
    }
    this.localData = this.localData.map(d => {
      if (!this.isDataFilteredFromUnselectedList(d)) {
        return { ...d, selected: true, checked: false };
      }
      return d;
    });
  }

  unselectAll() {
    if (this.disabled) {
      return;
    }
    this.localData = this.localData.map(d => {
      if (!this.isDataFilteredFromSelectedList(d)) {
        return { ...d, selected: false, checked: false };
      }
      return d;
    });
  }

  moveCheckedToSelected() {
    if (this.disabled) {
      return;
    }
    this.localData = this.localData.map(d => {
      if (!this.isDataFilteredFromUnselectedList(d) && d.checked && !d.selected) {
        d.selected = true;
        d.checked = false;
      }
      return d;
    });
  }

  moveCheckedToUnselected() {
    if (this.disabled) {
      return;
    }
    this.localData = this.localData.map(d => {
      if (!this.isDataFilteredFromSelectedList(d) && d.checked && d.selected) {
        d.selected = false;
        d.checked = false;
      }
      return d;
    });
  }

  toggleItemCheckedProp(e: DraggableItem) {
    if (this.disabled) {
      return;
    }
    e.checked = !e.checked;
  }

  getItemCheckClass(element: DraggableItem) {
    const classes = [];
    if (element.checked) {
      classes.push("checked");
    }
    return classes;
  }

  get unselectedItemWrapperClasses() {
    const classes = [];
    if (this.disabled) {
      classes.push("disabled");
    }
    return classes;
  }
  get selectedItemWrapperClasses() {
    const classes = [];
    if (this.disabled) {
      classes.push("disabled");
    }
    return classes;
  }

  getIconToLeftClasses(type: string) {
    const classes = [];
    if (
      this.disabled ||
      (type === "all" && this.isEmptyUnselectedData) ||
      (type === "single" && this.unselectedDataAndNoneIsChecked)
    ) {
      classes.push("disabled");
    }
    return classes;
  }

  getIconToRightClasses(type: string) {
    const classes = [];
    if (
      this.disabled ||
      (type === "all" && this.isEmptySelectedData) ||
      (type === "single" && this.selectedDataAndNoneIsChecked)
    ) {
      classes.push("disabled");
    }
    return classes;
  }

  get getCheckAllUnselectedItemsClass() {
    const classes = [];
    if (this.unselectedFilteredDataAndAtLeastOneIsChecked) {
      classes.push("checked");
    }
    if (this.disabled) {
      classes.push("disabled");
    }
    return classes;
  }
  get getCheckAllSelectedItemsClass() {
    const classes = [];
    if (this.selectedFilteredDataAndAtLeastOneIsChecked) {
      classes.push("checked");
    }
    if (this.disabled) {
      classes.push("disabled");
    }
    return classes;
  }

  handleDropEvent(evt: DragEvent, shouldSelect: boolean) {
    if (evt.added) {
      const droppedItem = evt.added.element;
      this.localData = this.localData.map(ld => {
        const isDroppedItem = ld.tag === droppedItem.tag;
        const otherItemIsFiltered = shouldSelect
          ? this.isDataFilteredFromUnselectedList(ld)
          : this.isDataFilteredFromSelectedList(ld);
        const isOtherItemsWhichShouldBeDragged =
          droppedItem.checked && ld.checked && ld.selected !== shouldSelect && !otherItemIsFiltered;
        if (isDroppedItem || isOtherItemsWhichShouldBeDragged) {
          return { ...ld, selected: shouldSelect, checked: false };
        }
        return ld;
      });
    }
    return;
  }

  clearFilters() {
    if (this.unselectedData.length === 0) {
      this.unselectedFilterValue = "";
    } else if (this.selectedData.length === 0) {
      this.selectedFilterValue = "";
    }
  }

  // THE BELOW FUNCTION IS USE TO NOT ALLOW ITEM TO BE DRAG INTO ITS OWN LIST
  cancelDrag(evt: any) {
    if (
      !(evt.to as HTMLDivElement).classList.contains(this.unSelectedWrapperClass) &&
      !(evt.to as HTMLDivElement).classList.contains(this.selectedWrapperClass)
    ) {
      this.$log.warn("Some css classes are missing...");
      return false;
    }
    if (
      !(evt.from as HTMLDivElement).classList.contains(this.unSelectedWrapperClass) &&
      !(evt.from as HTMLDivElement).classList.contains(this.selectedWrapperClass)
    ) {
      this.$log.warn("Some css classes are missing...");
      return false;
    }
    return (evt.to as HTMLDivElement).className !== (evt.from as HTMLDivElement).className;
  }
}
