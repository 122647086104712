
import { MultiSelectHeaderEmitSelectTag, MultiSelectOption, MultiSelectProps } from "@/types/localtypes/multiSelect";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "VMultiSelect",
})
export default class VMultiSelect extends Vue {
  @Prop({ default: "All Selected" })
  private placeholder!: MultiSelectProps["placeholder"];
  @Prop({ default: "All Selected" })
  private allSelectedLabel!: MultiSelectProps["allSelectedLabel"];
  @Prop({ default: () => () => true })
  private selectable!: MultiSelectProps["selectable"];
  @Prop() private selectName!: MultiSelectProps["selectName"];
  @Prop({ default: 2 })
  private maxOptionsDisplayed!: MultiSelectProps["maxOptionsDisplayed"];
  @Prop({ default: () => [] })
  private selectOptions!: MultiSelectProps["selectOptions"];
  @Prop({ default: () => [] })
  private initialSelectedOptions!: MultiSelectProps["initialSelectedOptions"];
  @Prop({ default: (label: string) => "" })
  private extensionCallback!: MultiSelectProps["extensionCallback"];
  @Prop({ default: false })
  private disabled!: MultiSelectProps["disabled"];
  @Prop() private eventBus!: Vue;

  selectedOptions: MultiSelectOption[] = [];

  @Watch("selectedOptions")
  onChangeSelected() {
    this.emitChangeSelectedEvent();
  }

  emitChangeSelectedEvent() {
    this.$emit("changeSelected", this.selectedOptions);
  }

  get isAllSelected() {
    return this.selectedOptions.length === this.selectOptionsLength;
  }

  get hasMultiOptions() {
    return this.selectOptionsLength > 1;
  }

  get selectOptionsLength() {
    return this.selectOptions.length;
  }
  isFirstOption(option: MultiSelectOption) {
    return this.selectedOptionIndex(option) === 0;
  }
  selectedOptionIndex(option: MultiSelectOption) {
    return this.selectedOptions.findIndex(selectedOption => selectedOption.code === option.code);
  }

  shouldDisplayOption(option: MultiSelectOption) {
    return this.selectedOptionIndex(option) < this.maxOptionsDisplayed;
  }

  shouldDisplayOtherSentence(option: MultiSelectOption) {
    return this.selectedOptionIndex(option) === this.maxOptionsDisplayed;
  }
  optionIsSelected(option: MultiSelectOption) {
    return this.selectedOptionIndex(option) !== -1;
  }
  deselectOption(option: MultiSelectOption) {
    if (this.selectable && !this.selectable(option)) {
      return;
    }
    this.selectedOptions = this.selectedOptions.filter(selectedOption => selectedOption.label !== option.label);
  }

  get optionOtherString() {
    const others = this.selectedOptions.length - this.maxOptionsDisplayed;
    return `(+${others} options)`;
  }

  selectAll() {
    this.forceClose();
    this.selectedOptions = this.selectOptions;
  }

  forceClose() {
    // @ts-ignore
    this.$refs.vSelectNative.open = false;
  }

  deSelectAll() {
    this.forceClose();
    this.selectedOptions = [];
  }

  busEvents: { [key: string]: (...params: any[]) => any } = {
    [MultiSelectHeaderEmitSelectTag.SELECT_ALL]: this.selectAll,
    [MultiSelectHeaderEmitSelectTag.DESELECT_ALL]: this.deSelectAll,
  };

  created() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$on(busEventsKey, this.busEvents[busEventsKey]);
    }
    this.selectedOptions = this.initialSelectedOptions || [];
  }
  beforeDestroy() {
    for (let busEventsKey in this.busEvents) {
      this.eventBus.$off(busEventsKey, this.busEvents[busEventsKey]);
    }
  }
}
