
import JFMultiSelect from "@/components/common/multiSelect/JFMultiSelect.vue";
import EndpointBanner from "@/components/views/subscriptions/modals/privateLink/gettingStarted/EndpointBanner.vue";
import { FlatSubscription } from "@/types/localtypes";
import { JFMultiSelectProps, MultiSelectOption } from "@/types/localtypes/multiSelect";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, JpuDTO, storeErrorsMapping, ManageEndpointRequest } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "ManageEndpoint",
  $_veeValidate: { validator: "new" },
  components: { JFMultiSelect, EndpointBanner, JFFormControl, JFTextField },
})
export default class ManageEndpoint extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Prop() private eventBus!: Vue;
  @Prop() private setLoading!: (isLoading: boolean) => undefined;
  @Prop() private setDisabled!: (isDisabled: boolean) => undefined;
  @Prop() private gotoInProgress!: () => undefined;
  @Prop() private gotoViewAllEndpoints!: () => undefined;
  @Prop() private currentCloudProvider!: ManageEndpointRequest["cloudProviderCode"] | any;

  endpointValue = "";
  endpointNameValue = "";
  selectedRegion = "";
  selectedInstances: MultiSelectOption[] = [];
  isManageEndpoint = false;

  endpointIdIsOnPendingValidation = false;
  endpointIdValidationTimeout: number | undefined = undefined;
  globalValidationDelay = 700;

  previousAddedServers: string[] = [];

  @Watch("selectedRegion")
  onSelectedRegion() {
    // reset instances only if region changed, changing regions only permitted in create new Endpoint.
    if (!this.isManageEndpoint) {
      this.selectedInstances.splice(0);
    }
  }

  @Watch("canSubmitForm")
  onCanSubmitFormChanged(isValid: boolean) {
    this.setDisabled(!isValid);
  }

  onEndpointIdKeyDown(e: any) {
    if (!e.key || ["shift", "capslock", "tab"].includes(e.key.toLowerCase())) {
      return;
    }

    this.endpointIdIsOnPendingValidation = true;

    clearTimeout(this.endpointIdValidationTimeout);
    this.endpointIdValidationTimeout = setTimeout(() => {
      this.endpointIdIsOnPendingValidation = false;
    }, this.globalValidationDelay + 50);

    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }

  get bannerTitle() {
    return this.isManageEndpoint ? "Editing your private connection" : "Before Getting Started";
  }

  get isServerSelectDisabled() {
    return !this.selectedRegion;
  }

  get bannerLink() {
    if (this.isAzureCloudProvider) return "https://www.jfrog.com/confluence/x/_IvQC";

    if (this.isGcpCloudProvider) return "https://www.jfrog.com/confluence/x/XgH5CQ";

    return "https://www.jfrog.com/confluence/x/EYwNBw";
  }

  get isFilled() {
    let isBaseFieldsFilled = !!this.endpointValue && this.selectedRegion && !!this.selectedInstances.length;
    return this.isAzureCloudProvider ? isBaseFieldsFilled && !!this.endpointNameValue : isBaseFieldsFilled;
  }

  get hasPendingValidation() {
    return this.endpointIdIsOnPendingValidation;
  }

  get inError() {
    return this.errors.any();
  }

  get selectedServerLabels() {
    return this.selectedInstances.map(server => server.label);
  }

  get serversDidNotChanged() {
    return (
      this.isManageEndpoint &&
      this.selectedServerLabels.length === this.previousAddedServers.length &&
      this.selectedServerLabels.every(server => this.previousAddedServers.includes(server))
    );
  }

  get canSubmitForm() {
    return this.isFilled && !this.hasPendingValidation && !this.inError && !this.serversDidNotChanged;
  }

  get isDisabled() {
    return this.isManageEndpoint;
  }

  get regions() {
    return [
      ...new Set(
        this.subscription.jpus
          .filter(jpu => jpu.cloudProvider === this.cloudProviderMapper(this.currentCloudProvider))
          .map(jpu => jpu.region),
      ),
    ];
  }

  get instances() {
    if (!this.selectedRegion) {
      return [];
    }

    return this.subscription.jpus
      .filter(jpu => jpu.region === this.selectedRegion)
      .filter(jpu => jpu.cloudProvider == this.cloudProviderMapper(this.currentCloudProvider))
      .map((jpu, id) => this.optionsCreator(id, jpu.serverName));
  }

  optionsCreator(index: number, serverName: string): MultiSelectOption {
    return { code: "" + index, label: serverName };
  }

  infoIcon = {
    iconClass: "icon-info",
    tooltipText:
      "Find this value on the AWS VPC Dashboard in your AWS account. Verify that the endpoint ID you enter is an alphanumeric lowercase string that begins with 'vpce-'",
  };

  get InstancesMultiSelectProps(): JFMultiSelectProps {
    return {
      selectLabel: "Select Instances:",
      headerProps: {
        disabled: !this.selectedRegion,
        selectAllActive: this.selectedInstances.length < this.instances.length,
        deSelectAllActive: this.isManageEndpoint ? false : this.selectedInstances.length > 0,
      },
      multiSelectProps: {
        selectable: option => this.isSelectableSubscription(option),
        disabled: !this.selectedRegion,
        placeholder: "Select Instances...",
        maxOptionsDisplayed: 2,
        selectName: "countries",
        selectOptions: this.instances,
        initialSelectedOptions: this.selectedInstances,
        extensionCallback: this.calcMultiSelectServerItemExtension,
      },
    };
  }

  isSelectableSubscription(option: MultiSelectOption) {
    if (!this.isManageEndpoint) {
      return true;
    }

    const isOnlyOneChoice = this.selectedInstances.length <= 1;
    const selectedInstance = this.selectedInstances.some(instance => instance.label === option.label);

    // one instance should always be selected.
    return !(isOnlyOneChoice && selectedInstance);
  }

  calcMultiSelectServerItemExtension(serverName: string) {
    if (!this.subscription.jpus) {
      return "";
    }
    const subscription = this.subscription.jpus.find(jpu => jpu.serverName === serverName);
    if (!subscription) {
      return "";
    }

    return subscription.products.filter(product => product.productName.includes("artifactory")).length === 1
      ? " (Artifactory)"
      : " (Edge)";
  }

  onInstancesSelected(instances: MultiSelectOption[]) {
    this.selectedInstances = instances;
  }

  populateEndpoint(row: any) {
    this.selectedRegion = row.region;
    if (row.cloudProvider === "AZURE" || row.cloudProvider === "azure") {
      const endPointIdSplit = row.id.split(".");
      this.endpointValue = endPointIdSplit[1];
      this.endpointNameValue = endPointIdSplit[0];
    } else {
      this.endpointValue = row.id;
    }
    const multiSelectOptions = this.instances.filter(instance => row.serverNames.includes(instance.label));
    this.selectedInstances.push(...multiSelectOptions);

    // we save servers that was added;
    this.previousAddedServers = this.selectedInstances.map(instance => instance.label);
    this.isManageEndpoint = true;
  }

  get calcServerToAdd(): string[] {
    if (!this.previousAddedServers.length) {
      return this.selectedInstances.map(instance => instance.label);
    }

    return this.selectedInstances
      .filter(selectedInstances => !this.previousAddedServers.includes(selectedInstances.label))
      .map(selected => selected.label);
  }

  get calcServerToRemove(): string[] {
    if (!this.previousAddedServers.length) {
      return [];
    }

    return this.previousAddedServers.filter(
      server => !this.selectedInstances.some(selectedInstance => selectedInstance.label === server),
    );
  }

  async manageEndpoint() {
    try {
      this.setLoading(true);
      await this.$jfSubscriptions.manageEndpoint(this.subscription.accountNumber, {
        id: this.endpointValue,
        endpointName: this.endpointNameValue,
        action: this.isManageEndpoint ? "UPDATE" : "NEW",
        serversToAdd: this.calcServerToAdd,
        serversToRemove: this.calcServerToRemove,
        cloudProviderCode: this.currentCloudProvider,
      });
      this.setLoading(false);

      this.isManageEndpoint ? this.gotoViewAllEndpoints() : this.gotoInProgress();
    } catch (e) {
      this.setLoading(false);
      let errorMessage = this.$jfMessages.subscriptions_narcissus_task_in_progress;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.privateLink.duplicateError) {
          errorMessage = this.$jfMessages.subscriptions_private_link_duplicate_error;
        }
      }
      this.$jfNotification.error({
        text: errorMessage,
      });
      this.$log.error(e);
      this.$jfWebeyez.send({ goal_key: "aws_private_link", isSuccess: false, errorMessage: errorMessage });
    }
  }

  get isAzureCloudProvider() {
    return this.currentCloudProvider === "AZURE" || this.currentCloudProvider === "azure";
  }

  get isGcpCloudProvider() {
    return this.currentCloudProvider === "GCP" || this.currentCloudProvider === "google";
  }

  get isAWSCloudProvider() {
    return this.currentCloudProvider === "AWS" || this.currentCloudProvider === "amazon";
  }

  cloudProviderMapper(cloudProviderName: ManageEndpointRequest["cloudProviderCode"]): JpuDTO["cloudProvider"] {
    switch (cloudProviderName) {
      case "AWS":
        return "amazon";
      case "AZURE":
        return "azure";
      case "GCP":
        return "google";
      default:
        return cloudProviderName;
    }
  }

  mounted() {
    this.eventBus.$on("onEditEndpoint", (row: any) => this.populateEndpoint(row));
    this.eventBus.$on("onManageEndpoint", this.manageEndpoint);
    this.setDisabled(true);
  }

  destroyed() {
    this.eventBus.$off("onEditEndpoint");
    this.eventBus.$off("onManageEndpoint");
  }
}
