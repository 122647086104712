export interface CountryItem {
  tag: string;
  label: string;
}

export const allCountries: CountryItem[] = [
  {
    tag: "AD",
    label: "Andorra",
  },
  {
    tag: "AE",
    label: "United Arab Emirates",
  },
  {
    tag: "AF",
    label: "Afghanistan",
  },
  {
    tag: "AG",
    label: "Antigua and Barbuda",
  },
  {
    tag: "AI",
    label: "Anguilla",
  },
  {
    tag: "AL",
    label: "Albania",
  },
  {
    tag: "AM",
    label: "Armenia",
  },
  {
    tag: "AO",
    label: "Angola",
  },
  {
    tag: "AP",
    label: "Asia/Pacific Region",
  },
  {
    tag: "AQ",
    label: "Antarctica",
  },
  {
    tag: "AR",
    label: "Argentina",
  },
  {
    tag: "AS",
    label: "American Samoa",
  },
  {
    tag: "AT",
    label: "Austria",
  },
  {
    tag: "AU",
    label: "Australia",
  },
  {
    tag: "AW",
    label: "Aruba",
  },
  {
    tag: "AX",
    label: "Aland Islands",
  },
  {
    tag: "AZ",
    label: "Azerbaijan",
  },
  {
    tag: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    tag: "BB",
    label: "Barbados",
  },
  {
    tag: "BD",
    label: "Bangladesh",
  },
  {
    tag: "BE",
    label: "Belgium",
  },
  {
    tag: "BF",
    label: "Burkina Faso",
  },
  {
    tag: "BG",
    label: "Bulgaria",
  },
  {
    tag: "BH",
    label: "Bahrain",
  },
  {
    tag: "BI",
    label: "Burundi",
  },
  {
    tag: "BJ",
    label: "Benin",
  },
  {
    tag: "BL",
    label: "Saint Barthelemey",
  },
  {
    tag: "BM",
    label: "Bermuda",
  },
  {
    tag: "BN",
    label: "Brunei Darussalam",
  },
  {
    tag: "BO",
    label: "Bolivia",
  },
  {
    tag: "BQ",
    label: "Bonaire, Saint Eustatius and Saba",
  },
  {
    tag: "BR",
    label: "Brazil",
  },
  {
    tag: "BS",
    label: "Bahamas",
  },
  {
    tag: "BT",
    label: "Bhutan",
  },
  {
    tag: "BV",
    label: "Bouvet Island",
  },
  {
    tag: "BW",
    label: "Botswana",
  },
  {
    tag: "BY",
    label: "Belarus",
  },
  {
    tag: "BZ",
    label: "Belize",
  },
  {
    tag: "CA",
    label: "Canada",
  },
  {
    tag: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    tag: "CD",
    label: "Congo, The Democratic Republic of the",
  },
  {
    tag: "CF",
    label: "Central African Republic",
  },
  {
    tag: "CG",
    label: "Congo",
  },
  {
    tag: "CH",
    label: "Switzerland",
  },
  {
    tag: "CI",
    label: "Cote d'Ivoire",
  },
  {
    tag: "CK",
    label: "Cook Islands",
  },
  {
    tag: "CL",
    label: "Chile",
  },
  {
    tag: "CM",
    label: "Cameroon",
  },
  {
    tag: "CN",
    label: "China",
  },
  {
    tag: "CO",
    label: "Colombia",
  },
  {
    tag: "CR",
    label: "Costa Rica",
  },
  {
    tag: "CU",
    label: "Cuba",
  },
  {
    tag: "CV",
    label: "Cape Verde",
  },
  {
    tag: "CW",
    label: "Curacao",
  },
  {
    tag: "CX",
    label: "Christmas Island",
  },
  {
    tag: "CY",
    label: "Cyprus",
  },
  {
    tag: "CZ",
    label: "Czech Republic",
  },
  {
    tag: "DE",
    label: "Germany",
  },
  {
    tag: "DJ",
    label: "Djibouti",
  },
  {
    tag: "DK",
    label: "Denmark",
  },
  {
    tag: "DM",
    label: "Dominica",
  },
  {
    tag: "DO",
    label: "Dominican Republic",
  },
  {
    tag: "DZ",
    label: "Algeria",
  },
  {
    tag: "EC",
    label: "Ecuador",
  },
  {
    tag: "EE",
    label: "Estonia",
  },
  {
    tag: "EG",
    label: "Egypt",
  },
  {
    tag: "EH",
    label: "Western Sahara",
  },
  {
    tag: "ER",
    label: "Eritrea",
  },
  {
    tag: "ES",
    label: "Spain",
  },
  {
    tag: "ET",
    label: "Ethiopia",
  },
  {
    tag: "EU",
    label: "Europe",
  },
  {
    tag: "FI",
    label: "Finland",
  },
  {
    tag: "FJ",
    label: "Fiji",
  },
  {
    tag: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    tag: "FM",
    label: "Micronesia, Federated States of",
  },
  {
    tag: "FO",
    label: "Faroe Islands",
  },
  {
    tag: "FR",
    label: "France",
  },
  {
    tag: "GA",
    label: "Gabon",
  },
  {
    tag: "GB",
    label: "United Kingdom",
  },
  {
    tag: "GD",
    label: "Grenada",
  },
  {
    tag: "GE",
    label: "Georgia",
  },
  {
    tag: "GF",
    label: "French Guiana",
  },
  {
    tag: "GG",
    label: "Guernsey",
  },
  {
    tag: "GH",
    label: "Ghana",
  },
  {
    tag: "GI",
    label: "Gibraltar",
  },
  {
    tag: "GL",
    label: "Greenland",
  },
  {
    tag: "GM",
    label: "Gambia",
  },
  {
    tag: "GN",
    label: "Guinea",
  },
  {
    tag: "GP",
    label: "Guadeloupe",
  },
  {
    tag: "GQ",
    label: "Equatorial Guinea",
  },
  {
    tag: "GR",
    label: "Greece",
  },
  {
    tag: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    tag: "GT",
    label: "Guatemala",
  },
  {
    tag: "GU",
    label: "Guam",
  },
  {
    tag: "GW",
    label: "Guinea-Bissau",
  },
  {
    tag: "GY",
    label: "Guyana",
  },
  {
    tag: "HK",
    label: "Hong Kong",
  },
  {
    tag: "HM",
    label: "Heard Island and McDonald Islands",
  },
  {
    tag: "HN",
    label: "Honduras",
  },
  {
    tag: "HR",
    label: "Croatia",
  },
  {
    tag: "HT",
    label: "Haiti",
  },
  {
    tag: "HU",
    label: "Hungary",
  },
  {
    tag: "ID",
    label: "Indonesia",
  },
  {
    tag: "IE",
    label: "Ireland",
  },
  {
    tag: "IL",
    label: "Israel",
  },
  {
    tag: "IM",
    label: "Isle of Man",
  },
  {
    tag: "IN",
    label: "India",
  },
  {
    tag: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    tag: "IQ",
    label: "Iraq",
  },
  {
    tag: "IR",
    label: "Iran, Islamic Republic of",
  },
  {
    tag: "IS",
    label: "Iceland",
  },
  {
    tag: "IT",
    label: "Italy",
  },
  {
    tag: "JE",
    label: "Jersey",
  },
  {
    tag: "JM",
    label: "Jamaica",
  },
  {
    tag: "JO",
    label: "Jordan",
  },
  {
    tag: "JP",
    label: "Japan",
  },
  {
    tag: "KE",
    label: "Kenya",
  },
  {
    tag: "KG",
    label: "Kyrgyzstan",
  },
  {
    tag: "KH",
    label: "Cambodia",
  },
  {
    tag: "KI",
    label: "Kiribati",
  },
  {
    tag: "KM",
    label: "Comoros",
  },
  {
    tag: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    tag: "KP",
    label: "Korea, Democratic People's Republic of",
  },
  {
    tag: "KR",
    label: "Korea, Republic of",
  },
  {
    tag: "KW",
    label: "Kuwait",
  },
  {
    tag: "KY",
    label: "Cayman Islands",
  },
  {
    tag: "KZ",
    label: "Kazakhstan",
  },
  {
    tag: "LA",
    label: "Lao People's Democratic Republic",
  },
  {
    tag: "LB",
    label: "Lebanon",
  },
  {
    tag: "LC",
    label: "Saint Lucia",
  },
  {
    tag: "LI",
    label: "Liechtenstein",
  },
  {
    tag: "LK",
    label: "Sri Lanka",
  },
  {
    tag: "LR",
    label: "Liberia",
  },
  {
    tag: "LS",
    label: "Lesotho",
  },
  {
    tag: "LT",
    label: "Lithuania",
  },
  {
    tag: "LU",
    label: "Luxembourg",
  },
  {
    tag: "LV",
    label: "Latvia",
  },
  {
    tag: "LY",
    label: "Libyan Arab Jamahiriya",
  },
  {
    tag: "MA",
    label: "Morocco",
  },
  {
    tag: "MC",
    label: "Monaco",
  },
  {
    tag: "MD",
    label: "Moldova, Republic of",
  },
  {
    tag: "ME",
    label: "Montenegro",
  },
  {
    tag: "MF",
    label: "Saint Martin",
  },
  {
    tag: "MG",
    label: "Madagascar",
  },
  {
    tag: "MH",
    label: "Marshall Islands",
  },
  {
    tag: "MK",
    label: "Macedonia",
  },
  {
    tag: "ML",
    label: "Mali",
  },
  {
    tag: "MM",
    label: "Myanmar",
  },
  {
    tag: "MN",
    label: "Mongolia",
  },
  {
    tag: "MO",
    label: "Macao",
  },
  {
    tag: "MP",
    label: "Northern Mariana Islands",
  },
  {
    tag: "MQ",
    label: "Martinique",
  },
  {
    tag: "MR",
    label: "Mauritania",
  },
  {
    tag: "MS",
    label: "Montserrat",
  },
  {
    tag: "MT",
    label: "Malta",
  },
  {
    tag: "MU",
    label: "Mauritius",
  },
  {
    tag: "MV",
    label: "Maldives",
  },
  {
    tag: "MW",
    label: "Malawi",
  },
  {
    tag: "MX",
    label: "Mexico",
  },
  {
    tag: "MY",
    label: "Malaysia",
  },
  {
    tag: "MZ",
    label: "Mozambique",
  },
  {
    tag: "NA",
    label: "Namibia",
  },
  {
    tag: "NC",
    label: "New Caledonia",
  },
  {
    tag: "NE",
    label: "Niger",
  },
  {
    tag: "NF",
    label: "Norfolk Island",
  },
  {
    tag: "NG",
    label: "Nigeria",
  },
  {
    tag: "NI",
    label: "Nicaragua",
  },
  {
    tag: "NL",
    label: "Netherlands",
  },
  {
    tag: "NO",
    label: "Norway",
  },
  {
    tag: "NP",
    label: "Nepal",
  },
  {
    tag: "NR",
    label: "Nauru",
  },
  {
    tag: "NU",
    label: "Niue",
  },
  {
    tag: "NZ",
    label: "New Zealand",
  },
  {
    tag: "OM",
    label: "Oman",
  },
  {
    tag: "PA",
    label: "Panama",
  },
  {
    tag: "PE",
    label: "Peru",
  },
  {
    tag: "PF",
    label: "French Polynesia",
  },
  {
    tag: "PG",
    label: "Papua New Guinea",
  },
  {
    tag: "PH",
    label: "Philippines",
  },
  {
    tag: "PK",
    label: "Pakistan",
  },
  {
    tag: "PL",
    label: "Poland",
  },
  {
    tag: "PM",
    label: "Saint Pierre and Miquelon",
  },
  {
    tag: "PN",
    label: "Pitcairn",
  },
  {
    tag: "PR",
    label: "Puerto Rico",
  },
  {
    tag: "PS",
    label: "Palestinian Territory",
  },
  {
    tag: "PT",
    label: "Portugal",
  },
  {
    tag: "PW",
    label: "Palau",
  },
  {
    tag: "PY",
    label: "Paraguay",
  },
  {
    tag: "QA",
    label: "Qatar",
  },
  {
    tag: "RE",
    label: "Reunion",
  },
  {
    tag: "RO",
    label: "Romania",
  },
  {
    tag: "RS",
    label: "Serbia",
  },
  {
    tag: "RU",
    label: "Russian Federation",
  },
  {
    tag: "RW",
    label: "Rwanda",
  },
  {
    tag: "SA",
    label: "Saudi Arabia",
  },
  {
    tag: "SB",
    label: "Solomon Islands",
  },
  {
    tag: "SC",
    label: "Seychelles",
  },
  {
    tag: "SD",
    label: "Sudan",
  },
  {
    tag: "SE",
    label: "Sweden",
  },
  {
    tag: "SG",
    label: "Singapore",
  },
  {
    tag: "SH",
    label: "Saint Helena",
  },
  {
    tag: "SI",
    label: "Slovenia",
  },
  {
    tag: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  {
    tag: "SK",
    label: "Slovakia",
  },
  {
    tag: "SL",
    label: "Sierra Leone",
  },
  {
    tag: "SM",
    label: "San Marino",
  },
  {
    tag: "SN",
    label: "Senegal",
  },
  {
    tag: "SO",
    label: "Somalia",
  },
  {
    tag: "SR",
    label: "Suriname",
  },
  {
    tag: "SS",
    label: "South Sudan",
  },
  {
    tag: "ST",
    label: "Sao Tome and Principe",
  },
  {
    tag: "SV",
    label: "El Salvador",
  },
  {
    tag: "SX",
    label: "Sint Maarten",
  },
  {
    tag: "SY",
    label: "Syrian Arab Republic",
  },
  {
    tag: "SZ",
    label: "Swaziland",
  },
  {
    tag: "TC",
    label: "Turks and Caicos Islands",
  },
  {
    tag: "TD",
    label: "Chad",
  },
  {
    tag: "TF",
    label: "French Southern Territories",
  },
  {
    tag: "TG",
    label: "Togo",
  },
  {
    tag: "TH",
    label: "Thailand",
  },
  {
    tag: "TJ",
    label: "Tajikistan",
  },
  {
    tag: "TK",
    label: "Tokelau",
  },
  {
    tag: "TL",
    label: "Timor-Leste",
  },
  {
    tag: "TM",
    label: "Turkmenistan",
  },
  {
    tag: "TN",
    label: "Tunisia",
  },
  {
    tag: "TO",
    label: "Tonga",
  },
  {
    tag: "TR",
    label: "Turkey",
  },
  {
    tag: "TT",
    label: "Trinidad and Tobago",
  },
  {
    tag: "TV",
    label: "Tuvalu",
  },
  {
    tag: "TW",
    label: "Taiwan",
  },
  {
    tag: "TZ",
    label: "Tanzania, United Republic of",
  },
  {
    tag: "UA",
    label: "Ukraine",
  },
  {
    tag: "UG",
    label: "Uganda",
  },
  {
    tag: "UM",
    label: "United States Minor Outlying Islands",
  },
  {
    tag: "US",
    label: "United States",
  },
  {
    tag: "UY",
    label: "Uruguay",
  },
  {
    tag: "UZ",
    label: "Uzbekistan",
  },
  {
    tag: "VA",
    label: "Holy See (Vatican City State)",
  },
  {
    tag: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    tag: "VE",
    label: "Venezuela",
  },
  {
    tag: "VG",
    label: "Virgin Islands, British",
  },
  {
    tag: "VI",
    label: "Virgin Islands, U.S.",
  },
  {
    tag: "VN",
    label: "Vietnam",
  },
  {
    tag: "VU",
    label: "Vanuatu",
  },
  {
    tag: "WF",
    label: "Wallis and Futuna",
  },
  {
    tag: "WS",
    label: "Samoa",
  },
  {
    tag: "YE",
    label: "Yemen",
  },
  {
    tag: "YT",
    label: "Mayotte",
  },
  {
    tag: "ZA",
    label: "South Africa",
  },
  {
    tag: "ZM",
    label: "Zambia",
  },
  {
    tag: "ZW",
    label: "Zimbabwe",
  },
];
