
import { DTGraphFilterProps } from "@/types/localtypes";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "DTGraphFilter",
  components: { JFCheckBox },
})
export default class DTGraphFilter extends Vue {
  @Inject() readonly graphEventBus!: Vue;
  @Prop({ default: true })
  private inline!: DTGraphFilterProps["inline"];
  @Prop({ default: true })
  private displayLabel!: DTGraphFilterProps["displayLabel"];
  @Prop({ default: true })
  private disableXray!: DTGraphFilterProps["disableXray"];
  @Prop({ default: false })
  private artifactoryCbChecked!: DTGraphFilterProps["artifactoryCbChecked"];
  @Prop({ default: false })
  private xrayCbChecked!: DTGraphFilterProps["xrayCbChecked"];
  @Prop({ default: () => [] })
  private customClasses!: string[];

  artifactoryCbModel = true;
  xrayCbModel = true;

  @Watch("artifactoryCbModel")
  onArtifactoryToggled() {
    this.graphEventBus.$emit("displayArtifactoryDTChanged", this.artifactoryCbModel);
  }

  @Watch("xrayCbModel")
  onXrayToggled() {
    this.graphEventBus.$emit("displayXrayDTChanged", this.xrayCbModel);
  }

  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.inline) {
      classes.push("inline");
    }
    return classes.concat(this.customClasses);
  }

  mounted() {
    this.artifactoryCbModel = this.artifactoryCbChecked;
    this.xrayCbModel = this.xrayCbChecked;
  }
}
