
import CancelSubscriptionInput from "@/components/views/subscriptions/modals/components/CancelSubscriptionInput.vue";
import {
  CANCEL,
  DOWNGRADE,
  REMOVE_SECURITY_PACK,
  SELF_DOWNGRADE_TO_FREE,
} from "@/components/views/subscriptions/consts/DowngradeHelper";
import { ChangeSubscriptionOptions, FlatSubscription } from "@/types/localtypes";
import { JFFormControl, JFRadioButtonGroup, JFTextAreaField } from "jfrog-ui-vue-essentials";
import { Component, Prop, Vue } from "vue-property-decorator";

interface RequestTypeInput {
  label: String;
  value: ChangeSubscriptionOptions;
  disabled?: boolean;
}

@Component({
  name: "ModalRequestForCancelSubscription",
  $_veeValidate: { validator: "new" },
  components: { JFRadioButtonGroup, JFFormControl, JFTextAreaField, CancelSubscriptionInput },
})
export default class ModalRequestForCancelSubscription extends Vue {
  @Prop() private onApprove!: (explanation: string, selectedRequestType: ChangeSubscriptionOptions) => Promise<boolean>;
  @Prop() private subscription!: FlatSubscription;
  @Prop() private requestType!: ChangeSubscriptionOptions;

  reason = { value: "" };
  radioValue: ChangeSubscriptionOptions = "";

  REMOVE_SECURITY_PACK_TEXT =
    "This will remove your Security Pack features including Open Source License Checks, Premium Vulnerability Database VulnDB Included, SCIM ID Management Support";

  get requestTypeInputs(): RequestTypeInput[] {
    return [
      {
        label: "Request to downgrade subscription",
        value: DOWNGRADE,
      },
      {
        label: "Remove Security Pack",
        value: REMOVE_SECURITY_PACK,
      },
    ] as RequestTypeInput[];
  }

  get radioClasses() {
    if (this.isMobile || this.isTablet) {
      return ["col-12"];
    }

    return ["col-5"];
  }

  get isConfirmDisabled() {
    return this.isSelectionNeeded && !this.radioValue;
  }

  get inputClasses() {
    const classes = [];
    if (this.isInputDisabled) {
      classes.push("disabled");
    }
    if (!this.isSelectionNeeded || this.isMobile || this.isTablet) {
      classes.push("col-12");
    } else {
      classes.push("col-7");
    }
    return classes;
  }

  onChange() {
    this.reason.value = "";
    setTimeout(() => ((this.$refs.textAreaFocus as Vue).$el.querySelector("textarea") as HTMLTextAreaElement).focus());
    this.errors.clear();
  }

  radioButtonsProps = {
    name: "requestType",
    validation: "required",
    inputs: this.requestTypeInputs,
    vertical: true,
  };

  get isSelectionNeeded() {
    return this.isRemoveXrayPremium;
  }

  get isInputDisabled() {
    return this.isRemoveXrayPremium && (!this.radioValue || this.radioValue == DOWNGRADE);
  }

  get isDowngrade() {
    return this.requestType === DOWNGRADE;
  }
  get isCancel() {
    return this.requestType === CANCEL;
  }
  get isSelfDowngradeToFree() {
    return this.requestType === SELF_DOWNGRADE_TO_FREE;
  }
  get isRemoveXrayPremium() {
    return this.requestType === REMOVE_SECURITY_PACK;
  }

  get isAWS() {
    return this.subscription.cloudProviderCode === "amazon";
  }

  get submitText() {
    if (this.isDowngrade) {
      return "Send request";
    }
    if (this.isSelfDowngradeToFree) {
      return "Downgrade";
    }
    if (this.isRemoveXrayPremium) {
      return "Submit";
    }
    return "Cancel Subscription";
  }

  get cancelText() {
    return this.isDowngrade ? "Cancel" : "Close";
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }

  get cloudProWithOutPipelines() {
    const meta = this.subscription.meta;
    return (meta.isCloudPro && !meta.hasPipelines) || (meta.isCloudPro && meta.isPipelinesBlocked);
  }

  get withoutPipelines() {
    const meta = this.subscription.meta;
    return meta.isJCR || this.cloudProWithOutPipelines;
  }

  get eventText() {
    return this.isDowngrade ? "downgrade-subscription" : "cancel-subscription";
  }

  get dataGtmEvent() {
    if (this.subscription.meta.isJCR) {
      return "additional-actions-jcr-cancel-subscription|send-button";
    }
    return `${this.eventText}|send-button`;
  }

  get dataGtmPage() {
    if (this.subscription.meta.isJCR) {
      return "additional-actions-jcr-cancel-subscription";
    }
    return `${this.eventText}|cancel-subscription`;
  }

  get dataGmtEventCancel() {
    if (this.subscription.meta.isJCR) {
      return "additional-actions|cancel-button";
    }
    return `${this.eventText}|cancel-button`;
  }

  get noticeText() {
    if (this.withoutPipelines) {
      return "It is highly recommended to save your data for any future use. Your data will be deleted following this approval of cancellation in accordance with the JFrog Cloud Terms of Service.";
    }
    if (this.isSelfDowngradeToFree) {
      return (
        `Downgrading to a Free subscription may block your service. ` +
        `Please see the <a class="underlined-link" target="_blank" href="https://jfrog.com/pricing/">Free subscription usage limit</a>.`
      );
    }
    if (this.isRemoveXrayPremium) {
      return this.isAWS
        ? this.REMOVE_SECURITY_PACK_TEXT + ", Vault Integration and AWS PrivateLink access."
        : this.REMOVE_SECURITY_PACK_TEXT + " and Vault Integration.";
    }
    return "This will cancel your complete JFrog subscription. It is highly recommended to save your data for any future use. Your data will be deleted following this approval of cancellation in accordance with the JFrog Cloud Terms of Service.";
  }

  get cancellationText() {
    if (this.withoutPipelines) {
      return "As our valued customer, your feedback is important to us. Please let us know what we could have done better:";
    }
    return "Please let us know what we could have done better:";
  }

  async confirm() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    const canClose = await this.onApprove(this.reason.value, this.radioValue);
    if (canClose) {
      this.$jfModal.close();
    }
  }

  mounted() {
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
  }
}
