import Vue from "vue";

const bytesTo = Vue.filter("bytesTo", (value: number, unit: string) => {
  if (!value) {
    return 0;
  }
  return convert(value, unit);
});

const bytesStringTo = Vue.filter("bytesStringTo", (stringValue: string, unit: string) => {
  if (!stringValue) {
    return 0;
  }
  const value = Number(stringValue);
  return convert(value, unit);
});

const convert = (value: number, unit: string) => {
  let result = value;
  switch (unit.toUpperCase()) {
    case "KB":
      result = value / 1024;
      break;
    case "MB":
      result = value / 1024 / 1024;
      break;
    case "GB":
      result = value / 1024 / 1024 / 1024;
      break;
    case "TB":
      result = value / 1024 / 1024 / 1024 / 1024;
      break;
  }
  return parseFloat(result.toFixed(2));
};
const number = Vue.filter("number", (value: number) => {
  if (!value) {
    return (0).toFixed(2);
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

export { bytesTo, bytesStringTo, number };
