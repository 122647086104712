import apiProxy from "@/util/api-proxy";
import { UsageExposerService } from "@/types/services/usageExposer";
import {
  UEAggregatedUsageBreakdownRequest,
  UEAggregatedUsageBreakdownResponse,
  UEUsageBreakdownCSVRequest,
} from "@jfrog-ba/myjfrog-common";

export const usageExposerService: UsageExposerService = {
  getMinimumUsageDate: async (subscription: number) => {
    const response: string | null = await apiProxy.get(`usage-exposer/${subscription}/getMinimumUsageDate`);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  getAggregatedUsage: async (request: UEAggregatedUsageBreakdownRequest) => {
    const response: UEAggregatedUsageBreakdownResponse | null = await apiProxy.post(
      `usage-exposer/${request.subscription}/AggregatedUsageBreakdown`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  getUsageBreakdownCSV: async (request: UEUsageBreakdownCSVRequest) => {
    const response: string | null = await apiProxy.post(
      `usage-exposer/${request.subscription}/downloadUsageBreakdownCsv`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
};
