
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { FlatSubscription } from "@/types/localtypes";
import { JFSwitchControl, JFCheckBox, JFFormControl } from "jfrog-ui-vue-essentials";
import { JFEssentialsPopupMenuPlacement } from "@/types/3rdPartyLibs";
import { JpuDTO, MigrationPluginStatus } from "@jfrog-ba/myjfrog-common";
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";

type SelectedJpuInput = { label: string; code: JpuDTO };
@Component({
  name: "ModalMigrationPlugin",
  components: { LoadingMask, JFSwitchControl, JFCheckBox, FadeSpinner, JFFormControl },
})
export default class ModalDetailedUsageLogs extends Vue {
  @Prop() private subscription!: FlatSubscription;
  isLoading: boolean = false;
  viewLoaded: boolean = false;
  isSwitchChecked: boolean = false;
  isSwitchDisabled: boolean = true;
  isCheckBoxChecked: boolean = false;
  isCheckBoxDisabled: boolean = false;
  isVselectDisabled: boolean = false;
  canSendInstallPluginRequest: boolean = true;
  canSendUninstallPluginRequest: boolean = false;
  showChooseJpuDropDown: boolean = false;
  migrationStatus: MigrationPluginStatus = "UNINSTALLED";
  tooltipMessage: string = "";
  intervalId = 0;
  artifactoryJpus!: JpuDTO[];
  selectedJpu!: JpuDTO | undefined;
  selectedJpuModel: any;

  async beforeMount() {
    this.artifactoryJpus = this.subscription.jpus.filter(jpu =>
      jpu.products.some(product => product.productName.includes("artifactory")),
    );

    let isSet = await this.setSelectedJpuAndMigrationStatus();

    if (isSet) {
      if (this.artifactoryJpus.length > 1) {
        this.selectedJpuModel = {
          label: this.selectedJpu?.technicalServerName,
        };
        this.showChooseJpuDropDown = true;
      }
      this.setMigrationPluginStates();
    }
  }

  mounted() {
    setTimeout(() => {
      this.viewLoaded = true;
    }, 500);
    // should be called in each custom modal in his mounted() method (it will trigger resizes and other global stuff).
    // Called automatically by Essentials in JS but doesn't work in TS components so we should trigger it manually.
    this.$jfModal.childComponentIsSet();
    this.retryFetch();
  }

  retryFetch() {
    const fetchInterval = 1000 * 20;
    this.intervalId = setInterval(this.getMigrationStatusAndSetStates, fetchInterval);
  }

  destroyed() {
    clearInterval(this.intervalId);
  }

  async setSelectedJpuAndMigrationStatus() {
    try {
      for (const jpu of this.artifactoryJpus) {
        const response = await this.$jfJmisService.getPluginStatus({
          technical_server_name: jpu.technicalServerName,
        });
        this.selectedJpu = jpu;
        this.migrationStatus = response.status;
        // if there is a JPU that is in progress, break
        if (response.status !== "UNINSTALLED") {
          break;
        }
      }
      return true;
    } catch (e) {
      this.isCheckBoxDisabled = true;
      this.$log.error("Cant get migration to cloud data: " + e);
      this.$jfNotification.error({ text: "Cant get migration data" });
    }
  }

  async getMigrationStatusAndSetStates() {
    try {
      const response = await this.$jfJmisService.getPluginStatus({
        technical_server_name: this.selectedJpu?.technicalServerName!,
      });
      this.migrationStatus = response.status;
      this.setMigrationPluginStates();
    } catch (e) {
      this.$log.error("Cant get migration to cloud data: " + e);
      this.$jfNotification.error({ text: "Cant get migration data" });
      this.isCheckBoxDisabled = true;
    }
  }
  get tooltipProps() {
    const placement: JFEssentialsPopupMenuPlacement = "right";
    return {
      html: true,
      content: this.tooltipMessage,
      trigger: "manual",
      show: true,
      placement: placement,
      classes: ["tooltip_light", "over-popup"],
      autoHide: false,
      hideOnTargetClick: false,
    };
  }

  selectedJpuChanged(input: SelectedJpuInput) {
    this.selectedJpu = input.code;
    this.$forceUpdate();
    this.getMigrationStatusAndSetStates();
  }

  setMigrationPluginStates() {
    switch (this.migrationStatus) {
      case "INSTALL":
        this.canSendInstallPluginRequest = false;
        this.canSendUninstallPluginRequest = false;
        this.isSwitchChecked = true;
        this.isCheckBoxChecked = true;
        this.isCheckBoxDisabled = true;
        this.isSwitchDisabled = true;
        this.isLoading = true;
        this.isVselectDisabled = true;
        this.tooltipMessage = "<b>Enabling transfer. Please wait.</b>";
        return;
      case "UNINSTALL":
        this.canSendInstallPluginRequest = false;
        this.canSendUninstallPluginRequest = false;
        this.isSwitchChecked = false;
        this.isCheckBoxChecked = true;
        this.isCheckBoxDisabled = true;
        this.isSwitchDisabled = true;
        this.isLoading = true;
        this.isVselectDisabled = true;
        this.tooltipMessage = "<b>Disabling transfer. Please wait.<br/>";
        return;
      case "INSTALLED":
        this.isSwitchChecked = true;
        this.canSendInstallPluginRequest = false;
        this.canSendUninstallPluginRequest = true;
        this.isSwitchDisabled = false;
        this.isCheckBoxDisabled = true;
        this.isCheckBoxChecked = true;
        this.isVselectDisabled = true;

        this.tooltipMessage = "<b>Your JFrog Cloud Platform<br/>is now ready for config transfer</b>";
        this.isLoading = false;
        return;
      case "UNINSTALLED":
        this.isSwitchChecked = false;
        this.canSendInstallPluginRequest = true;
        this.canSendUninstallPluginRequest = false;
        this.isSwitchDisabled = true;
        this.isCheckBoxDisabled = false;
        this.isCheckBoxChecked = false;
        this.isVselectDisabled = false;
        this.tooltipMessage = "";
        this.isLoading = false;
        return;
      default:
    }
  }

  @Watch("isSwitchChecked")
  async isSwitchCheckedChange() {
    if (this.canSendInstallPluginRequest && this.isSwitchChecked) {
      try {
        this.isSwitchDisabled = true;
        this.isCheckBoxDisabled = true;
        this.isLoading = true;
        this.isVselectDisabled = true;
        this.tooltipMessage = "<b>Enabling transfer. Please wait.</b>";
        this.migrationStatus = "INSTALL";
        await this.$jfJmisService.installPlugin({ technical_server_name: this.selectedJpu?.technicalServerName! });
        this.$jfWebeyez.send({
          goal_key: "install_plugin",
          isSuccess: true,
          customData: [{ key: "saasMigration", value: this.selectedJpu?.technicalServerName! }],
        });
      } catch (e) {
        this.isSwitchDisabled = false;
        this.canSendInstallPluginRequest = true;
        this.isSwitchDisabled = true;
        this.isVselectDisabled = false;
        this.isLoading = false;
        this.tooltipMessage = "";
        this.migrationStatus = "UNINSTALLED";
        this.$log.error("Cant send install plugin request: " + e);
        this.$jfNotification.error({ text: this.$jfMessages.jmis_migration_install_plugin_error });
        this.$jfWebeyez.send({
          goal_key: "install_plugin",
          isSuccess: false,
          errorMessage: this.$jfMessages.jmis_migration_install_plugin_error,
          customData: [{ key: "saasMigration", value: this.selectedJpu?.technicalServerName! }],
        });
        setTimeout(() => {
          this.isSwitchChecked = false;
          this.isSwitchDisabled = false;
        }, 800);
      }
    }

    if (this.canSendUninstallPluginRequest && !this.isSwitchChecked) {
      try {
        this.isSwitchDisabled = true;
        this.isVselectDisabled = true;
        this.isLoading = true;
        this.tooltipMessage = "<b>Disabling transfer. Please wait.<br/>";
        await this.$jfJmisService.uninstallPlugin({ technical_server_name: this.selectedJpu?.technicalServerName! });
        this.$jfWebeyez.send({
          goal_key: "uninstall_plugin",
          isSuccess: true,
          customData: [{ key: "saasMigration", value: this.selectedJpu?.technicalServerName! }],
        });
      } catch (e) {
        this.isVselectDisabled = false;
        this.isLoading = false;
        this.tooltipMessage = "<b>Disabling transfer. Please wait.<br/>";
        this.migrationStatus = "INSTALLED";
        this.$log.error("Cant send uninstall plugin request: " + e);
        this.$jfNotification.error({ text: this.$jfMessages.jmis_migration_uninstall_plugin_error });
        this.$jfWebeyez.send({
          goal_key: "uninstall_plugin",
          isSuccess: false,
          errorMessage: this.$jfMessages.jmis_migration_uninstall_plugin_error,
          customData: [{ key: "saasMigration", value: this.selectedJpu?.technicalServerName! }],
        });
        setTimeout(() => {
          this.isSwitchChecked = true;
          this.isSwitchDisabled = false;
        }, 800);
      }
    }
  }

  onCheckBoxChanged() {
    this.isSwitchDisabled = !this.isSwitchDisabled;
  }

  getCssClasses() {
    const arr = ["custom-switch"];
    arr.push(this.isSwitchChecked ? "active" : "");
    return arr;
  }

  get contextualJpuInputs() {
    return this.artifactoryJpus.map(jpu => ({ label: jpu.technicalServerName, code: jpu } as SelectedJpuInput));
  }

  close() {
    this.$jfModal.dismiss();
  }
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }
  get modalBodyStyle() {
    return {
      minHeight: "80px",
    };
  }
}
