
import EndpointBanner from "@/components/views/subscriptions/modals/privateLink/gettingStarted/EndpointBanner.vue";
import { FlatSubscription } from "@/types/localtypes";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, storeErrorsMapping, ManageEndpointRequest } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "EditEndpoint",
  $_veeValidate: { validator: "new" },
  components: { EndpointBanner, JFFormControl, JFTextField },
})
export default class ReplaceEndpoint extends Vue {
  @Prop() private subscription!: FlatSubscription;
  @Prop() private eventBus!: Vue;
  @Prop() private setLoading!: (isLoading: boolean) => undefined;
  @Prop() private gotoInProgress!: () => undefined;
  @Prop() private currentCloudProvider!: ManageEndpointRequest["cloudProviderCode"] | any;

  selectedRegion = "";
  oldEndpointId = "";
  oldEndpointNameValue = "";
  newEndpointId = "";
  newEndpointNameValue = "";
  endpointIdIsOnPendingValidation = false;
  endpointIdValidationTimeout: number | undefined = undefined;
  globalValidationDelay = 700;
  previousAddedServers: string[] = [];

  /*
   * For Azure Private link:  'ID' = 'EndpointName''.''EndpointID' (e.g.: "my-private-link.wer230923oiwefower092" )
   * For AWS Private link: 'ID' = 'EndpointID' (e.g.: "vpce-1234")
   **/
  completeOldEndPointId = "";

  onEndpointIdKeyDown(e: any) {
    if (!e.key || ["shift", "capslock", "tab"].includes(e.key.toLowerCase())) {
      return;
    }

    this.endpointIdIsOnPendingValidation = true;

    clearTimeout(this.endpointIdValidationTimeout);
    this.endpointIdValidationTimeout = setTimeout(() => {
      this.endpointIdIsOnPendingValidation = false;
    }, this.globalValidationDelay + 50);

    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }

  get hasPendingValidation() {
    return this.endpointIdIsOnPendingValidation;
  }

  get inError() {
    return this.errors.any();
  }

  get canSubmitForm() {
    return !!this.newEndpointId && !this.hasPendingValidation && !this.inError;
  }

  get isAzureCloudProvider() {
    return this.currentCloudProvider === "AZURE" || this.currentCloudProvider === "azure";
  }

  get isAWSCloudProvider() {
    return this.currentCloudProvider === "AWS" || this.currentCloudProvider === "amazon";
  }

  get isGcpCloudProvider() {
    return this.currentCloudProvider === "GCP" || this.currentCloudProvider === "google";
  }

  infoIcon = {
    iconClass: "icon-info",
    tooltipText:
      "Find this value on the AWS VPC Dashboard in your AWS account. Verify that the endpoint ID you enter is an alphanumeric lowercase string that begins with 'vpce-'",
  };

  async replaceEndpoint() {
    try {
      this.setLoading(true);
      await this.$jfSubscriptions.manageEndpoint(this.subscription.accountNumber, {
        id: this.newEndpointId,
        endpointName: this.newEndpointNameValue,
        action: "UPDATE",
        serversToAdd: [],
        serversToRemove: [],
        oldId: this.completeOldEndPointId,
        serversToModify: this.previousAddedServers,
        cloudProviderCode: this.currentCloudProvider,
      });
      this.setLoading(false);

      this.gotoInProgress();
      this.$jfModal.dismiss();
    } catch (e) {
      this.setLoading(false);
      let errorMessage = this.$jfMessages.subscriptions_narcissus_task_in_progress;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.privateLink.duplicateError) {
          errorMessage = this.$jfMessages.subscriptions_private_link_duplicate_error;
        }
      }
      this.$jfNotification.error({
        text: errorMessage,
      });
      this.$log.error(e);
    }
  }

  mounted() {
    this.eventBus.$on("onReplaceEndpoint", (row: any) => this.populateEndpoint(row));
  }

  populateEndpoint(row: any) {
    this.selectedRegion = row.region;

    if (row.cloudProvider === "AZURE" || row.cloudProvider === "azure") {
      const endPointIdSplit = row.id.split(".");
      this.oldEndpointId = endPointIdSplit[1];
      this.oldEndpointNameValue = endPointIdSplit[0];
    } else {
      this.oldEndpointId = row.id;
    }

    this.completeOldEndPointId = row.id;
    this.previousAddedServers = row.serverNames;
  }

  destroyed() {
    this.eventBus.$off("onReplaceEndpoint");
  }
}
