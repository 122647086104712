
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";
import { EPlusConfigServerItemProps } from "@/components/views/subscriptions/ePlus/modals/eplusInstancesConfiguration/ModalConfigureEPlusIntances.vue";
import { JFEssentialsAsyncFieldConfig, JFEssentialsTextfieldIconConfig } from "@/types/3rdPartyLibs";
import { Region } from "@/types/localtypes";
import { JFCheckBox, JFFormControl, JFTextField, JFTextFieldAsync } from "jfrog-ui-vue-essentials";
import { JpuDTO, SaasProduct, ServerRequestModel } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type ProviderSelectInput = { label: string; code: JpuDTO["cloudProvider"] };
type RegionSelectInput = { label: string; code: Region["code"] };

@Component({
  name: "EplusConfigServerItem.vue",
  $_veeValidate: { validator: "new" },
  components: { JFFormControl, JFCheckBox, JFTextFieldAsync, JFTextField },
})
export default class EplusConfigServerItem extends Vue {
  @Prop({ default: "" })
  private itemEventBus!: Vue;
  @Prop({ default: "" })
  private itemKey!: string;
  @Prop({ default: "instance" })
  private type!: "instance" | "edge";
  @Prop({ default: 0 })
  private index!: number;
  @Prop({ default: false })
  private initialActiveState!: boolean;
  @Prop({ default: false })
  private displayApplyAll!: boolean;
  @Prop() private onActiveChange!: (itemKey: string, active: boolean) => void;
  @Prop() private onReadyChange!: (itemKey: string, ready: boolean) => void;
  @Prop() private regions!: Region[];
  @Prop({ default: null })
  private enforcedProvider!: EPlusConfigServerItemProps["enforcedProvider"];

  cbChecked: boolean = false;
  applyToAllChecked: boolean = false;
  inputEventBus: Vue = new Vue();
  selectedProviderInput: ProviderSelectInput | null = null;
  selectedRegionInput: RegionSelectInput | null = null;
  passwordInputType: "password" | "text" = "password";

  serverName = "";
  selectedProvider: JpuDTO["cloudProvider"] | null = null;
  selectedRegion: Region["code"] | null = null;
  password = "";
  confirmPassword = "";

  serverNameIsInError = false;
  serverNameIsOnPendingValidation = false;
  serverNameIsOnPendingValidationLocked = false;
  serverNameIsOnPendingValidationLockedTimeout = 0;
  confirmPasswordIsOnPendingValidation = false;
  confirmPasswordLoadingTimeout: number | undefined = undefined;
  globalValidationDelay = 700;

  passwordApplied = "";
  passwordIsAppliedOutside = false;

  servernameLabelIcon = {
    iconClass: "icon-info",
    tooltipText:
      "&#x2022; Server name should be 3 - 32 characters long<br>" +
      "&#x2022; Server name should start with a letter<br>" +
      "&#x2022; Server name should contain only lowercase characters or numbers",
  };

  newPasswordLabelIcon = {
    iconClass: "icon-info",
    tooltipText:
      "&#x2022; Password should be 8 - 32 characters long<br>" +
      "&#x2022; Password should contain at least one uppercase and one lowercase character<br>" +
      "&#x2022; Password should contain at least one number<br>" +
      "&#x2022; Password should contain at least one special character",
  };

  @Watch("isActive")
  handleActiveChange(isActive: boolean) {
    this.onActiveChange(this.itemKey, this.isActive);
    if (isActive && this.passwordIsAppliedOutside) {
      this.applyPasswords();
    }
    if (!isActive && this.displayApplyAll && this.applyToAllChecked) {
      this.applyToAllChecked = false;
    }
    if (!isActive && this.passwordIsAppliedOutside) {
      this.clearPasswords();
    }
  }

  @Watch("isReady")
  handleReadyChange() {
    this.onReadyChange(this.itemKey, this.isReady);
  }

  @Watch("selectedProviderInput")
  handleProviderChange(newProviderInput: ProviderSelectInput, oldProviderInput: ProviderSelectInput) {
    if (oldProviderInput && newProviderInput.code !== oldProviderInput.code) {
      this.selectedRegionInput = null;
    }
    this.selectedProvider = newProviderInput.code;
  }

  @Watch("selectedRegionInput")
  handleRegionChange(newRegionInput: RegionSelectInput) {
    this.selectedRegion = newRegionInput ? newRegionInput.code : null;
  }

  @Watch("applyToAllChecked")
  handleApplyToAllChecked(applyChecked: boolean) {
    this.itemEventBus.$emit("applyToAllToggled", this.itemKey, applyChecked, this.password);
  }

  toggleApplyToAllChecked() {
    if (this.isActive) {
      this.applyToAllChecked = !this.applyToAllChecked;
    }
  }

  get controlWrapperClasses() {
    const classes = [];
    if (this.shouldHideProvider) {
      classes.push("w-25");
    } else {
      classes.push("w-20");
    }
    return classes;
  }

  get controlWrapperClassesProviderField() {
    const classes = [...this.controlWrapperClasses];
    if (this.shouldHideProvider) {
      classes.push("hidden");
    }
    return classes;
  }

  get passwordFieldIcons(): JFEssentialsTextfieldIconConfig[] {
    return [
      {
        persistent: true,
        name: "art-view",
        initialOffset: 0,
        extraStyles: {
          fontSize: "20px",
        },
        handler: icon => (this.passwordInputType = this.passwordInputType === "password" ? "text" : "password"),
      },
    ];
  }

  get asyncConfig(): JFEssentialsAsyncFieldConfig {
    return {
      showAsyncIndications: this.isActive,
      icons: {
        success: {
          name: "icon-mjf-curved-check",
          classes: ["mjf-green"],
        },
        error: {
          name: "icon-mjf-canceled",
          classes: ["mjf-red"],
        },
      },
      loader: {
        component: FadeSpinner,
        wrapper: {
          rightOffset: 5,
          bottomOffset: 27,
        },
        props: {
          color: "#1cbe4f",
          dotHeight: "3",
          dotWidth: "3",
          borderRadius: "100%",
          radius: 10,
          containerSize: "30",
        },
      },
    };
  }

  get providersInputs(): ProviderSelectInput[] {
    return [
      {
        label: "Amazon",
        code: "amazon",
      },
      {
        label: "Azure",
        code: "azure",
      },
      {
        label: "Google Cloud",
        code: "google",
      },
    ];
  }
  get providerIsSelected() {
    return !!this.selectedProviderInput;
  }

  get contextualRegionsInputs(): RegionSelectInput[] {
    return this.selectedProviderInput
      ? this.regions
          .filter(
            region =>
              region.jpuProviderCode === (this.selectedProviderInput as ProviderSelectInput).code &&
              region.name.toLowerCase() !== "default",
          )
          .map(region => ({ label: region.name, code: region.code }))
      : [];
  }

  get isActive() {
    return this.cbChecked;
  }

  get wrapperClasses() {
    const classes: string[] = [];
    if (!this.isActive) {
      classes.push("disabled");
    }
    if (this.displayApplyAll) {
      classes.push("with-apply-to-all");
    }
    return classes;
  }

  get serverNameFieldName() {
    return `config-servername-${this.itemKey}`;
  }

  get serverNameFieldRef() {
    return `ref-${this.serverNameFieldName}`;
  }

  get passwordFieldName() {
    return `config-pwd-${this.itemKey}`;
  }

  get confirmPasswordFieldName() {
    return `config-confirm-pwd-${this.itemKey}`;
  }

  get isFilled() {
    return (
      !!this.serverName && !!this.selectedProvider && !!this.selectedRegion && !!this.password && !!this.confirmPassword
    );
  }

  get hasPendingValidation() {
    return this.serverNameIsOnPendingValidation || this.confirmPasswordIsOnPendingValidation;
  }

  get inError() {
    return this.serverNameIsInError || this.errors.any();
  }

  get isReady() {
    return this.isActive && this.isFilled && !this.hasPendingValidation && !this.inError;
  }

  onPasswordKeyDown(e: any) {
    if (["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }

    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }

  onConfirmPasswordKeyDown(e: any) {
    if (["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }
    this.confirmPasswordIsOnPendingValidation = true;
    clearTimeout(this.confirmPasswordLoadingTimeout);
    this.confirmPasswordLoadingTimeout = setTimeout(() => {
      this.confirmPasswordIsOnPendingValidation = false;
    }, this.globalValidationDelay + 50);

    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }

  onPasswordKeyUp(e: any) {
    if (!this.displayApplyAll || ["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }

    if (this.applyToAllChecked) {
      this.itemEventBus.$emit("appliedPwdUpdated", this.password);
    }
  }

  onServerNameKeyDown(e: any) {
    // dt wait the pending event to put the serverName on pending state (because of dataVvDelay)
    if (["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }
    this.serverNameIsOnPendingValidation = true;
    this.serverNameIsOnPendingValidationLocked = true;
    clearTimeout(this.serverNameIsOnPendingValidationLockedTimeout);
    this.serverNameIsOnPendingValidationLockedTimeout = setTimeout(() => {
      this.serverNameIsOnPendingValidationLocked = false;
    }, this.globalValidationDelay + 50);
  }

  handleInnerPendingChanged(innerPendings: { [key: string]: boolean }) {
    if (innerPendings) {
      if (!this.serverNameIsOnPendingValidationLocked) {
        this.serverNameIsOnPendingValidation = innerPendings[this.serverNameFieldName];
      }
    }
  }

  handleInnerErrorsChanged(innerErrors: { [key: string]: string[] }) {
    if (innerErrors && innerErrors[this.serverNameFieldName]) {
      this.serverNameIsInError = !!innerErrors[this.serverNameFieldName].length;
    }
  }

  applyPasswords() {
    this.password = this.passwordApplied;
    this.confirmPassword = this.passwordApplied;
    setTimeout(() => {
      this.$validator.validate(this.passwordFieldName);
      this.$validator.validate(this.confirmPasswordFieldName);
    }, 0);
  }

  clearPasswords() {
    this.password = "";
    this.confirmPassword = "";
    setTimeout(() => {
      this.errors.remove(this.confirmPasswordFieldName);
      this.errors.remove(this.passwordFieldName);
    }, 0);
  }

  handleApplyToAllToggled(emitterKey: string, applyToAllChecked: boolean, passwordApplied: string) {
    this.passwordApplied = passwordApplied;
    const currentItemIsTheOrigin = this.itemKey === emitterKey;
    this.passwordIsAppliedOutside = !currentItemIsTheOrigin && applyToAllChecked;

    if (!currentItemIsTheOrigin && this.isActive) {
      if (applyToAllChecked) {
        this.applyPasswords();
      } else {
        this.clearPasswords();
      }
    }
  }

  handleAppliedPwdUpdated(passwordApplied: string) {
    if (this.passwordIsAppliedOutside) {
      this.passwordApplied = passwordApplied;
      if (this.isActive) {
        this.applyPasswords();
      }
    }
  }

  itemBusEvents: { [key: string]: (...params: any[]) => any } = {
    applyToAllToggled: this.handleApplyToAllToggled,
    appliedPwdUpdated: this.handleAppliedPwdUpdated,
  };

  inputBusEvents: { [key: string]: (...params: any[]) => any } = {
    innerErrorsChanged: this.handleInnerErrorsChanged,
    innerPendingChanged: this.handleInnerPendingChanged,
  };

  generateServerRequestModel(): ServerRequestModel {
    // this method is called by parent (via $refs)
    const products: SaasProduct[] = this.type === "instance" ? ["artifactory", "xray"] : ["edge"];
    return {
      serverName: this.serverName,
      regionCode: this.selectedRegion as string,
      initialPassword: this.password,
      products,
    };
  }

  putFocusOnFirstField(): void {
    // this method is called by parent (via $refs)
    ((this.$refs[this.serverNameFieldRef] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
  }

  get passwordFieldIsInError() {
    return !!this.errors.items.find(item => item.field === this.passwordFieldName);
  }

  get applyToAllStyles() {
    let bottom = 3;
    if (this.isActive && this.passwordFieldIsInError) {
      bottom = -18;
    }
    return {
      bottom: `${bottom}px`,
    };
  }

  get shouldHideProvider() {
    return !!this.enforcedProvider;
  }

  enforceProviderField() {
    if (!this.enforcedProvider) {
      return;
    }
    const providerInput = this.providersInputs.find(p => p.code === this.enforcedProvider);
    if (!!providerInput) {
      this.selectedProviderInput = providerInput;
    }
  }

  created() {
    this.cbChecked = this.initialActiveState;
    for (let key in this.itemBusEvents) {
      this.itemEventBus.$on(key, this.itemBusEvents[key]);
    }
    for (let key in this.inputBusEvents) {
      this.inputEventBus.$on(key, this.inputBusEvents[key]);
    }
  }

  mounted() {
    this.enforceProviderField();
  }

  beforeDestroy() {
    for (let key in this.itemBusEvents) {
      this.itemEventBus.$off(key, this.itemBusEvents[key]);
    }
    for (let key in this.inputBusEvents) {
      this.inputEventBus.$off(key, this.inputBusEvents[key]);
    }
  }
}
